@import 'bootstrap/scss/functions';
@import 'abstracts/mixins';
@import 'abstracts/variables';

$primary-1: #{map-get($theme-colors, 'primary')};
$primary-2: #{map-get($theme-colors, 'primary-1')};
$secondary: #{map-get($theme-colors, 'secondary')};
$secondary-hover: #{map-get($theme-colors, 'secondary-hover')};
$gray: #{map-get($colors, 'gray')};
$blue-gray-1: #{map-get($colors, 'blue-gray-1')};
$blue-gray-2: #{map-get($colors, 'blue-gray-2')};
$blue-gray-3: #{map-get($colors, 'blue-gray-3')};

magnum-icw .magnum-root,
.cdk-overlay-container {
  --magnum-color-primary-1: #{$primary-1};
  --magnum-color-primary-2: #{$primary-2};
  --magnum-color-primary-3: #{$white};
  --magnum-color-default-1: #{map-get($theme-colors, 'primary')}; //TODO Why it doesn't take $primary
  --magnum-color-default-5: #{$white};
  --magnum-widget-border-active-default: inset 0 0 0 1px #{$primary-1};
  --magnum-widget-border-active-focus: 0 0 0 1px #{$secondary}, inset 0 0 0 4px #{$primary-2};
  --magnum-widget-border-normal-default: 0 0 0 1px #{$blue-gray-2};
  --magnum-widget-border-normal-hover: 0 0 0 1px #{$blue-gray-2};
  --magnum-widget-border-normal-focus: 0 0 0 2px #{map-get($theme-colors, 'secondary')}; //TODO Why it doesn't take $secondary for input fields
  --magnum-widget-border-normal-focus-hover: 0 0 0 2px #{map-get($theme-colors, 'secondary')}; //TODO Why it doesn't take $secondary for input fields
  --magnum-widget-border-active-focus-hover: 0 0 0 2px #{$secondary}, inset 0 0 0 4px #{$secondary};
  --magnum-widget-border-active-hover: 0 0 0 2px #{$primary-1};
  --magnum-widget-background-active: inset 0 0 0 2px #{$secondary};
  --magnum-font-family: NuernbergerTextWeb;

  .magnum {
    &-labelled-button {
      &-control-widget {
        &__control {
          border-radius: $border-radius;
        }
      }
    }

    &-icon {
      &__background {
        fill: $secondary;
      }
    }

    &-navigation-button {
      line-height: $input-btn-line-height;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }

    &-button {
      border-radius: $border-radius;
      transition: 0.25s;

      //continue button
      &_primary {
        background-color: $secondary;
      }

      //continue button hover
      &_primary:hover:not([disabled]) {
        background-color: $secondary;
      }
    }

    &-preamble {
      & p {
        font-weight: $font-weight-bold;
      }
    }

    //info-box
    &-question {
      &__help-text {
        background-color: $secondary;
        color: $white;
      }
    }

    &-stylish {
      &-component {
        //checkbox icon
        &__icon {
          color: $secondary;
        }

        &__control {
          border-radius: $border-radius;

          &:hover {
            border-radius: $border-radius;
          }
        }
      }
    }
  }

  .mxg {
    transition: 0.25s;

    //back button
    &-action {
      &-back {
        border: 2px solid $blue-gray-3;
        background-color: $blue-gray-3;
        color: $primary;
        box-shadow: none;

        &:hover {
          border-color: $gray;
          background-color: $gray;
          color: $white;
          box-shadow: none;
        }
      }
      &-continue {
        border: 2px solid $secondary;

        &:hover:is(.magnum-navigation-button) {
          border-color: $secondary-hover;
          background-color: $secondary-hover;
          box-shadow: none;
        }
      }
    }
  }

  //box-shadow + bg-color of yes and no boxes
  .magnum-stylish-component_primary .magnum-stylish-component__control {
    box-shadow: 0 0 0 2px $secondary;
    color: $white;
    background-color: $secondary;

    &:hover {
      box-shadow: 0 0 0 2px $secondary;
    }
  }
  .magnum-question__header.mxg-question-header {
    padding-inline-end: 15px;
  }
}
