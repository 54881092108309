@charset "UTF-8";
@font-face {
  font-family: "NuernbergerTextWeb";
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  src: url("../../assets/fonts/nuernbergertextweb-bold.eot");
  src: url("../../assets/fonts/nuernbergertextweb-bold.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/nuernbergertextweb-bold.woff2") format("woff2"), url("../../assets/fonts/nuernbergertextweb-bold.woff") format("woff"), url("../../assets/fonts/nuernbergertextweb-bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "NuernbergerTextWeb";
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: url("../../assets/fonts/nuernbergertextweb-regular.eot");
  src: url("../../assets/fonts/nuernbergertextweb-regular.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/nuernbergertextweb-regular.woff2") format("woff2"), url("../../assets/fonts/nuernbergertextweb-regular.woff") format("woff"), url("../../assets/fonts/nuernbergertextweb-regular.ttf") format("truetype");
  font-display: swap;
}
magnum-icw .magnum-root,
.cdk-overlay-container {
  --magnum-color-primary-1: #003460;
  --magnum-color-primary-2: #608ca1;
  --magnum-color-primary-3: #fff;
  --magnum-color-default-1: #003460;
  --magnum-color-default-5: #fff;
  --magnum-widget-border-active-default: inset 0 0 0 1px #003460;
  --magnum-widget-border-active-focus: 0 0 0 1px #009bb7, inset 0 0 0 4px #608ca1;
  --magnum-widget-border-normal-default: 0 0 0 1px #aec2ce;
  --magnum-widget-border-normal-hover: 0 0 0 1px #aec2ce;
  --magnum-widget-border-normal-focus: 0 0 0 2px #009bb7;
  --magnum-widget-border-normal-focus-hover: 0 0 0 2px #009bb7;
  --magnum-widget-border-active-focus-hover: 0 0 0 2px #009bb7, inset 0 0 0 4px #009bb7;
  --magnum-widget-border-active-hover: 0 0 0 2px #003460;
  --magnum-widget-background-active: inset 0 0 0 2px #009bb7;
  --magnum-font-family: NuernbergerTextWeb;
}
magnum-icw .magnum-root .magnum-labelled-button-control-widget__control,
.cdk-overlay-container .magnum-labelled-button-control-widget__control {
  border-radius: 0.375rem;
}
magnum-icw .magnum-root .magnum-icon__background,
.cdk-overlay-container .magnum-icon__background {
  fill: #009bb7;
}
magnum-icw .magnum-root .magnum-navigation-button,
.cdk-overlay-container .magnum-navigation-button {
  line-height: 1.5556;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
magnum-icw .magnum-root .magnum-button,
.cdk-overlay-container .magnum-button {
  border-radius: 0.375rem;
  transition: 0.25s;
}
magnum-icw .magnum-root .magnum-button_primary,
.cdk-overlay-container .magnum-button_primary {
  background-color: #009bb7;
}
magnum-icw .magnum-root .magnum-button_primary:hover:not([disabled]),
.cdk-overlay-container .magnum-button_primary:hover:not([disabled]) {
  background-color: #009bb7;
}
magnum-icw .magnum-root .magnum-preamble p,
.cdk-overlay-container .magnum-preamble p {
  font-weight: 700;
}
magnum-icw .magnum-root .magnum-question__help-text,
.cdk-overlay-container .magnum-question__help-text {
  background-color: #009bb7;
  color: #fff;
}
magnum-icw .magnum-root .magnum-stylish-component__icon,
.cdk-overlay-container .magnum-stylish-component__icon {
  color: #009bb7;
}
magnum-icw .magnum-root .magnum-stylish-component__control,
.cdk-overlay-container .magnum-stylish-component__control {
  border-radius: 0.375rem;
}
magnum-icw .magnum-root .magnum-stylish-component__control:hover,
.cdk-overlay-container .magnum-stylish-component__control:hover {
  border-radius: 0.375rem;
}
magnum-icw .magnum-root .mxg,
.cdk-overlay-container .mxg {
  transition: 0.25s;
}
magnum-icw .magnum-root .mxg-action-back,
.cdk-overlay-container .mxg-action-back {
  border: 2px solid #e0ebf1;
  background-color: #e0ebf1;
  color: #003460;
  box-shadow: none;
}
magnum-icw .magnum-root .mxg-action-back:hover,
.cdk-overlay-container .mxg-action-back:hover {
  border-color: #9ea4a7;
  background-color: #9ea4a7;
  color: #fff;
  box-shadow: none;
}
magnum-icw .magnum-root .mxg-action-continue,
.cdk-overlay-container .mxg-action-continue {
  border: 2px solid #009bb7;
}
magnum-icw .magnum-root .mxg-action-continue:hover:is(.magnum-navigation-button),
.cdk-overlay-container .mxg-action-continue:hover:is(.magnum-navigation-button) {
  border-color: #03859e;
  background-color: #03859e;
  box-shadow: none;
}
magnum-icw .magnum-root .magnum-stylish-component_primary .magnum-stylish-component__control,
.cdk-overlay-container .magnum-stylish-component_primary .magnum-stylish-component__control {
  box-shadow: 0 0 0 2px #009bb7;
  color: #fff;
  background-color: #009bb7;
}
magnum-icw .magnum-root .magnum-stylish-component_primary .magnum-stylish-component__control:hover,
.cdk-overlay-container .magnum-stylish-component_primary .magnum-stylish-component__control:hover {
  box-shadow: 0 0 0 2px #009bb7;
}
magnum-icw .magnum-root .magnum-question__header.mxg-question-header,
.cdk-overlay-container .magnum-question__header.mxg-question-header {
  padding-inline-end: 15px;
}

:root {
  --nv-blue: #003460;
  --nv-blue-gray-0: #2a697f;
  --nv-blue-gray-1: #608ca1;
  --nv-blue-gray-2: #aec2ce;
  --nv-blue-gray-3: #e0ebf1;
  --nv-blue-gray-35: #f1f6f9;
  --nv-gray: #9ea4a7;
  --nv-turq: #009bb7;
  --nv-rose: #ff7c6b;
  --nv-error: #ff7c6b;
  --nv-warning: #ffd631;
  --nv-success: #44ad4b;
  --nv-light: #e0ebf1;
  --nv-gray-100: #f8f9fa;
  --nv-gray-200: #e9ecef;
  --nv-gray-300: #dee2e6;
  --nv-gray-400: #ced4da;
  --nv-gray-500: #adb5bd;
  --nv-gray-600: #6c757d;
  --nv-gray-700: #495057;
  --nv-gray-800: #343a40;
  --nv-gray-900: #212529;
  --nv-primary: #003460;
  --nv-primary-0: #2a697f;
  --nv-primary-1: #608ca1;
  --nv-primary-2: #aec2ce;
  --nv-primary-3: #e0ebf1;
  --nv-primary-35: #f1f6f9;
  --nv-secondary: #009bb7;
  --nv-secondary-hover: #03859e;
  --nv-accent: #ff7c6b;
  --nv-error: #ff7c6b;
  --nv-warning: #ffd631;
  --nv-success: #44ad4b;
  --nv-primary-rgb: 0, 52, 96;
  --nv-primary-0-rgb: 42, 105, 127;
  --nv-primary-1-rgb: 96, 140, 161;
  --nv-primary-2-rgb: 174, 194, 206;
  --nv-primary-3-rgb: 224, 235, 241;
  --nv-primary-35-rgb: 241, 246, 249;
  --nv-secondary-rgb: 0, 155, 183;
  --nv-secondary-hover-rgb: 3, 133, 158;
  --nv-accent-rgb: 255, 124, 107;
  --nv-error-rgb: 255, 124, 107;
  --nv-warning-rgb: 255, 214, 49;
  --nv-success-rgb: 68, 173, 75;
  --nv-light-rgb: 224, 235, 241;
  --nv-white-rgb: 255, 255, 255;
  --nv-black-rgb: 0, 0, 0;
  --nv-body-color-rgb: 0, 52, 96;
  --nv-body-bg-rgb: 255, 255, 255;
  --nv-font-sans-serif: "NuernbergerTextWeb", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --nv-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --nv-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --nv-body-font-family: NuernbergerTextWeb, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --nv-body-font-size: 1.125rem;
  --nv-body-font-weight: 400;
  --nv-body-line-height: 1.5;
  --nv-body-color: #003460;
  --nv-body-bg: #fff;
  --nv-border-width: 1px;
  --nv-border-style: solid;
  --nv-border-color: #dee2e6;
  --nv-border-color-translucent: rgba(0, 0, 0, 0.175);
  --nv-border-radius: 0.375rem;
  --nv-border-radius-sm: 0.25rem;
  --nv-border-radius-lg: 0;
  --nv-border-radius-xl: 1rem;
  --nv-border-radius-2xl: 2rem;
  --nv-border-radius-pill: 50rem;
  --nv-link-color: #003460;
  --nv-link-hover-color: #009bb7;
  --nv-code-color: #d63384;
  --nv-highlight-bg: #fcf8e3;
}

.nv-evox-root:not(.modal-dialog) {
  margin: 0;
  font-family: var(--nv-body-font-family);
  font-size: var(--nv-body-font-size);
  font-weight: var(--nv-body-font-weight);
  line-height: var(--nv-body-line-height);
  color: var(--nv-body-color);
  text-align: var(--nv-body-text-align);
  background-color: var(--nv-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  /* rtl:raw:
  [type="tel"],
  [type="url"],
  [type="email"],
  [type="number"] {
    direction: ltr;
  }
  */
}
body *,
body *::before,
body *::after {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  body :root {
    scroll-behavior: smooth;
  }
}
body body {
  margin: 0;
  font-family: var(--nv-body-font-family);
  font-size: var(--nv-body-font-size);
  font-weight: var(--nv-body-font-weight);
  line-height: var(--nv-body-line-height);
  color: var(--nv-body-color);
  text-align: var(--nv-body-text-align);
  background-color: var(--nv-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body hr {
  margin: 1.125rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}
body h6, body .nv-evox-root .h6, .nv-evox-root body .h6, body h5, body .nv-evox-root .h5, .nv-evox-root body .h5, body h4, body .nv-evox-root .h4, .nv-evox-root body .h4, body h3, body .nv-evox-root .h3, .nv-evox-root body .h3, body h2, body .nv-evox-root .h2, .nv-evox-root body .h2, body h1, body .nv-evox-root .h1, .nv-evox-root body .h1 {
  margin-top: 0;
  margin-bottom: 0.5625rem;
  font-family: "NuernbergerTextWeb", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  line-height: 1.3;
  color: inherit;
}
body h1, body .nv-evox-root .h1, .nv-evox-root body .h1 {
  font-size: calc(1.411875rem + 1.9425vw);
}
@media (min-width: 1200px) {
  body h1, body .nv-evox-root .h1, .nv-evox-root body .h1 {
    font-size: 2.86875rem;
  }
}
body h2, body .nv-evox-root .h2, .nv-evox-root body .h2 {
  font-size: calc(1.37475rem + 1.497vw);
}
@media (min-width: 1200px) {
  body h2, body .nv-evox-root .h2, .nv-evox-root body .h2 {
    font-size: 2.4975rem;
  }
}
body h3, body .nv-evox-root .h3, .nv-evox-root body .h3 {
  font-size: calc(1.2825rem + 0.39vw);
}
@media (min-width: 1200px) {
  body h3, body .nv-evox-root .h3, .nv-evox-root body .h3 {
    font-size: 1.575rem;
  }
}
body h4, body .nv-evox-root .h4, .nv-evox-root body .h4 {
  font-size: 1.125rem;
}
body h5, body .nv-evox-root .h5, .nv-evox-root body .h5 {
  font-size: 1.125rem;
}
body h6, body .nv-evox-root .h6, .nv-evox-root body .h6 {
  font-size: 1.125rem;
}
body p {
  margin-top: 0;
  margin-bottom: 1.125rem;
}
body abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}
body address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
body ol,
body ul {
  padding-left: 2rem;
}
body ol,
body ul,
body dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
body ol ol,
body ul ul,
body ol ul,
body ul ol {
  margin-bottom: 0;
}
body dt {
  font-weight: 700;
}
body dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
body blockquote {
  margin: 0 0 1rem;
}
body b,
body strong {
  font-weight: bolder;
}
body small, body .nv-evox-root .small, .nv-evox-root body .small {
  font-size: 0.9rem;
}
body mark, body .nv-evox-root .mark, .nv-evox-root body .mark {
  padding: 0.1875em;
  background-color: var(--nv-highlight-bg);
}
body sub,
body sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}
body sub {
  bottom: -0.25em;
}
body sup {
  top: -0.5em;
}
body a {
  color: var(--nv-link-color);
  text-decoration: underline;
}
body a:hover {
  color: var(--nv-link-hover-color);
  text-decoration: underline;
}
body a:not([href]):not([class]), body a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
body pre,
body code,
body kbd,
body samp {
  font-family: var(--nv-font-monospace);
  font-size: 1em;
}
body pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.9rem;
}
body pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
body code {
  font-size: 0.9rem;
  color: var(--nv-code-color);
  word-wrap: break-word;
}
a > body code {
  color: inherit;
}
body kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.9rem;
  color: var(--nv-body-bg);
  background-color: var(--nv-body-color);
  border-radius: 0.25rem;
}
body kbd kbd {
  padding: 0;
  font-size: 1em;
}
body figure {
  margin: 0 0 1rem;
}
body img,
body svg {
  vertical-align: middle;
}
body table {
  caption-side: bottom;
  border-collapse: collapse;
}
body caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #adb5bd;
  text-align: left;
}
body th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
body thead,
body tbody,
body tfoot,
body tr,
body td,
body th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
body label {
  display: inline-block;
}
body button {
  border-radius: 0;
}
body button:focus:not(:focus-visible) {
  outline: 0;
}
body input,
body button,
body select,
body optgroup,
body textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
body button,
body select {
  text-transform: none;
}
body [role=button] {
  cursor: pointer;
}
body select {
  word-wrap: normal;
}
body select:disabled {
  opacity: 1;
}
body [list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}
body button,
body [type=button],
body [type=reset],
body [type=submit] {
  -webkit-appearance: button;
}
body button:not(:disabled),
body [type=button]:not(:disabled),
body [type=reset]:not(:disabled),
body [type=submit]:not(:disabled) {
  cursor: pointer;
}
body ::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
body textarea {
  resize: vertical;
}
body fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
body legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  body legend {
    font-size: 1.5rem;
  }
}
body legend + * {
  clear: left;
}
body ::-webkit-datetime-edit-fields-wrapper,
body ::-webkit-datetime-edit-text,
body ::-webkit-datetime-edit-minute,
body ::-webkit-datetime-edit-hour-field,
body ::-webkit-datetime-edit-day-field,
body ::-webkit-datetime-edit-month-field,
body ::-webkit-datetime-edit-year-field {
  padding: 0;
}
body ::-webkit-inner-spin-button {
  height: auto;
}
body [type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}
body ::-webkit-search-decoration {
  -webkit-appearance: none;
}
body ::-webkit-color-swatch-wrapper {
  padding: 0;
}
body ::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}
body output {
  display: inline-block;
}
body iframe {
  border: 0;
}
body summary {
  display: list-item;
  cursor: pointer;
}
body progress {
  vertical-align: baseline;
}
body [hidden] {
  display: none !important;
}

.nv-evox-root {
  /* rtl:begin:remove */
  /* rtl:end:remove */
  /* rtl:options: {
    "autoRename": true,
    "stringMap":[ {
      "name"    : "prev-next",
      "search"  : "prev",
      "replace" : "next"
    } ]
  } */
}
.nv-evox-root .lead {
  font-size: calc(1.265625rem + 0.1875vw);
  font-weight: 300;
}
@media (min-width: 1200px) {
  .nv-evox-root .lead {
    font-size: 1.40625rem;
  }
}
.nv-evox-root .display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .nv-evox-root .display-1 {
    font-size: 5rem;
  }
}
.nv-evox-root .display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .nv-evox-root .display-2 {
    font-size: 4.5rem;
  }
}
.nv-evox-root .display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .nv-evox-root .display-3 {
    font-size: 4rem;
  }
}
.nv-evox-root .display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .nv-evox-root .display-4 {
    font-size: 3.5rem;
  }
}
.nv-evox-root .display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .nv-evox-root .display-5 {
    font-size: 3rem;
  }
}
.nv-evox-root .display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .nv-evox-root .display-6 {
    font-size: 2.5rem;
  }
}
.nv-evox-root .list-unstyled {
  padding-left: 0;
  list-style: none;
}
.nv-evox-root .list-inline {
  padding-left: 0;
  list-style: none;
}
.nv-evox-root .list-inline-item {
  display: inline-block;
}
.nv-evox-root .list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.nv-evox-root .initialism {
  font-size: 0.9rem;
  text-transform: uppercase;
}
.nv-evox-root .blockquote {
  margin-bottom: 1.125rem;
  font-size: calc(1.265625rem + 0.1875vw);
}
@media (min-width: 1200px) {
  .nv-evox-root .blockquote {
    font-size: 1.40625rem;
  }
}
.nv-evox-root .blockquote > :last-child {
  margin-bottom: 0;
}
.nv-evox-root .blockquote-footer {
  margin-top: -1.125rem;
  margin-bottom: 1.125rem;
  font-size: 0.9rem;
  color: #6c757d;
}
.nv-evox-root .blockquote-footer::before {
  content: "— ";
}
.nv-evox-root .img-fluid {
  max-width: 100%;
  height: auto;
}
.nv-evox-root .img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--nv-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}
.nv-evox-root .figure {
  display: inline-block;
}
.nv-evox-root .figure-img {
  margin-bottom: 0.5625rem;
  line-height: 1;
}
.nv-evox-root .figure-caption {
  font-size: 0.9rem;
  color: #6c757d;
}
.nv-evox-root .container,
.nv-evox-root .container-fluid,
.nv-evox-root .container-xl,
.nv-evox-root .container-lg,
.nv-evox-root .container-md,
.nv-evox-root .container-sm {
  --nv-gutter-x: 32px;
  --nv-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--nv-gutter-x) * 0.5);
  padding-left: calc(var(--nv-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 480px) {
  .nv-evox-root .container-sm, .nv-evox-root .container {
    max-width: 480px;
  }
}
@media (min-width: 768px) {
  .nv-evox-root .container-md, .nv-evox-root .container-sm, .nv-evox-root .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .nv-evox-root .container-lg, .nv-evox-root .container-md, .nv-evox-root .container-sm, .nv-evox-root .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .nv-evox-root .container-xl, .nv-evox-root .container-lg, .nv-evox-root .container-md, .nv-evox-root .container-sm, .nv-evox-root .container {
    max-width: 1280px;
  }
}
.nv-evox-root .row {
  --nv-gutter-x: 32px;
  --nv-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--nv-gutter-y));
  margin-right: calc(-0.5 * var(--nv-gutter-x));
  margin-left: calc(-0.5 * var(--nv-gutter-x));
}
.nv-evox-root .row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--nv-gutter-x) * 0.5);
  padding-left: calc(var(--nv-gutter-x) * 0.5);
  margin-top: var(--nv-gutter-y);
}
.nv-evox-root .col {
  flex: 1 0 0%;
}
.nv-evox-root .row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
.nv-evox-root .row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
.nv-evox-root .row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
.nv-evox-root .row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}
.nv-evox-root .row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
.nv-evox-root .row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
.nv-evox-root .row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}
.nv-evox-root .col-auto {
  flex: 0 0 auto;
  width: auto;
}
.nv-evox-root .col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.nv-evox-root .col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.nv-evox-root .col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.nv-evox-root .col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.nv-evox-root .col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.nv-evox-root .col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.nv-evox-root .col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.nv-evox-root .col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.nv-evox-root .col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.nv-evox-root .col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.nv-evox-root .col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.nv-evox-root .col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.nv-evox-root .offset-1 {
  margin-left: 8.33333333%;
}
.nv-evox-root .offset-2 {
  margin-left: 16.66666667%;
}
.nv-evox-root .offset-3 {
  margin-left: 25%;
}
.nv-evox-root .offset-4 {
  margin-left: 33.33333333%;
}
.nv-evox-root .offset-5 {
  margin-left: 41.66666667%;
}
.nv-evox-root .offset-6 {
  margin-left: 50%;
}
.nv-evox-root .offset-7 {
  margin-left: 58.33333333%;
}
.nv-evox-root .offset-8 {
  margin-left: 66.66666667%;
}
.nv-evox-root .offset-9 {
  margin-left: 75%;
}
.nv-evox-root .offset-10 {
  margin-left: 83.33333333%;
}
.nv-evox-root .offset-11 {
  margin-left: 91.66666667%;
}
.nv-evox-root .g-0,
.nv-evox-root .gx-0 {
  --nv-gutter-x: 0;
}
.nv-evox-root .g-0,
.nv-evox-root .gy-0 {
  --nv-gutter-y: 0;
}
.nv-evox-root .g-1,
.nv-evox-root .gx-1 {
  --nv-gutter-x: 0.5rem;
}
.nv-evox-root .g-1,
.nv-evox-root .gy-1 {
  --nv-gutter-y: 0.5rem;
}
.nv-evox-root .g-2,
.nv-evox-root .gx-2 {
  --nv-gutter-x: 1rem;
}
.nv-evox-root .g-2,
.nv-evox-root .gy-2 {
  --nv-gutter-y: 1rem;
}
.nv-evox-root .g-3,
.nv-evox-root .gx-3 {
  --nv-gutter-x: 2rem;
}
.nv-evox-root .g-3,
.nv-evox-root .gy-3 {
  --nv-gutter-y: 2rem;
}
.nv-evox-root .g-4,
.nv-evox-root .gx-4 {
  --nv-gutter-x: 3rem;
}
.nv-evox-root .g-4,
.nv-evox-root .gy-4 {
  --nv-gutter-y: 3rem;
}
.nv-evox-root .g-5,
.nv-evox-root .gx-5 {
  --nv-gutter-x: 6rem;
}
.nv-evox-root .g-5,
.nv-evox-root .gy-5 {
  --nv-gutter-y: 6rem;
}
.nv-evox-root .g--1,
.nv-evox-root .gx--1 {
  --nv-gutter-x: -0.5rem;
}
.nv-evox-root .g--1,
.nv-evox-root .gy--1 {
  --nv-gutter-y: -0.5rem;
}
.nv-evox-root .g--2,
.nv-evox-root .gx--2 {
  --nv-gutter-x: -1rem;
}
.nv-evox-root .g--2,
.nv-evox-root .gy--2 {
  --nv-gutter-y: -1rem;
}
.nv-evox-root .g--3,
.nv-evox-root .gx--3 {
  --nv-gutter-x: -2rem;
}
.nv-evox-root .g--3,
.nv-evox-root .gy--3 {
  --nv-gutter-y: -2rem;
}
.nv-evox-root .g--4,
.nv-evox-root .gx--4 {
  --nv-gutter-x: -3rem;
}
.nv-evox-root .g--4,
.nv-evox-root .gy--4 {
  --nv-gutter-y: -3rem;
}
.nv-evox-root .g--5,
.nv-evox-root .gx--5 {
  --nv-gutter-x: -6rem;
}
.nv-evox-root .g--5,
.nv-evox-root .gy--5 {
  --nv-gutter-y: -6rem;
}
@media (min-width: 480px) {
  .nv-evox-root .col-sm {
    flex: 1 0 0%;
  }
  .nv-evox-root .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .nv-evox-root .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .nv-evox-root .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .nv-evox-root .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .nv-evox-root .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .nv-evox-root .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .nv-evox-root .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .nv-evox-root .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .nv-evox-root .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .nv-evox-root .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .nv-evox-root .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .nv-evox-root .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .nv-evox-root .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .nv-evox-root .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .nv-evox-root .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .nv-evox-root .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .nv-evox-root .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .nv-evox-root .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .nv-evox-root .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .nv-evox-root .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .nv-evox-root .offset-sm-0 {
    margin-left: 0;
  }
  .nv-evox-root .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .nv-evox-root .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .nv-evox-root .offset-sm-3 {
    margin-left: 25%;
  }
  .nv-evox-root .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .nv-evox-root .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .nv-evox-root .offset-sm-6 {
    margin-left: 50%;
  }
  .nv-evox-root .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .nv-evox-root .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .nv-evox-root .offset-sm-9 {
    margin-left: 75%;
  }
  .nv-evox-root .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .nv-evox-root .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .nv-evox-root .g-sm-0,
  .nv-evox-root .gx-sm-0 {
    --nv-gutter-x: 0;
  }
  .nv-evox-root .g-sm-0,
  .nv-evox-root .gy-sm-0 {
    --nv-gutter-y: 0;
  }
  .nv-evox-root .g-sm-1,
  .nv-evox-root .gx-sm-1 {
    --nv-gutter-x: 0.5rem;
  }
  .nv-evox-root .g-sm-1,
  .nv-evox-root .gy-sm-1 {
    --nv-gutter-y: 0.5rem;
  }
  .nv-evox-root .g-sm-2,
  .nv-evox-root .gx-sm-2 {
    --nv-gutter-x: 1rem;
  }
  .nv-evox-root .g-sm-2,
  .nv-evox-root .gy-sm-2 {
    --nv-gutter-y: 1rem;
  }
  .nv-evox-root .g-sm-3,
  .nv-evox-root .gx-sm-3 {
    --nv-gutter-x: 2rem;
  }
  .nv-evox-root .g-sm-3,
  .nv-evox-root .gy-sm-3 {
    --nv-gutter-y: 2rem;
  }
  .nv-evox-root .g-sm-4,
  .nv-evox-root .gx-sm-4 {
    --nv-gutter-x: 3rem;
  }
  .nv-evox-root .g-sm-4,
  .nv-evox-root .gy-sm-4 {
    --nv-gutter-y: 3rem;
  }
  .nv-evox-root .g-sm-5,
  .nv-evox-root .gx-sm-5 {
    --nv-gutter-x: 6rem;
  }
  .nv-evox-root .g-sm-5,
  .nv-evox-root .gy-sm-5 {
    --nv-gutter-y: 6rem;
  }
  .nv-evox-root .g-sm--1,
  .nv-evox-root .gx-sm--1 {
    --nv-gutter-x: -0.5rem;
  }
  .nv-evox-root .g-sm--1,
  .nv-evox-root .gy-sm--1 {
    --nv-gutter-y: -0.5rem;
  }
  .nv-evox-root .g-sm--2,
  .nv-evox-root .gx-sm--2 {
    --nv-gutter-x: -1rem;
  }
  .nv-evox-root .g-sm--2,
  .nv-evox-root .gy-sm--2 {
    --nv-gutter-y: -1rem;
  }
  .nv-evox-root .g-sm--3,
  .nv-evox-root .gx-sm--3 {
    --nv-gutter-x: -2rem;
  }
  .nv-evox-root .g-sm--3,
  .nv-evox-root .gy-sm--3 {
    --nv-gutter-y: -2rem;
  }
  .nv-evox-root .g-sm--4,
  .nv-evox-root .gx-sm--4 {
    --nv-gutter-x: -3rem;
  }
  .nv-evox-root .g-sm--4,
  .nv-evox-root .gy-sm--4 {
    --nv-gutter-y: -3rem;
  }
  .nv-evox-root .g-sm--5,
  .nv-evox-root .gx-sm--5 {
    --nv-gutter-x: -6rem;
  }
  .nv-evox-root .g-sm--5,
  .nv-evox-root .gy-sm--5 {
    --nv-gutter-y: -6rem;
  }
}
@media (min-width: 768px) {
  .nv-evox-root .col-md {
    flex: 1 0 0%;
  }
  .nv-evox-root .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .nv-evox-root .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .nv-evox-root .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .nv-evox-root .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .nv-evox-root .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .nv-evox-root .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .nv-evox-root .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .nv-evox-root .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .nv-evox-root .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .nv-evox-root .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .nv-evox-root .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .nv-evox-root .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .nv-evox-root .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .nv-evox-root .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .nv-evox-root .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .nv-evox-root .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .nv-evox-root .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .nv-evox-root .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .nv-evox-root .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .nv-evox-root .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .nv-evox-root .offset-md-0 {
    margin-left: 0;
  }
  .nv-evox-root .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .nv-evox-root .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .nv-evox-root .offset-md-3 {
    margin-left: 25%;
  }
  .nv-evox-root .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .nv-evox-root .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .nv-evox-root .offset-md-6 {
    margin-left: 50%;
  }
  .nv-evox-root .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .nv-evox-root .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .nv-evox-root .offset-md-9 {
    margin-left: 75%;
  }
  .nv-evox-root .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .nv-evox-root .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .nv-evox-root .g-md-0,
  .nv-evox-root .gx-md-0 {
    --nv-gutter-x: 0;
  }
  .nv-evox-root .g-md-0,
  .nv-evox-root .gy-md-0 {
    --nv-gutter-y: 0;
  }
  .nv-evox-root .g-md-1,
  .nv-evox-root .gx-md-1 {
    --nv-gutter-x: 0.5rem;
  }
  .nv-evox-root .g-md-1,
  .nv-evox-root .gy-md-1 {
    --nv-gutter-y: 0.5rem;
  }
  .nv-evox-root .g-md-2,
  .nv-evox-root .gx-md-2 {
    --nv-gutter-x: 1rem;
  }
  .nv-evox-root .g-md-2,
  .nv-evox-root .gy-md-2 {
    --nv-gutter-y: 1rem;
  }
  .nv-evox-root .g-md-3,
  .nv-evox-root .gx-md-3 {
    --nv-gutter-x: 2rem;
  }
  .nv-evox-root .g-md-3,
  .nv-evox-root .gy-md-3 {
    --nv-gutter-y: 2rem;
  }
  .nv-evox-root .g-md-4,
  .nv-evox-root .gx-md-4 {
    --nv-gutter-x: 3rem;
  }
  .nv-evox-root .g-md-4,
  .nv-evox-root .gy-md-4 {
    --nv-gutter-y: 3rem;
  }
  .nv-evox-root .g-md-5,
  .nv-evox-root .gx-md-5 {
    --nv-gutter-x: 6rem;
  }
  .nv-evox-root .g-md-5,
  .nv-evox-root .gy-md-5 {
    --nv-gutter-y: 6rem;
  }
  .nv-evox-root .g-md--1,
  .nv-evox-root .gx-md--1 {
    --nv-gutter-x: -0.5rem;
  }
  .nv-evox-root .g-md--1,
  .nv-evox-root .gy-md--1 {
    --nv-gutter-y: -0.5rem;
  }
  .nv-evox-root .g-md--2,
  .nv-evox-root .gx-md--2 {
    --nv-gutter-x: -1rem;
  }
  .nv-evox-root .g-md--2,
  .nv-evox-root .gy-md--2 {
    --nv-gutter-y: -1rem;
  }
  .nv-evox-root .g-md--3,
  .nv-evox-root .gx-md--3 {
    --nv-gutter-x: -2rem;
  }
  .nv-evox-root .g-md--3,
  .nv-evox-root .gy-md--3 {
    --nv-gutter-y: -2rem;
  }
  .nv-evox-root .g-md--4,
  .nv-evox-root .gx-md--4 {
    --nv-gutter-x: -3rem;
  }
  .nv-evox-root .g-md--4,
  .nv-evox-root .gy-md--4 {
    --nv-gutter-y: -3rem;
  }
  .nv-evox-root .g-md--5,
  .nv-evox-root .gx-md--5 {
    --nv-gutter-x: -6rem;
  }
  .nv-evox-root .g-md--5,
  .nv-evox-root .gy-md--5 {
    --nv-gutter-y: -6rem;
  }
}
@media (min-width: 1024px) {
  .nv-evox-root .col-lg {
    flex: 1 0 0%;
  }
  .nv-evox-root .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .nv-evox-root .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .nv-evox-root .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .nv-evox-root .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .nv-evox-root .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .nv-evox-root .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .nv-evox-root .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .nv-evox-root .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .nv-evox-root .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .nv-evox-root .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .nv-evox-root .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .nv-evox-root .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .nv-evox-root .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .nv-evox-root .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .nv-evox-root .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .nv-evox-root .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .nv-evox-root .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .nv-evox-root .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .nv-evox-root .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .nv-evox-root .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .nv-evox-root .offset-lg-0 {
    margin-left: 0;
  }
  .nv-evox-root .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .nv-evox-root .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .nv-evox-root .offset-lg-3 {
    margin-left: 25%;
  }
  .nv-evox-root .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .nv-evox-root .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .nv-evox-root .offset-lg-6 {
    margin-left: 50%;
  }
  .nv-evox-root .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .nv-evox-root .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .nv-evox-root .offset-lg-9 {
    margin-left: 75%;
  }
  .nv-evox-root .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .nv-evox-root .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .nv-evox-root .g-lg-0,
  .nv-evox-root .gx-lg-0 {
    --nv-gutter-x: 0;
  }
  .nv-evox-root .g-lg-0,
  .nv-evox-root .gy-lg-0 {
    --nv-gutter-y: 0;
  }
  .nv-evox-root .g-lg-1,
  .nv-evox-root .gx-lg-1 {
    --nv-gutter-x: 0.5rem;
  }
  .nv-evox-root .g-lg-1,
  .nv-evox-root .gy-lg-1 {
    --nv-gutter-y: 0.5rem;
  }
  .nv-evox-root .g-lg-2,
  .nv-evox-root .gx-lg-2 {
    --nv-gutter-x: 1rem;
  }
  .nv-evox-root .g-lg-2,
  .nv-evox-root .gy-lg-2 {
    --nv-gutter-y: 1rem;
  }
  .nv-evox-root .g-lg-3,
  .nv-evox-root .gx-lg-3 {
    --nv-gutter-x: 2rem;
  }
  .nv-evox-root .g-lg-3,
  .nv-evox-root .gy-lg-3 {
    --nv-gutter-y: 2rem;
  }
  .nv-evox-root .g-lg-4,
  .nv-evox-root .gx-lg-4 {
    --nv-gutter-x: 3rem;
  }
  .nv-evox-root .g-lg-4,
  .nv-evox-root .gy-lg-4 {
    --nv-gutter-y: 3rem;
  }
  .nv-evox-root .g-lg-5,
  .nv-evox-root .gx-lg-5 {
    --nv-gutter-x: 6rem;
  }
  .nv-evox-root .g-lg-5,
  .nv-evox-root .gy-lg-5 {
    --nv-gutter-y: 6rem;
  }
  .nv-evox-root .g-lg--1,
  .nv-evox-root .gx-lg--1 {
    --nv-gutter-x: -0.5rem;
  }
  .nv-evox-root .g-lg--1,
  .nv-evox-root .gy-lg--1 {
    --nv-gutter-y: -0.5rem;
  }
  .nv-evox-root .g-lg--2,
  .nv-evox-root .gx-lg--2 {
    --nv-gutter-x: -1rem;
  }
  .nv-evox-root .g-lg--2,
  .nv-evox-root .gy-lg--2 {
    --nv-gutter-y: -1rem;
  }
  .nv-evox-root .g-lg--3,
  .nv-evox-root .gx-lg--3 {
    --nv-gutter-x: -2rem;
  }
  .nv-evox-root .g-lg--3,
  .nv-evox-root .gy-lg--3 {
    --nv-gutter-y: -2rem;
  }
  .nv-evox-root .g-lg--4,
  .nv-evox-root .gx-lg--4 {
    --nv-gutter-x: -3rem;
  }
  .nv-evox-root .g-lg--4,
  .nv-evox-root .gy-lg--4 {
    --nv-gutter-y: -3rem;
  }
  .nv-evox-root .g-lg--5,
  .nv-evox-root .gx-lg--5 {
    --nv-gutter-x: -6rem;
  }
  .nv-evox-root .g-lg--5,
  .nv-evox-root .gy-lg--5 {
    --nv-gutter-y: -6rem;
  }
}
@media (min-width: 1280px) {
  .nv-evox-root .col-xl {
    flex: 1 0 0%;
  }
  .nv-evox-root .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .nv-evox-root .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .nv-evox-root .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .nv-evox-root .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .nv-evox-root .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .nv-evox-root .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .nv-evox-root .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .nv-evox-root .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .nv-evox-root .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .nv-evox-root .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .nv-evox-root .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .nv-evox-root .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .nv-evox-root .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .nv-evox-root .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .nv-evox-root .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .nv-evox-root .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .nv-evox-root .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .nv-evox-root .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .nv-evox-root .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .nv-evox-root .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .nv-evox-root .offset-xl-0 {
    margin-left: 0;
  }
  .nv-evox-root .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .nv-evox-root .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .nv-evox-root .offset-xl-3 {
    margin-left: 25%;
  }
  .nv-evox-root .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .nv-evox-root .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .nv-evox-root .offset-xl-6 {
    margin-left: 50%;
  }
  .nv-evox-root .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .nv-evox-root .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .nv-evox-root .offset-xl-9 {
    margin-left: 75%;
  }
  .nv-evox-root .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .nv-evox-root .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .nv-evox-root .g-xl-0,
  .nv-evox-root .gx-xl-0 {
    --nv-gutter-x: 0;
  }
  .nv-evox-root .g-xl-0,
  .nv-evox-root .gy-xl-0 {
    --nv-gutter-y: 0;
  }
  .nv-evox-root .g-xl-1,
  .nv-evox-root .gx-xl-1 {
    --nv-gutter-x: 0.5rem;
  }
  .nv-evox-root .g-xl-1,
  .nv-evox-root .gy-xl-1 {
    --nv-gutter-y: 0.5rem;
  }
  .nv-evox-root .g-xl-2,
  .nv-evox-root .gx-xl-2 {
    --nv-gutter-x: 1rem;
  }
  .nv-evox-root .g-xl-2,
  .nv-evox-root .gy-xl-2 {
    --nv-gutter-y: 1rem;
  }
  .nv-evox-root .g-xl-3,
  .nv-evox-root .gx-xl-3 {
    --nv-gutter-x: 2rem;
  }
  .nv-evox-root .g-xl-3,
  .nv-evox-root .gy-xl-3 {
    --nv-gutter-y: 2rem;
  }
  .nv-evox-root .g-xl-4,
  .nv-evox-root .gx-xl-4 {
    --nv-gutter-x: 3rem;
  }
  .nv-evox-root .g-xl-4,
  .nv-evox-root .gy-xl-4 {
    --nv-gutter-y: 3rem;
  }
  .nv-evox-root .g-xl-5,
  .nv-evox-root .gx-xl-5 {
    --nv-gutter-x: 6rem;
  }
  .nv-evox-root .g-xl-5,
  .nv-evox-root .gy-xl-5 {
    --nv-gutter-y: 6rem;
  }
  .nv-evox-root .g-xl--1,
  .nv-evox-root .gx-xl--1 {
    --nv-gutter-x: -0.5rem;
  }
  .nv-evox-root .g-xl--1,
  .nv-evox-root .gy-xl--1 {
    --nv-gutter-y: -0.5rem;
  }
  .nv-evox-root .g-xl--2,
  .nv-evox-root .gx-xl--2 {
    --nv-gutter-x: -1rem;
  }
  .nv-evox-root .g-xl--2,
  .nv-evox-root .gy-xl--2 {
    --nv-gutter-y: -1rem;
  }
  .nv-evox-root .g-xl--3,
  .nv-evox-root .gx-xl--3 {
    --nv-gutter-x: -2rem;
  }
  .nv-evox-root .g-xl--3,
  .nv-evox-root .gy-xl--3 {
    --nv-gutter-y: -2rem;
  }
  .nv-evox-root .g-xl--4,
  .nv-evox-root .gx-xl--4 {
    --nv-gutter-x: -3rem;
  }
  .nv-evox-root .g-xl--4,
  .nv-evox-root .gy-xl--4 {
    --nv-gutter-y: -3rem;
  }
  .nv-evox-root .g-xl--5,
  .nv-evox-root .gx-xl--5 {
    --nv-gutter-x: -6rem;
  }
  .nv-evox-root .g-xl--5,
  .nv-evox-root .gy-xl--5 {
    --nv-gutter-y: -6rem;
  }
}
.nv-evox-root .clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.nv-evox-root .text-bg-primary {
  color: #fff !important;
  background-color: RGBA(0, 52, 96, var(--nv-bg-opacity, 1)) !important;
}
.nv-evox-root .text-bg-primary-0 {
  color: #fff !important;
  background-color: RGBA(42, 105, 127, var(--nv-bg-opacity, 1)) !important;
}
.nv-evox-root .text-bg-primary-1 {
  color: #fff !important;
  background-color: RGBA(96, 140, 161, var(--nv-bg-opacity, 1)) !important;
}
.nv-evox-root .text-bg-primary-2 {
  color: #003460 !important;
  background-color: RGBA(174, 194, 206, var(--nv-bg-opacity, 1)) !important;
}
.nv-evox-root .text-bg-primary-3 {
  color: #003460 !important;
  background-color: RGBA(224, 235, 241, var(--nv-bg-opacity, 1)) !important;
}
.nv-evox-root .text-bg-primary-35 {
  color: #003460 !important;
  background-color: RGBA(241, 246, 249, var(--nv-bg-opacity, 1)) !important;
}
.nv-evox-root .text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(0, 155, 183, var(--nv-bg-opacity, 1)) !important;
}
.nv-evox-root .text-bg-secondary-hover {
  color: #fff !important;
  background-color: RGBA(3, 133, 158, var(--nv-bg-opacity, 1)) !important;
}
.nv-evox-root .text-bg-accent {
  color: #003460 !important;
  background-color: RGBA(255, 124, 107, var(--nv-bg-opacity, 1)) !important;
}
.nv-evox-root .text-bg-error {
  color: #003460 !important;
  background-color: RGBA(255, 124, 107, var(--nv-bg-opacity, 1)) !important;
}
.nv-evox-root .text-bg-warning {
  color: #003460 !important;
  background-color: RGBA(255, 214, 49, var(--nv-bg-opacity, 1)) !important;
}
.nv-evox-root .text-bg-success {
  color: #003460 !important;
  background-color: RGBA(68, 173, 75, var(--nv-bg-opacity, 1)) !important;
}
.nv-evox-root .link-primary {
  color: #003460 !important;
}
.nv-evox-root .link-primary:hover, .nv-evox-root .link-primary:focus {
  color: #002a4d !important;
}
.nv-evox-root .link-primary-0 {
  color: #2a697f !important;
}
.nv-evox-root .link-primary-0:hover, .nv-evox-root .link-primary-0:focus {
  color: #225466 !important;
}
.nv-evox-root .link-primary-1 {
  color: #608ca1 !important;
}
.nv-evox-root .link-primary-1:hover, .nv-evox-root .link-primary-1:focus {
  color: #4d7081 !important;
}
.nv-evox-root .link-primary-2 {
  color: #aec2ce !important;
}
.nv-evox-root .link-primary-2:hover, .nv-evox-root .link-primary-2:focus {
  color: #beced8 !important;
}
.nv-evox-root .link-primary-3 {
  color: #e0ebf1 !important;
}
.nv-evox-root .link-primary-3:hover, .nv-evox-root .link-primary-3:focus {
  color: #e6eff4 !important;
}
.nv-evox-root .link-primary-35 {
  color: #f1f6f9 !important;
}
.nv-evox-root .link-primary-35:hover, .nv-evox-root .link-primary-35:focus {
  color: #f4f8fa !important;
}
.nv-evox-root .link-secondary {
  color: #009bb7 !important;
}
.nv-evox-root .link-secondary:hover, .nv-evox-root .link-secondary:focus {
  color: #007c92 !important;
}
.nv-evox-root .link-secondary-hover {
  color: #03859e !important;
}
.nv-evox-root .link-secondary-hover:hover, .nv-evox-root .link-secondary-hover:focus {
  color: #026a7e !important;
}
.nv-evox-root .link-accent {
  color: #ff7c6b !important;
}
.nv-evox-root .link-accent:hover, .nv-evox-root .link-accent:focus {
  color: #ff9689 !important;
}
.nv-evox-root .link-error {
  color: #ff7c6b !important;
}
.nv-evox-root .link-error:hover, .nv-evox-root .link-error:focus {
  color: #ff9689 !important;
}
.nv-evox-root .link-warning {
  color: #ffd631 !important;
}
.nv-evox-root .link-warning:hover, .nv-evox-root .link-warning:focus {
  color: #ffde5a !important;
}
.nv-evox-root .link-success {
  color: #44ad4b !important;
}
.nv-evox-root .link-success:hover, .nv-evox-root .link-success:focus {
  color: #69bd6f !important;
}
.nv-evox-root .ratio {
  position: relative;
  width: 100%;
}
.nv-evox-root .ratio::before {
  display: block;
  padding-top: var(--nv-aspect-ratio);
  content: "";
}
.nv-evox-root .ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.nv-evox-root .ratio-1x1 {
  --nv-aspect-ratio: 100%;
}
.nv-evox-root .ratio-4x3 {
  --nv-aspect-ratio: 75%;
}
.nv-evox-root .ratio-16x9 {
  --nv-aspect-ratio: 56.25%;
}
.nv-evox-root .ratio-21x9 {
  --nv-aspect-ratio: 42.8571428571%;
}
.nv-evox-root .fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 900;
}
.nv-evox-root .fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 900;
}
.nv-evox-root .sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}
.nv-evox-root .sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}
@media (min-width: 480px) {
  .nv-evox-root .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .nv-evox-root .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .nv-evox-root .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .nv-evox-root .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1024px) {
  .nv-evox-root .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .nv-evox-root .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1280px) {
  .nv-evox-root .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .nv-evox-root .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.nv-evox-root .hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}
.nv-evox-root .vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}
.nv-evox-root .visually-hidden,
.nv-evox-root .visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.nv-evox-root .stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}
.nv-evox-root .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.nv-evox-root .vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}
.nv-evox-root .align-baseline {
  vertical-align: baseline !important;
}
.nv-evox-root .align-top {
  vertical-align: top !important;
}
.nv-evox-root .align-middle {
  vertical-align: middle !important;
}
.nv-evox-root .align-bottom {
  vertical-align: bottom !important;
}
.nv-evox-root .align-text-bottom {
  vertical-align: text-bottom !important;
}
.nv-evox-root .align-text-top {
  vertical-align: text-top !important;
}
.nv-evox-root .float-start {
  float: left !important;
}
.nv-evox-root .float-end {
  float: right !important;
}
.nv-evox-root .float-none {
  float: none !important;
}
.nv-evox-root .opacity-0 {
  opacity: 0 !important;
}
.nv-evox-root .opacity-25 {
  opacity: 0.25 !important;
}
.nv-evox-root .opacity-50 {
  opacity: 0.5 !important;
}
.nv-evox-root .opacity-75 {
  opacity: 0.75 !important;
}
.nv-evox-root .opacity-100 {
  opacity: 1 !important;
}
.nv-evox-root .overflow-auto {
  overflow: auto !important;
}
.nv-evox-root .overflow-hidden {
  overflow: hidden !important;
}
.nv-evox-root .overflow-visible {
  overflow: visible !important;
}
.nv-evox-root .overflow-scroll {
  overflow: scroll !important;
}
.nv-evox-root .d-inline {
  display: inline !important;
}
.nv-evox-root .d-inline-block {
  display: inline-block !important;
}
.nv-evox-root .d-block {
  display: block !important;
}
.nv-evox-root .d-grid {
  display: grid !important;
}
.nv-evox-root .d-table {
  display: table !important;
}
.nv-evox-root .d-table-row {
  display: table-row !important;
}
.nv-evox-root .d-table-cell {
  display: table-cell !important;
}
.nv-evox-root .d-flex {
  display: flex !important;
}
.nv-evox-root .d-inline-flex {
  display: inline-flex !important;
}
.nv-evox-root .d-none {
  display: none !important;
}
.nv-evox-root .shadow {
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.15) !important;
}
.nv-evox-root .shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05) !important;
}
.nv-evox-root .shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.nv-evox-root .shadow-none {
  box-shadow: none !important;
}
.nv-evox-root .position-static {
  position: static !important;
}
.nv-evox-root .position-relative {
  position: relative !important;
}
.nv-evox-root .position-absolute {
  position: absolute !important;
}
.nv-evox-root .position-fixed {
  position: fixed !important;
}
.nv-evox-root .position-sticky {
  position: sticky !important;
}
.nv-evox-root .top-0 {
  top: 0 !important;
}
.nv-evox-root .top-50 {
  top: 50% !important;
}
.nv-evox-root .top-100 {
  top: 100% !important;
}
.nv-evox-root .bottom-0 {
  bottom: 0 !important;
}
.nv-evox-root .bottom-50 {
  bottom: 50% !important;
}
.nv-evox-root .bottom-100 {
  bottom: 100% !important;
}
.nv-evox-root .start-0 {
  left: 0 !important;
}
.nv-evox-root .start-50 {
  left: 50% !important;
}
.nv-evox-root .start-100 {
  left: 100% !important;
}
.nv-evox-root .end-0 {
  right: 0 !important;
}
.nv-evox-root .end-50 {
  right: 50% !important;
}
.nv-evox-root .end-100 {
  right: 100% !important;
}
.nv-evox-root .translate-middle {
  transform: translate(-50%, -50%) !important;
}
.nv-evox-root .translate-middle-x {
  transform: translateX(-50%) !important;
}
.nv-evox-root .translate-middle-y {
  transform: translateY(-50%) !important;
}
.nv-evox-root .border {
  border: var(--nv-border-width) var(--nv-border-style) var(--nv-border-color) !important;
}
.nv-evox-root .border-0 {
  border: 0 !important;
}
.nv-evox-root .border-top {
  border-top: var(--nv-border-width) var(--nv-border-style) var(--nv-border-color) !important;
}
.nv-evox-root .border-top-0 {
  border-top: 0 !important;
}
.nv-evox-root .border-end {
  border-right: var(--nv-border-width) var(--nv-border-style) var(--nv-border-color) !important;
}
.nv-evox-root .border-end-0 {
  border-right: 0 !important;
}
.nv-evox-root .border-bottom {
  border-bottom: var(--nv-border-width) var(--nv-border-style) var(--nv-border-color) !important;
}
.nv-evox-root .border-bottom-0 {
  border-bottom: 0 !important;
}
.nv-evox-root .border-start {
  border-left: var(--nv-border-width) var(--nv-border-style) var(--nv-border-color) !important;
}
.nv-evox-root .border-start-0 {
  border-left: 0 !important;
}
.nv-evox-root .border-primary {
  --nv-border-opacity: 1;
  border-color: rgba(var(--nv-primary-rgb), var(--nv-border-opacity)) !important;
}
.nv-evox-root .border-primary-0 {
  --nv-border-opacity: 1;
  border-color: rgba(var(--nv-primary-0-rgb), var(--nv-border-opacity)) !important;
}
.nv-evox-root .border-primary-1 {
  --nv-border-opacity: 1;
  border-color: rgba(var(--nv-primary-1-rgb), var(--nv-border-opacity)) !important;
}
.nv-evox-root .border-primary-2 {
  --nv-border-opacity: 1;
  border-color: rgba(var(--nv-primary-2-rgb), var(--nv-border-opacity)) !important;
}
.nv-evox-root .border-primary-3 {
  --nv-border-opacity: 1;
  border-color: rgba(var(--nv-primary-3-rgb), var(--nv-border-opacity)) !important;
}
.nv-evox-root .border-primary-35 {
  --nv-border-opacity: 1;
  border-color: rgba(var(--nv-primary-35-rgb), var(--nv-border-opacity)) !important;
}
.nv-evox-root .border-secondary {
  --nv-border-opacity: 1;
  border-color: rgba(var(--nv-secondary-rgb), var(--nv-border-opacity)) !important;
}
.nv-evox-root .border-secondary-hover {
  --nv-border-opacity: 1;
  border-color: rgba(var(--nv-secondary-hover-rgb), var(--nv-border-opacity)) !important;
}
.nv-evox-root .border-accent {
  --nv-border-opacity: 1;
  border-color: rgba(var(--nv-accent-rgb), var(--nv-border-opacity)) !important;
}
.nv-evox-root .border-error {
  --nv-border-opacity: 1;
  border-color: rgba(var(--nv-error-rgb), var(--nv-border-opacity)) !important;
}
.nv-evox-root .border-warning {
  --nv-border-opacity: 1;
  border-color: rgba(var(--nv-warning-rgb), var(--nv-border-opacity)) !important;
}
.nv-evox-root .border-success {
  --nv-border-opacity: 1;
  border-color: rgba(var(--nv-success-rgb), var(--nv-border-opacity)) !important;
}
.nv-evox-root .border-light {
  --nv-border-opacity: 1;
  border-color: rgba(var(--nv-light-rgb), var(--nv-border-opacity)) !important;
}
.nv-evox-root .border-white {
  --nv-border-opacity: 1;
  border-color: rgba(var(--nv-white-rgb), var(--nv-border-opacity)) !important;
}
.nv-evox-root .border-1 {
  --nv-border-width: 1px;
}
.nv-evox-root .border-2 {
  --nv-border-width: 2px;
}
.nv-evox-root .border-3 {
  --nv-border-width: 3px;
}
.nv-evox-root .border-4 {
  --nv-border-width: 4px;
}
.nv-evox-root .border-5 {
  --nv-border-width: 5px;
}
.nv-evox-root .border-opacity-10 {
  --nv-border-opacity: 0.1;
}
.nv-evox-root .border-opacity-25 {
  --nv-border-opacity: 0.25;
}
.nv-evox-root .border-opacity-50 {
  --nv-border-opacity: 0.5;
}
.nv-evox-root .border-opacity-75 {
  --nv-border-opacity: 0.75;
}
.nv-evox-root .border-opacity-100 {
  --nv-border-opacity: 1;
}
.nv-evox-root .w-25 {
  width: 25% !important;
}
.nv-evox-root .w-50 {
  width: 50% !important;
}
.nv-evox-root .w-75 {
  width: 75% !important;
}
.nv-evox-root .w-100 {
  width: 100% !important;
}
.nv-evox-root .w-auto {
  width: auto !important;
}
.nv-evox-root .mw-100 {
  max-width: 100% !important;
}
.nv-evox-root .vw-100 {
  width: 100vw !important;
}
.nv-evox-root .min-vw-100 {
  min-width: 100vw !important;
}
.nv-evox-root .h-25 {
  height: 25% !important;
}
.nv-evox-root .h-50 {
  height: 50% !important;
}
.nv-evox-root .h-75 {
  height: 75% !important;
}
.nv-evox-root .h-100 {
  height: 100% !important;
}
.nv-evox-root .h-auto {
  height: auto !important;
}
.nv-evox-root .mh-100 {
  max-height: 100% !important;
}
.nv-evox-root .vh-100 {
  height: 100vh !important;
}
.nv-evox-root .min-vh-100 {
  min-height: 100vh !important;
}
.nv-evox-root .flex-fill {
  flex: 1 1 auto !important;
}
.nv-evox-root .flex-row {
  flex-direction: row !important;
}
.nv-evox-root .flex-column {
  flex-direction: column !important;
}
.nv-evox-root .flex-row-reverse {
  flex-direction: row-reverse !important;
}
.nv-evox-root .flex-column-reverse {
  flex-direction: column-reverse !important;
}
.nv-evox-root .flex-grow-0 {
  flex-grow: 0 !important;
}
.nv-evox-root .flex-grow-1 {
  flex-grow: 1 !important;
}
.nv-evox-root .flex-shrink-0 {
  flex-shrink: 0 !important;
}
.nv-evox-root .flex-shrink-1 {
  flex-shrink: 1 !important;
}
.nv-evox-root .flex-wrap {
  flex-wrap: wrap !important;
}
.nv-evox-root .flex-nowrap {
  flex-wrap: nowrap !important;
}
.nv-evox-root .flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.nv-evox-root .justify-content-start {
  justify-content: flex-start !important;
}
.nv-evox-root .justify-content-end {
  justify-content: flex-end !important;
}
.nv-evox-root .justify-content-center {
  justify-content: center !important;
}
.nv-evox-root .justify-content-between {
  justify-content: space-between !important;
}
.nv-evox-root .justify-content-around {
  justify-content: space-around !important;
}
.nv-evox-root .justify-content-evenly {
  justify-content: space-evenly !important;
}
.nv-evox-root .align-items-start {
  align-items: flex-start !important;
}
.nv-evox-root .align-items-end {
  align-items: flex-end !important;
}
.nv-evox-root .align-items-center {
  align-items: center !important;
}
.nv-evox-root .align-items-baseline {
  align-items: baseline !important;
}
.nv-evox-root .align-items-stretch {
  align-items: stretch !important;
}
.nv-evox-root .align-content-start {
  align-content: flex-start !important;
}
.nv-evox-root .align-content-end {
  align-content: flex-end !important;
}
.nv-evox-root .align-content-center {
  align-content: center !important;
}
.nv-evox-root .align-content-between {
  align-content: space-between !important;
}
.nv-evox-root .align-content-around {
  align-content: space-around !important;
}
.nv-evox-root .align-content-stretch {
  align-content: stretch !important;
}
.nv-evox-root .align-self-auto {
  align-self: auto !important;
}
.nv-evox-root .align-self-start {
  align-self: flex-start !important;
}
.nv-evox-root .align-self-end {
  align-self: flex-end !important;
}
.nv-evox-root .align-self-center {
  align-self: center !important;
}
.nv-evox-root .align-self-baseline {
  align-self: baseline !important;
}
.nv-evox-root .align-self-stretch {
  align-self: stretch !important;
}
.nv-evox-root .order-first {
  order: -1 !important;
}
.nv-evox-root .order-0 {
  order: 0 !important;
}
.nv-evox-root .order-1 {
  order: 1 !important;
}
.nv-evox-root .order-2 {
  order: 2 !important;
}
.nv-evox-root .order-3 {
  order: 3 !important;
}
.nv-evox-root .order-4 {
  order: 4 !important;
}
.nv-evox-root .order-5 {
  order: 5 !important;
}
.nv-evox-root .order-last {
  order: 6 !important;
}
.nv-evox-root .m-0 {
  margin: 0 !important;
}
.nv-evox-root .m-1 {
  margin: 0.5rem !important;
}
.nv-evox-root .m-2 {
  margin: 1rem !important;
}
.nv-evox-root .m-3 {
  margin: 2rem !important;
}
.nv-evox-root .m-4 {
  margin: 3rem !important;
}
.nv-evox-root .m-5 {
  margin: 6rem !important;
}
.nv-evox-root .m--1 {
  margin: -0.5rem !important;
}
.nv-evox-root .m--2 {
  margin: -1rem !important;
}
.nv-evox-root .m--3 {
  margin: -2rem !important;
}
.nv-evox-root .m--4 {
  margin: -3rem !important;
}
.nv-evox-root .m--5 {
  margin: -6rem !important;
}
.nv-evox-root .m-auto {
  margin: auto !important;
}
.nv-evox-root .mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.nv-evox-root .mx-1 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.nv-evox-root .mx-2 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.nv-evox-root .mx-3 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}
.nv-evox-root .mx-4 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.nv-evox-root .mx-5 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}
.nv-evox-root .mx--1 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}
.nv-evox-root .mx--2 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}
.nv-evox-root .mx--3 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}
.nv-evox-root .mx--4 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}
.nv-evox-root .mx--5 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}
.nv-evox-root .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.nv-evox-root .my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.nv-evox-root .my-1 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.nv-evox-root .my-2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.nv-evox-root .my-3 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.nv-evox-root .my-4 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.nv-evox-root .my-5 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}
.nv-evox-root .my--1 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}
.nv-evox-root .my--2 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}
.nv-evox-root .my--3 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}
.nv-evox-root .my--4 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}
.nv-evox-root .my--5 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}
.nv-evox-root .my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.nv-evox-root .mt-0 {
  margin-top: 0 !important;
}
.nv-evox-root .mt-1 {
  margin-top: 0.5rem !important;
}
.nv-evox-root .mt-2 {
  margin-top: 1rem !important;
}
.nv-evox-root .mt-3 {
  margin-top: 2rem !important;
}
.nv-evox-root .mt-4 {
  margin-top: 3rem !important;
}
.nv-evox-root .mt-5 {
  margin-top: 6rem !important;
}
.nv-evox-root .mt--1 {
  margin-top: -0.5rem !important;
}
.nv-evox-root .mt--2 {
  margin-top: -1rem !important;
}
.nv-evox-root .mt--3 {
  margin-top: -2rem !important;
}
.nv-evox-root .mt--4 {
  margin-top: -3rem !important;
}
.nv-evox-root .mt--5 {
  margin-top: -6rem !important;
}
.nv-evox-root .mt-auto {
  margin-top: auto !important;
}
.nv-evox-root .me-0 {
  margin-right: 0 !important;
}
.nv-evox-root .me-1 {
  margin-right: 0.5rem !important;
}
.nv-evox-root .me-2 {
  margin-right: 1rem !important;
}
.nv-evox-root .me-3 {
  margin-right: 2rem !important;
}
.nv-evox-root .me-4 {
  margin-right: 3rem !important;
}
.nv-evox-root .me-5 {
  margin-right: 6rem !important;
}
.nv-evox-root .me--1 {
  margin-right: -0.5rem !important;
}
.nv-evox-root .me--2 {
  margin-right: -1rem !important;
}
.nv-evox-root .me--3 {
  margin-right: -2rem !important;
}
.nv-evox-root .me--4 {
  margin-right: -3rem !important;
}
.nv-evox-root .me--5 {
  margin-right: -6rem !important;
}
.nv-evox-root .me-auto {
  margin-right: auto !important;
}
.nv-evox-root .mb-0 {
  margin-bottom: 0 !important;
}
.nv-evox-root .mb-1 {
  margin-bottom: 0.5rem !important;
}
.nv-evox-root .mb-2 {
  margin-bottom: 1rem !important;
}
.nv-evox-root .mb-3 {
  margin-bottom: 2rem !important;
}
.nv-evox-root .mb-4 {
  margin-bottom: 3rem !important;
}
.nv-evox-root .mb-5 {
  margin-bottom: 6rem !important;
}
.nv-evox-root .mb--1 {
  margin-bottom: -0.5rem !important;
}
.nv-evox-root .mb--2 {
  margin-bottom: -1rem !important;
}
.nv-evox-root .mb--3 {
  margin-bottom: -2rem !important;
}
.nv-evox-root .mb--4 {
  margin-bottom: -3rem !important;
}
.nv-evox-root .mb--5 {
  margin-bottom: -6rem !important;
}
.nv-evox-root .mb-auto {
  margin-bottom: auto !important;
}
.nv-evox-root .ms-0 {
  margin-left: 0 !important;
}
.nv-evox-root .ms-1 {
  margin-left: 0.5rem !important;
}
.nv-evox-root .ms-2 {
  margin-left: 1rem !important;
}
.nv-evox-root .ms-3 {
  margin-left: 2rem !important;
}
.nv-evox-root .ms-4 {
  margin-left: 3rem !important;
}
.nv-evox-root .ms-5 {
  margin-left: 6rem !important;
}
.nv-evox-root .ms--1 {
  margin-left: -0.5rem !important;
}
.nv-evox-root .ms--2 {
  margin-left: -1rem !important;
}
.nv-evox-root .ms--3 {
  margin-left: -2rem !important;
}
.nv-evox-root .ms--4 {
  margin-left: -3rem !important;
}
.nv-evox-root .ms--5 {
  margin-left: -6rem !important;
}
.nv-evox-root .ms-auto {
  margin-left: auto !important;
}
.nv-evox-root .p-0 {
  padding: 0 !important;
}
.nv-evox-root .p-1 {
  padding: 0.5rem !important;
}
.nv-evox-root .p-2 {
  padding: 1rem !important;
}
.nv-evox-root .p-3 {
  padding: 2rem !important;
}
.nv-evox-root .p-4 {
  padding: 3rem !important;
}
.nv-evox-root .p-5 {
  padding: 6rem !important;
}
.nv-evox-root .p--1 {
  padding: -0.5rem !important;
}
.nv-evox-root .p--2 {
  padding: -1rem !important;
}
.nv-evox-root .p--3 {
  padding: -2rem !important;
}
.nv-evox-root .p--4 {
  padding: -3rem !important;
}
.nv-evox-root .p--5 {
  padding: -6rem !important;
}
.nv-evox-root .px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.nv-evox-root .px-1 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.nv-evox-root .px-2 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.nv-evox-root .px-3 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}
.nv-evox-root .px-4 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.nv-evox-root .px-5 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}
.nv-evox-root .px--1 {
  padding-right: -0.5rem !important;
  padding-left: -0.5rem !important;
}
.nv-evox-root .px--2 {
  padding-right: -1rem !important;
  padding-left: -1rem !important;
}
.nv-evox-root .px--3 {
  padding-right: -2rem !important;
  padding-left: -2rem !important;
}
.nv-evox-root .px--4 {
  padding-right: -3rem !important;
  padding-left: -3rem !important;
}
.nv-evox-root .px--5 {
  padding-right: -6rem !important;
  padding-left: -6rem !important;
}
.nv-evox-root .py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.nv-evox-root .py-1 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.nv-evox-root .py-2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.nv-evox-root .py-3 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
.nv-evox-root .py-4 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.nv-evox-root .py-5 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}
.nv-evox-root .py--1 {
  padding-top: -0.5rem !important;
  padding-bottom: -0.5rem !important;
}
.nv-evox-root .py--2 {
  padding-top: -1rem !important;
  padding-bottom: -1rem !important;
}
.nv-evox-root .py--3 {
  padding-top: -2rem !important;
  padding-bottom: -2rem !important;
}
.nv-evox-root .py--4 {
  padding-top: -3rem !important;
  padding-bottom: -3rem !important;
}
.nv-evox-root .py--5 {
  padding-top: -6rem !important;
  padding-bottom: -6rem !important;
}
.nv-evox-root .pt-0 {
  padding-top: 0 !important;
}
.nv-evox-root .pt-1 {
  padding-top: 0.5rem !important;
}
.nv-evox-root .pt-2 {
  padding-top: 1rem !important;
}
.nv-evox-root .pt-3 {
  padding-top: 2rem !important;
}
.nv-evox-root .pt-4 {
  padding-top: 3rem !important;
}
.nv-evox-root .pt-5 {
  padding-top: 6rem !important;
}
.nv-evox-root .pt--1 {
  padding-top: -0.5rem !important;
}
.nv-evox-root .pt--2 {
  padding-top: -1rem !important;
}
.nv-evox-root .pt--3 {
  padding-top: -2rem !important;
}
.nv-evox-root .pt--4 {
  padding-top: -3rem !important;
}
.nv-evox-root .pt--5 {
  padding-top: -6rem !important;
}
.nv-evox-root .pe-0 {
  padding-right: 0 !important;
}
.nv-evox-root .pe-1 {
  padding-right: 0.5rem !important;
}
.nv-evox-root .pe-2 {
  padding-right: 1rem !important;
}
.nv-evox-root .pe-3 {
  padding-right: 2rem !important;
}
.nv-evox-root .pe-4 {
  padding-right: 3rem !important;
}
.nv-evox-root .pe-5 {
  padding-right: 6rem !important;
}
.nv-evox-root .pe--1 {
  padding-right: -0.5rem !important;
}
.nv-evox-root .pe--2 {
  padding-right: -1rem !important;
}
.nv-evox-root .pe--3 {
  padding-right: -2rem !important;
}
.nv-evox-root .pe--4 {
  padding-right: -3rem !important;
}
.nv-evox-root .pe--5 {
  padding-right: -6rem !important;
}
.nv-evox-root .pb-0 {
  padding-bottom: 0 !important;
}
.nv-evox-root .pb-1 {
  padding-bottom: 0.5rem !important;
}
.nv-evox-root .pb-2 {
  padding-bottom: 1rem !important;
}
.nv-evox-root .pb-3 {
  padding-bottom: 2rem !important;
}
.nv-evox-root .pb-4 {
  padding-bottom: 3rem !important;
}
.nv-evox-root .pb-5 {
  padding-bottom: 6rem !important;
}
.nv-evox-root .pb--1 {
  padding-bottom: -0.5rem !important;
}
.nv-evox-root .pb--2 {
  padding-bottom: -1rem !important;
}
.nv-evox-root .pb--3 {
  padding-bottom: -2rem !important;
}
.nv-evox-root .pb--4 {
  padding-bottom: -3rem !important;
}
.nv-evox-root .pb--5 {
  padding-bottom: -6rem !important;
}
.nv-evox-root .ps-0 {
  padding-left: 0 !important;
}
.nv-evox-root .ps-1 {
  padding-left: 0.5rem !important;
}
.nv-evox-root .ps-2 {
  padding-left: 1rem !important;
}
.nv-evox-root .ps-3 {
  padding-left: 2rem !important;
}
.nv-evox-root .ps-4 {
  padding-left: 3rem !important;
}
.nv-evox-root .ps-5 {
  padding-left: 6rem !important;
}
.nv-evox-root .ps--1 {
  padding-left: -0.5rem !important;
}
.nv-evox-root .ps--2 {
  padding-left: -1rem !important;
}
.nv-evox-root .ps--3 {
  padding-left: -2rem !important;
}
.nv-evox-root .ps--4 {
  padding-left: -3rem !important;
}
.nv-evox-root .ps--5 {
  padding-left: -6rem !important;
}
.nv-evox-root .gap-0 {
  gap: 0 !important;
}
.nv-evox-root .gap-1 {
  gap: 0.5rem !important;
}
.nv-evox-root .gap-2 {
  gap: 1rem !important;
}
.nv-evox-root .gap-3 {
  gap: 2rem !important;
}
.nv-evox-root .gap-4 {
  gap: 3rem !important;
}
.nv-evox-root .gap-5 {
  gap: 6rem !important;
}
.nv-evox-root .gap--1 {
  gap: -0.5rem !important;
}
.nv-evox-root .gap--2 {
  gap: -1rem !important;
}
.nv-evox-root .gap--3 {
  gap: -2rem !important;
}
.nv-evox-root .gap--4 {
  gap: -3rem !important;
}
.nv-evox-root .gap--5 {
  gap: -6rem !important;
}
.nv-evox-root .font-monospace {
  font-family: var(--nv-font-monospace) !important;
}
.nv-evox-root .fs-1 {
  font-size: calc(1.411875rem + 1.9425vw) !important;
}
.nv-evox-root .fs-2 {
  font-size: calc(1.37475rem + 1.497vw) !important;
}
.nv-evox-root .fs-3 {
  font-size: calc(1.2825rem + 0.39vw) !important;
}
.nv-evox-root .fs-4 {
  font-size: 1.125rem !important;
}
.nv-evox-root .fs-5 {
  font-size: 1.125rem !important;
}
.nv-evox-root .fs-6 {
  font-size: 1.125rem !important;
}
.nv-evox-root .fst-italic {
  font-style: italic !important;
}
.nv-evox-root .fst-normal {
  font-style: normal !important;
}
.nv-evox-root .fw-light {
  font-weight: 300 !important;
}
.nv-evox-root .fw-lighter {
  font-weight: lighter !important;
}
.nv-evox-root .fw-normal {
  font-weight: 400 !important;
}
.nv-evox-root .fw-bold {
  font-weight: 700 !important;
}
.nv-evox-root .fw-semibold {
  font-weight: 600 !important;
}
.nv-evox-root .fw-bolder {
  font-weight: bolder !important;
}
.nv-evox-root .lh-1 {
  line-height: 1 !important;
}
.nv-evox-root .lh-sm {
  line-height: 1.5 !important;
}
.nv-evox-root .lh-base {
  line-height: 1.5 !important;
}
.nv-evox-root .lh-lg {
  line-height: 1.5 !important;
}
.nv-evox-root .text-start {
  text-align: left !important;
}
.nv-evox-root .text-end {
  text-align: right !important;
}
.nv-evox-root .text-center {
  text-align: center !important;
}
.nv-evox-root .text-decoration-none {
  text-decoration: none !important;
}
.nv-evox-root .text-decoration-underline {
  text-decoration: underline !important;
}
.nv-evox-root .text-decoration-line-through {
  text-decoration: line-through !important;
}
.nv-evox-root .text-lowercase {
  text-transform: lowercase !important;
}
.nv-evox-root .text-uppercase {
  text-transform: uppercase !important;
}
.nv-evox-root .text-capitalize {
  text-transform: capitalize !important;
}
.nv-evox-root .text-wrap {
  white-space: normal !important;
}
.nv-evox-root .text-nowrap {
  white-space: nowrap !important;
}
.nv-evox-root .text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.nv-evox-root .text-primary {
  --nv-text-opacity: 1;
  color: rgba(var(--nv-primary-rgb), var(--nv-text-opacity)) !important;
}
.nv-evox-root .text-primary-0 {
  --nv-text-opacity: 1;
  color: rgba(var(--nv-primary-0-rgb), var(--nv-text-opacity)) !important;
}
.nv-evox-root .text-primary-1 {
  --nv-text-opacity: 1;
  color: rgba(var(--nv-primary-1-rgb), var(--nv-text-opacity)) !important;
}
.nv-evox-root .text-primary-2 {
  --nv-text-opacity: 1;
  color: rgba(var(--nv-primary-2-rgb), var(--nv-text-opacity)) !important;
}
.nv-evox-root .text-primary-3 {
  --nv-text-opacity: 1;
  color: rgba(var(--nv-primary-3-rgb), var(--nv-text-opacity)) !important;
}
.nv-evox-root .text-primary-35 {
  --nv-text-opacity: 1;
  color: rgba(var(--nv-primary-35-rgb), var(--nv-text-opacity)) !important;
}
.nv-evox-root .text-secondary {
  --nv-text-opacity: 1;
  color: rgba(var(--nv-secondary-rgb), var(--nv-text-opacity)) !important;
}
.nv-evox-root .text-secondary-hover {
  --nv-text-opacity: 1;
  color: rgba(var(--nv-secondary-hover-rgb), var(--nv-text-opacity)) !important;
}
.nv-evox-root .text-accent {
  --nv-text-opacity: 1;
  color: rgba(var(--nv-accent-rgb), var(--nv-text-opacity)) !important;
}
.nv-evox-root .text-error {
  --nv-text-opacity: 1;
  color: rgba(var(--nv-error-rgb), var(--nv-text-opacity)) !important;
}
.nv-evox-root .text-warning {
  --nv-text-opacity: 1;
  color: rgba(var(--nv-warning-rgb), var(--nv-text-opacity)) !important;
}
.nv-evox-root .text-success {
  --nv-text-opacity: 1;
  color: rgba(var(--nv-success-rgb), var(--nv-text-opacity)) !important;
}
.nv-evox-root .text-light {
  --nv-text-opacity: 1;
  color: rgba(var(--nv-light-rgb), var(--nv-text-opacity)) !important;
}
.nv-evox-root .text-black {
  --nv-text-opacity: 1;
  color: rgba(var(--nv-black-rgb), var(--nv-text-opacity)) !important;
}
.nv-evox-root .text-white {
  --nv-text-opacity: 1;
  color: rgba(var(--nv-white-rgb), var(--nv-text-opacity)) !important;
}
.nv-evox-root .text-body {
  --nv-text-opacity: 1;
  color: rgba(var(--nv-body-color-rgb), var(--nv-text-opacity)) !important;
}
.nv-evox-root .text-muted {
  --nv-text-opacity: 1;
  color: #adb5bd !important;
}
.nv-evox-root .text-black-50 {
  --nv-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}
.nv-evox-root .text-white-50 {
  --nv-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}
.nv-evox-root .text-reset {
  --nv-text-opacity: 1;
  color: inherit !important;
}
.nv-evox-root .text-opacity-25 {
  --nv-text-opacity: 0.25;
}
.nv-evox-root .text-opacity-50 {
  --nv-text-opacity: 0.5;
}
.nv-evox-root .text-opacity-75 {
  --nv-text-opacity: 0.75;
}
.nv-evox-root .text-opacity-100 {
  --nv-text-opacity: 1;
}
.nv-evox-root .bg-primary {
  --nv-bg-opacity: 1;
  background-color: rgba(var(--nv-primary-rgb), var(--nv-bg-opacity)) !important;
}
.nv-evox-root .bg-primary-0 {
  --nv-bg-opacity: 1;
  background-color: rgba(var(--nv-primary-0-rgb), var(--nv-bg-opacity)) !important;
}
.nv-evox-root .bg-primary-1 {
  --nv-bg-opacity: 1;
  background-color: rgba(var(--nv-primary-1-rgb), var(--nv-bg-opacity)) !important;
}
.nv-evox-root .bg-primary-2 {
  --nv-bg-opacity: 1;
  background-color: rgba(var(--nv-primary-2-rgb), var(--nv-bg-opacity)) !important;
}
.nv-evox-root .bg-primary-3 {
  --nv-bg-opacity: 1;
  background-color: rgba(var(--nv-primary-3-rgb), var(--nv-bg-opacity)) !important;
}
.nv-evox-root .bg-primary-35 {
  --nv-bg-opacity: 1;
  background-color: rgba(var(--nv-primary-35-rgb), var(--nv-bg-opacity)) !important;
}
.nv-evox-root .bg-secondary {
  --nv-bg-opacity: 1;
  background-color: rgba(var(--nv-secondary-rgb), var(--nv-bg-opacity)) !important;
}
.nv-evox-root .bg-secondary-hover {
  --nv-bg-opacity: 1;
  background-color: rgba(var(--nv-secondary-hover-rgb), var(--nv-bg-opacity)) !important;
}
.nv-evox-root .bg-accent {
  --nv-bg-opacity: 1;
  background-color: rgba(var(--nv-accent-rgb), var(--nv-bg-opacity)) !important;
}
.nv-evox-root .bg-error {
  --nv-bg-opacity: 1;
  background-color: rgba(var(--nv-error-rgb), var(--nv-bg-opacity)) !important;
}
.nv-evox-root .bg-warning {
  --nv-bg-opacity: 1;
  background-color: rgba(var(--nv-warning-rgb), var(--nv-bg-opacity)) !important;
}
.nv-evox-root .bg-success {
  --nv-bg-opacity: 1;
  background-color: rgba(var(--nv-success-rgb), var(--nv-bg-opacity)) !important;
}
.nv-evox-root .bg-light {
  --nv-bg-opacity: 1;
  background-color: rgba(var(--nv-light-rgb), var(--nv-bg-opacity)) !important;
}
.nv-evox-root .bg-black {
  --nv-bg-opacity: 1;
  background-color: rgba(var(--nv-black-rgb), var(--nv-bg-opacity)) !important;
}
.nv-evox-root .bg-white {
  --nv-bg-opacity: 1;
  background-color: rgba(var(--nv-white-rgb), var(--nv-bg-opacity)) !important;
}
.nv-evox-root .bg-body {
  --nv-bg-opacity: 1;
  background-color: rgba(var(--nv-body-bg-rgb), var(--nv-bg-opacity)) !important;
}
.nv-evox-root .bg-transparent {
  --nv-bg-opacity: 1;
  background-color: transparent !important;
}
.nv-evox-root .bg-opacity-10 {
  --nv-bg-opacity: 0.1;
}
.nv-evox-root .bg-opacity-25 {
  --nv-bg-opacity: 0.25;
}
.nv-evox-root .bg-opacity-50 {
  --nv-bg-opacity: 0.5;
}
.nv-evox-root .bg-opacity-75 {
  --nv-bg-opacity: 0.75;
}
.nv-evox-root .bg-opacity-100 {
  --nv-bg-opacity: 1;
}
.nv-evox-root .bg-gradient {
  background-image: var(--nv-gradient) !important;
}
.nv-evox-root .user-select-all {
  user-select: all !important;
}
.nv-evox-root .user-select-auto {
  user-select: auto !important;
}
.nv-evox-root .user-select-none {
  user-select: none !important;
}
.nv-evox-root .pe-none {
  pointer-events: none !important;
}
.nv-evox-root .pe-auto {
  pointer-events: auto !important;
}
.nv-evox-root .rounded {
  border-radius: var(--nv-border-radius) !important;
}
.nv-evox-root .rounded-0 {
  border-radius: 0 !important;
}
.nv-evox-root .rounded-1 {
  border-radius: var(--nv-border-radius-sm) !important;
}
.nv-evox-root .rounded-2 {
  border-radius: var(--nv-border-radius) !important;
}
.nv-evox-root .rounded-3 {
  border-radius: var(--nv-border-radius-lg) !important;
}
.nv-evox-root .rounded-4 {
  border-radius: var(--nv-border-radius-xl) !important;
}
.nv-evox-root .rounded-5 {
  border-radius: var(--nv-border-radius-2xl) !important;
}
.nv-evox-root .rounded-circle {
  border-radius: 50% !important;
}
.nv-evox-root .rounded-pill {
  border-radius: var(--nv-border-radius-pill) !important;
}
.nv-evox-root .rounded-top {
  border-top-left-radius: var(--nv-border-radius) !important;
  border-top-right-radius: var(--nv-border-radius) !important;
}
.nv-evox-root .rounded-end {
  border-top-right-radius: var(--nv-border-radius) !important;
  border-bottom-right-radius: var(--nv-border-radius) !important;
}
.nv-evox-root .rounded-bottom {
  border-bottom-right-radius: var(--nv-border-radius) !important;
  border-bottom-left-radius: var(--nv-border-radius) !important;
}
.nv-evox-root .rounded-start {
  border-bottom-left-radius: var(--nv-border-radius) !important;
  border-top-left-radius: var(--nv-border-radius) !important;
}
.nv-evox-root .visible {
  visibility: visible !important;
}
.nv-evox-root .invisible {
  visibility: hidden !important;
}
@media (min-width: 480px) {
  .nv-evox-root .float-sm-start {
    float: left !important;
  }
  .nv-evox-root .float-sm-end {
    float: right !important;
  }
  .nv-evox-root .float-sm-none {
    float: none !important;
  }
  .nv-evox-root .d-sm-inline {
    display: inline !important;
  }
  .nv-evox-root .d-sm-inline-block {
    display: inline-block !important;
  }
  .nv-evox-root .d-sm-block {
    display: block !important;
  }
  .nv-evox-root .d-sm-grid {
    display: grid !important;
  }
  .nv-evox-root .d-sm-table {
    display: table !important;
  }
  .nv-evox-root .d-sm-table-row {
    display: table-row !important;
  }
  .nv-evox-root .d-sm-table-cell {
    display: table-cell !important;
  }
  .nv-evox-root .d-sm-flex {
    display: flex !important;
  }
  .nv-evox-root .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .nv-evox-root .d-sm-none {
    display: none !important;
  }
  .nv-evox-root .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .nv-evox-root .flex-sm-row {
    flex-direction: row !important;
  }
  .nv-evox-root .flex-sm-column {
    flex-direction: column !important;
  }
  .nv-evox-root .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .nv-evox-root .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .nv-evox-root .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .nv-evox-root .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .nv-evox-root .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .nv-evox-root .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .nv-evox-root .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .nv-evox-root .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .nv-evox-root .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .nv-evox-root .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .nv-evox-root .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .nv-evox-root .justify-content-sm-center {
    justify-content: center !important;
  }
  .nv-evox-root .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .nv-evox-root .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .nv-evox-root .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .nv-evox-root .align-items-sm-start {
    align-items: flex-start !important;
  }
  .nv-evox-root .align-items-sm-end {
    align-items: flex-end !important;
  }
  .nv-evox-root .align-items-sm-center {
    align-items: center !important;
  }
  .nv-evox-root .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .nv-evox-root .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .nv-evox-root .align-content-sm-start {
    align-content: flex-start !important;
  }
  .nv-evox-root .align-content-sm-end {
    align-content: flex-end !important;
  }
  .nv-evox-root .align-content-sm-center {
    align-content: center !important;
  }
  .nv-evox-root .align-content-sm-between {
    align-content: space-between !important;
  }
  .nv-evox-root .align-content-sm-around {
    align-content: space-around !important;
  }
  .nv-evox-root .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .nv-evox-root .align-self-sm-auto {
    align-self: auto !important;
  }
  .nv-evox-root .align-self-sm-start {
    align-self: flex-start !important;
  }
  .nv-evox-root .align-self-sm-end {
    align-self: flex-end !important;
  }
  .nv-evox-root .align-self-sm-center {
    align-self: center !important;
  }
  .nv-evox-root .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .nv-evox-root .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .nv-evox-root .order-sm-first {
    order: -1 !important;
  }
  .nv-evox-root .order-sm-0 {
    order: 0 !important;
  }
  .nv-evox-root .order-sm-1 {
    order: 1 !important;
  }
  .nv-evox-root .order-sm-2 {
    order: 2 !important;
  }
  .nv-evox-root .order-sm-3 {
    order: 3 !important;
  }
  .nv-evox-root .order-sm-4 {
    order: 4 !important;
  }
  .nv-evox-root .order-sm-5 {
    order: 5 !important;
  }
  .nv-evox-root .order-sm-last {
    order: 6 !important;
  }
  .nv-evox-root .m-sm-0 {
    margin: 0 !important;
  }
  .nv-evox-root .m-sm-1 {
    margin: 0.5rem !important;
  }
  .nv-evox-root .m-sm-2 {
    margin: 1rem !important;
  }
  .nv-evox-root .m-sm-3 {
    margin: 2rem !important;
  }
  .nv-evox-root .m-sm-4 {
    margin: 3rem !important;
  }
  .nv-evox-root .m-sm-5 {
    margin: 6rem !important;
  }
  .nv-evox-root .m-sm--1 {
    margin: -0.5rem !important;
  }
  .nv-evox-root .m-sm--2 {
    margin: -1rem !important;
  }
  .nv-evox-root .m-sm--3 {
    margin: -2rem !important;
  }
  .nv-evox-root .m-sm--4 {
    margin: -3rem !important;
  }
  .nv-evox-root .m-sm--5 {
    margin: -6rem !important;
  }
  .nv-evox-root .m-sm-auto {
    margin: auto !important;
  }
  .nv-evox-root .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .nv-evox-root .mx-sm-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .nv-evox-root .mx-sm-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .nv-evox-root .mx-sm-3 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .nv-evox-root .mx-sm-4 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .nv-evox-root .mx-sm-5 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .nv-evox-root .mx-sm--1 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .nv-evox-root .mx-sm--2 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .nv-evox-root .mx-sm--3 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .nv-evox-root .mx-sm--4 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .nv-evox-root .mx-sm--5 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .nv-evox-root .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .nv-evox-root .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .nv-evox-root .my-sm-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .nv-evox-root .my-sm-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .nv-evox-root .my-sm-3 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .nv-evox-root .my-sm-4 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .nv-evox-root .my-sm-5 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .nv-evox-root .my-sm--1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .nv-evox-root .my-sm--2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .nv-evox-root .my-sm--3 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .nv-evox-root .my-sm--4 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .nv-evox-root .my-sm--5 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .nv-evox-root .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .nv-evox-root .mt-sm-0 {
    margin-top: 0 !important;
  }
  .nv-evox-root .mt-sm-1 {
    margin-top: 0.5rem !important;
  }
  .nv-evox-root .mt-sm-2 {
    margin-top: 1rem !important;
  }
  .nv-evox-root .mt-sm-3 {
    margin-top: 2rem !important;
  }
  .nv-evox-root .mt-sm-4 {
    margin-top: 3rem !important;
  }
  .nv-evox-root .mt-sm-5 {
    margin-top: 6rem !important;
  }
  .nv-evox-root .mt-sm--1 {
    margin-top: -0.5rem !important;
  }
  .nv-evox-root .mt-sm--2 {
    margin-top: -1rem !important;
  }
  .nv-evox-root .mt-sm--3 {
    margin-top: -2rem !important;
  }
  .nv-evox-root .mt-sm--4 {
    margin-top: -3rem !important;
  }
  .nv-evox-root .mt-sm--5 {
    margin-top: -6rem !important;
  }
  .nv-evox-root .mt-sm-auto {
    margin-top: auto !important;
  }
  .nv-evox-root .me-sm-0 {
    margin-right: 0 !important;
  }
  .nv-evox-root .me-sm-1 {
    margin-right: 0.5rem !important;
  }
  .nv-evox-root .me-sm-2 {
    margin-right: 1rem !important;
  }
  .nv-evox-root .me-sm-3 {
    margin-right: 2rem !important;
  }
  .nv-evox-root .me-sm-4 {
    margin-right: 3rem !important;
  }
  .nv-evox-root .me-sm-5 {
    margin-right: 6rem !important;
  }
  .nv-evox-root .me-sm--1 {
    margin-right: -0.5rem !important;
  }
  .nv-evox-root .me-sm--2 {
    margin-right: -1rem !important;
  }
  .nv-evox-root .me-sm--3 {
    margin-right: -2rem !important;
  }
  .nv-evox-root .me-sm--4 {
    margin-right: -3rem !important;
  }
  .nv-evox-root .me-sm--5 {
    margin-right: -6rem !important;
  }
  .nv-evox-root .me-sm-auto {
    margin-right: auto !important;
  }
  .nv-evox-root .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .nv-evox-root .mb-sm-1 {
    margin-bottom: 0.5rem !important;
  }
  .nv-evox-root .mb-sm-2 {
    margin-bottom: 1rem !important;
  }
  .nv-evox-root .mb-sm-3 {
    margin-bottom: 2rem !important;
  }
  .nv-evox-root .mb-sm-4 {
    margin-bottom: 3rem !important;
  }
  .nv-evox-root .mb-sm-5 {
    margin-bottom: 6rem !important;
  }
  .nv-evox-root .mb-sm--1 {
    margin-bottom: -0.5rem !important;
  }
  .nv-evox-root .mb-sm--2 {
    margin-bottom: -1rem !important;
  }
  .nv-evox-root .mb-sm--3 {
    margin-bottom: -2rem !important;
  }
  .nv-evox-root .mb-sm--4 {
    margin-bottom: -3rem !important;
  }
  .nv-evox-root .mb-sm--5 {
    margin-bottom: -6rem !important;
  }
  .nv-evox-root .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .nv-evox-root .ms-sm-0 {
    margin-left: 0 !important;
  }
  .nv-evox-root .ms-sm-1 {
    margin-left: 0.5rem !important;
  }
  .nv-evox-root .ms-sm-2 {
    margin-left: 1rem !important;
  }
  .nv-evox-root .ms-sm-3 {
    margin-left: 2rem !important;
  }
  .nv-evox-root .ms-sm-4 {
    margin-left: 3rem !important;
  }
  .nv-evox-root .ms-sm-5 {
    margin-left: 6rem !important;
  }
  .nv-evox-root .ms-sm--1 {
    margin-left: -0.5rem !important;
  }
  .nv-evox-root .ms-sm--2 {
    margin-left: -1rem !important;
  }
  .nv-evox-root .ms-sm--3 {
    margin-left: -2rem !important;
  }
  .nv-evox-root .ms-sm--4 {
    margin-left: -3rem !important;
  }
  .nv-evox-root .ms-sm--5 {
    margin-left: -6rem !important;
  }
  .nv-evox-root .ms-sm-auto {
    margin-left: auto !important;
  }
  .nv-evox-root .p-sm-0 {
    padding: 0 !important;
  }
  .nv-evox-root .p-sm-1 {
    padding: 0.5rem !important;
  }
  .nv-evox-root .p-sm-2 {
    padding: 1rem !important;
  }
  .nv-evox-root .p-sm-3 {
    padding: 2rem !important;
  }
  .nv-evox-root .p-sm-4 {
    padding: 3rem !important;
  }
  .nv-evox-root .p-sm-5 {
    padding: 6rem !important;
  }
  .nv-evox-root .p-sm--1 {
    padding: -0.5rem !important;
  }
  .nv-evox-root .p-sm--2 {
    padding: -1rem !important;
  }
  .nv-evox-root .p-sm--3 {
    padding: -2rem !important;
  }
  .nv-evox-root .p-sm--4 {
    padding: -3rem !important;
  }
  .nv-evox-root .p-sm--5 {
    padding: -6rem !important;
  }
  .nv-evox-root .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .nv-evox-root .px-sm-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .nv-evox-root .px-sm-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .nv-evox-root .px-sm-3 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .nv-evox-root .px-sm-4 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .nv-evox-root .px-sm-5 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .nv-evox-root .px-sm--1 {
    padding-right: -0.5rem !important;
    padding-left: -0.5rem !important;
  }
  .nv-evox-root .px-sm--2 {
    padding-right: -1rem !important;
    padding-left: -1rem !important;
  }
  .nv-evox-root .px-sm--3 {
    padding-right: -2rem !important;
    padding-left: -2rem !important;
  }
  .nv-evox-root .px-sm--4 {
    padding-right: -3rem !important;
    padding-left: -3rem !important;
  }
  .nv-evox-root .px-sm--5 {
    padding-right: -6rem !important;
    padding-left: -6rem !important;
  }
  .nv-evox-root .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .nv-evox-root .py-sm-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .nv-evox-root .py-sm-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .nv-evox-root .py-sm-3 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .nv-evox-root .py-sm-4 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .nv-evox-root .py-sm-5 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .nv-evox-root .py-sm--1 {
    padding-top: -0.5rem !important;
    padding-bottom: -0.5rem !important;
  }
  .nv-evox-root .py-sm--2 {
    padding-top: -1rem !important;
    padding-bottom: -1rem !important;
  }
  .nv-evox-root .py-sm--3 {
    padding-top: -2rem !important;
    padding-bottom: -2rem !important;
  }
  .nv-evox-root .py-sm--4 {
    padding-top: -3rem !important;
    padding-bottom: -3rem !important;
  }
  .nv-evox-root .py-sm--5 {
    padding-top: -6rem !important;
    padding-bottom: -6rem !important;
  }
  .nv-evox-root .pt-sm-0 {
    padding-top: 0 !important;
  }
  .nv-evox-root .pt-sm-1 {
    padding-top: 0.5rem !important;
  }
  .nv-evox-root .pt-sm-2 {
    padding-top: 1rem !important;
  }
  .nv-evox-root .pt-sm-3 {
    padding-top: 2rem !important;
  }
  .nv-evox-root .pt-sm-4 {
    padding-top: 3rem !important;
  }
  .nv-evox-root .pt-sm-5 {
    padding-top: 6rem !important;
  }
  .nv-evox-root .pt-sm--1 {
    padding-top: -0.5rem !important;
  }
  .nv-evox-root .pt-sm--2 {
    padding-top: -1rem !important;
  }
  .nv-evox-root .pt-sm--3 {
    padding-top: -2rem !important;
  }
  .nv-evox-root .pt-sm--4 {
    padding-top: -3rem !important;
  }
  .nv-evox-root .pt-sm--5 {
    padding-top: -6rem !important;
  }
  .nv-evox-root .pe-sm-0 {
    padding-right: 0 !important;
  }
  .nv-evox-root .pe-sm-1 {
    padding-right: 0.5rem !important;
  }
  .nv-evox-root .pe-sm-2 {
    padding-right: 1rem !important;
  }
  .nv-evox-root .pe-sm-3 {
    padding-right: 2rem !important;
  }
  .nv-evox-root .pe-sm-4 {
    padding-right: 3rem !important;
  }
  .nv-evox-root .pe-sm-5 {
    padding-right: 6rem !important;
  }
  .nv-evox-root .pe-sm--1 {
    padding-right: -0.5rem !important;
  }
  .nv-evox-root .pe-sm--2 {
    padding-right: -1rem !important;
  }
  .nv-evox-root .pe-sm--3 {
    padding-right: -2rem !important;
  }
  .nv-evox-root .pe-sm--4 {
    padding-right: -3rem !important;
  }
  .nv-evox-root .pe-sm--5 {
    padding-right: -6rem !important;
  }
  .nv-evox-root .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .nv-evox-root .pb-sm-1 {
    padding-bottom: 0.5rem !important;
  }
  .nv-evox-root .pb-sm-2 {
    padding-bottom: 1rem !important;
  }
  .nv-evox-root .pb-sm-3 {
    padding-bottom: 2rem !important;
  }
  .nv-evox-root .pb-sm-4 {
    padding-bottom: 3rem !important;
  }
  .nv-evox-root .pb-sm-5 {
    padding-bottom: 6rem !important;
  }
  .nv-evox-root .pb-sm--1 {
    padding-bottom: -0.5rem !important;
  }
  .nv-evox-root .pb-sm--2 {
    padding-bottom: -1rem !important;
  }
  .nv-evox-root .pb-sm--3 {
    padding-bottom: -2rem !important;
  }
  .nv-evox-root .pb-sm--4 {
    padding-bottom: -3rem !important;
  }
  .nv-evox-root .pb-sm--5 {
    padding-bottom: -6rem !important;
  }
  .nv-evox-root .ps-sm-0 {
    padding-left: 0 !important;
  }
  .nv-evox-root .ps-sm-1 {
    padding-left: 0.5rem !important;
  }
  .nv-evox-root .ps-sm-2 {
    padding-left: 1rem !important;
  }
  .nv-evox-root .ps-sm-3 {
    padding-left: 2rem !important;
  }
  .nv-evox-root .ps-sm-4 {
    padding-left: 3rem !important;
  }
  .nv-evox-root .ps-sm-5 {
    padding-left: 6rem !important;
  }
  .nv-evox-root .ps-sm--1 {
    padding-left: -0.5rem !important;
  }
  .nv-evox-root .ps-sm--2 {
    padding-left: -1rem !important;
  }
  .nv-evox-root .ps-sm--3 {
    padding-left: -2rem !important;
  }
  .nv-evox-root .ps-sm--4 {
    padding-left: -3rem !important;
  }
  .nv-evox-root .ps-sm--5 {
    padding-left: -6rem !important;
  }
  .nv-evox-root .gap-sm-0 {
    gap: 0 !important;
  }
  .nv-evox-root .gap-sm-1 {
    gap: 0.5rem !important;
  }
  .nv-evox-root .gap-sm-2 {
    gap: 1rem !important;
  }
  .nv-evox-root .gap-sm-3 {
    gap: 2rem !important;
  }
  .nv-evox-root .gap-sm-4 {
    gap: 3rem !important;
  }
  .nv-evox-root .gap-sm-5 {
    gap: 6rem !important;
  }
  .nv-evox-root .gap-sm--1 {
    gap: -0.5rem !important;
  }
  .nv-evox-root .gap-sm--2 {
    gap: -1rem !important;
  }
  .nv-evox-root .gap-sm--3 {
    gap: -2rem !important;
  }
  .nv-evox-root .gap-sm--4 {
    gap: -3rem !important;
  }
  .nv-evox-root .gap-sm--5 {
    gap: -6rem !important;
  }
  .nv-evox-root .text-sm-start {
    text-align: left !important;
  }
  .nv-evox-root .text-sm-end {
    text-align: right !important;
  }
  .nv-evox-root .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .nv-evox-root .float-md-start {
    float: left !important;
  }
  .nv-evox-root .float-md-end {
    float: right !important;
  }
  .nv-evox-root .float-md-none {
    float: none !important;
  }
  .nv-evox-root .d-md-inline {
    display: inline !important;
  }
  .nv-evox-root .d-md-inline-block {
    display: inline-block !important;
  }
  .nv-evox-root .d-md-block {
    display: block !important;
  }
  .nv-evox-root .d-md-grid {
    display: grid !important;
  }
  .nv-evox-root .d-md-table {
    display: table !important;
  }
  .nv-evox-root .d-md-table-row {
    display: table-row !important;
  }
  .nv-evox-root .d-md-table-cell {
    display: table-cell !important;
  }
  .nv-evox-root .d-md-flex {
    display: flex !important;
  }
  .nv-evox-root .d-md-inline-flex {
    display: inline-flex !important;
  }
  .nv-evox-root .d-md-none {
    display: none !important;
  }
  .nv-evox-root .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .nv-evox-root .flex-md-row {
    flex-direction: row !important;
  }
  .nv-evox-root .flex-md-column {
    flex-direction: column !important;
  }
  .nv-evox-root .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .nv-evox-root .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .nv-evox-root .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .nv-evox-root .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .nv-evox-root .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .nv-evox-root .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .nv-evox-root .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .nv-evox-root .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .nv-evox-root .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .nv-evox-root .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .nv-evox-root .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .nv-evox-root .justify-content-md-center {
    justify-content: center !important;
  }
  .nv-evox-root .justify-content-md-between {
    justify-content: space-between !important;
  }
  .nv-evox-root .justify-content-md-around {
    justify-content: space-around !important;
  }
  .nv-evox-root .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .nv-evox-root .align-items-md-start {
    align-items: flex-start !important;
  }
  .nv-evox-root .align-items-md-end {
    align-items: flex-end !important;
  }
  .nv-evox-root .align-items-md-center {
    align-items: center !important;
  }
  .nv-evox-root .align-items-md-baseline {
    align-items: baseline !important;
  }
  .nv-evox-root .align-items-md-stretch {
    align-items: stretch !important;
  }
  .nv-evox-root .align-content-md-start {
    align-content: flex-start !important;
  }
  .nv-evox-root .align-content-md-end {
    align-content: flex-end !important;
  }
  .nv-evox-root .align-content-md-center {
    align-content: center !important;
  }
  .nv-evox-root .align-content-md-between {
    align-content: space-between !important;
  }
  .nv-evox-root .align-content-md-around {
    align-content: space-around !important;
  }
  .nv-evox-root .align-content-md-stretch {
    align-content: stretch !important;
  }
  .nv-evox-root .align-self-md-auto {
    align-self: auto !important;
  }
  .nv-evox-root .align-self-md-start {
    align-self: flex-start !important;
  }
  .nv-evox-root .align-self-md-end {
    align-self: flex-end !important;
  }
  .nv-evox-root .align-self-md-center {
    align-self: center !important;
  }
  .nv-evox-root .align-self-md-baseline {
    align-self: baseline !important;
  }
  .nv-evox-root .align-self-md-stretch {
    align-self: stretch !important;
  }
  .nv-evox-root .order-md-first {
    order: -1 !important;
  }
  .nv-evox-root .order-md-0 {
    order: 0 !important;
  }
  .nv-evox-root .order-md-1 {
    order: 1 !important;
  }
  .nv-evox-root .order-md-2 {
    order: 2 !important;
  }
  .nv-evox-root .order-md-3 {
    order: 3 !important;
  }
  .nv-evox-root .order-md-4 {
    order: 4 !important;
  }
  .nv-evox-root .order-md-5 {
    order: 5 !important;
  }
  .nv-evox-root .order-md-last {
    order: 6 !important;
  }
  .nv-evox-root .m-md-0 {
    margin: 0 !important;
  }
  .nv-evox-root .m-md-1 {
    margin: 0.5rem !important;
  }
  .nv-evox-root .m-md-2 {
    margin: 1rem !important;
  }
  .nv-evox-root .m-md-3 {
    margin: 2rem !important;
  }
  .nv-evox-root .m-md-4 {
    margin: 3rem !important;
  }
  .nv-evox-root .m-md-5 {
    margin: 6rem !important;
  }
  .nv-evox-root .m-md--1 {
    margin: -0.5rem !important;
  }
  .nv-evox-root .m-md--2 {
    margin: -1rem !important;
  }
  .nv-evox-root .m-md--3 {
    margin: -2rem !important;
  }
  .nv-evox-root .m-md--4 {
    margin: -3rem !important;
  }
  .nv-evox-root .m-md--5 {
    margin: -6rem !important;
  }
  .nv-evox-root .m-md-auto {
    margin: auto !important;
  }
  .nv-evox-root .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .nv-evox-root .mx-md-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .nv-evox-root .mx-md-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .nv-evox-root .mx-md-3 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .nv-evox-root .mx-md-4 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .nv-evox-root .mx-md-5 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .nv-evox-root .mx-md--1 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .nv-evox-root .mx-md--2 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .nv-evox-root .mx-md--3 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .nv-evox-root .mx-md--4 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .nv-evox-root .mx-md--5 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .nv-evox-root .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .nv-evox-root .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .nv-evox-root .my-md-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .nv-evox-root .my-md-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .nv-evox-root .my-md-3 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .nv-evox-root .my-md-4 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .nv-evox-root .my-md-5 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .nv-evox-root .my-md--1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .nv-evox-root .my-md--2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .nv-evox-root .my-md--3 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .nv-evox-root .my-md--4 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .nv-evox-root .my-md--5 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .nv-evox-root .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .nv-evox-root .mt-md-0 {
    margin-top: 0 !important;
  }
  .nv-evox-root .mt-md-1 {
    margin-top: 0.5rem !important;
  }
  .nv-evox-root .mt-md-2 {
    margin-top: 1rem !important;
  }
  .nv-evox-root .mt-md-3 {
    margin-top: 2rem !important;
  }
  .nv-evox-root .mt-md-4 {
    margin-top: 3rem !important;
  }
  .nv-evox-root .mt-md-5 {
    margin-top: 6rem !important;
  }
  .nv-evox-root .mt-md--1 {
    margin-top: -0.5rem !important;
  }
  .nv-evox-root .mt-md--2 {
    margin-top: -1rem !important;
  }
  .nv-evox-root .mt-md--3 {
    margin-top: -2rem !important;
  }
  .nv-evox-root .mt-md--4 {
    margin-top: -3rem !important;
  }
  .nv-evox-root .mt-md--5 {
    margin-top: -6rem !important;
  }
  .nv-evox-root .mt-md-auto {
    margin-top: auto !important;
  }
  .nv-evox-root .me-md-0 {
    margin-right: 0 !important;
  }
  .nv-evox-root .me-md-1 {
    margin-right: 0.5rem !important;
  }
  .nv-evox-root .me-md-2 {
    margin-right: 1rem !important;
  }
  .nv-evox-root .me-md-3 {
    margin-right: 2rem !important;
  }
  .nv-evox-root .me-md-4 {
    margin-right: 3rem !important;
  }
  .nv-evox-root .me-md-5 {
    margin-right: 6rem !important;
  }
  .nv-evox-root .me-md--1 {
    margin-right: -0.5rem !important;
  }
  .nv-evox-root .me-md--2 {
    margin-right: -1rem !important;
  }
  .nv-evox-root .me-md--3 {
    margin-right: -2rem !important;
  }
  .nv-evox-root .me-md--4 {
    margin-right: -3rem !important;
  }
  .nv-evox-root .me-md--5 {
    margin-right: -6rem !important;
  }
  .nv-evox-root .me-md-auto {
    margin-right: auto !important;
  }
  .nv-evox-root .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .nv-evox-root .mb-md-1 {
    margin-bottom: 0.5rem !important;
  }
  .nv-evox-root .mb-md-2 {
    margin-bottom: 1rem !important;
  }
  .nv-evox-root .mb-md-3 {
    margin-bottom: 2rem !important;
  }
  .nv-evox-root .mb-md-4 {
    margin-bottom: 3rem !important;
  }
  .nv-evox-root .mb-md-5 {
    margin-bottom: 6rem !important;
  }
  .nv-evox-root .mb-md--1 {
    margin-bottom: -0.5rem !important;
  }
  .nv-evox-root .mb-md--2 {
    margin-bottom: -1rem !important;
  }
  .nv-evox-root .mb-md--3 {
    margin-bottom: -2rem !important;
  }
  .nv-evox-root .mb-md--4 {
    margin-bottom: -3rem !important;
  }
  .nv-evox-root .mb-md--5 {
    margin-bottom: -6rem !important;
  }
  .nv-evox-root .mb-md-auto {
    margin-bottom: auto !important;
  }
  .nv-evox-root .ms-md-0 {
    margin-left: 0 !important;
  }
  .nv-evox-root .ms-md-1 {
    margin-left: 0.5rem !important;
  }
  .nv-evox-root .ms-md-2 {
    margin-left: 1rem !important;
  }
  .nv-evox-root .ms-md-3 {
    margin-left: 2rem !important;
  }
  .nv-evox-root .ms-md-4 {
    margin-left: 3rem !important;
  }
  .nv-evox-root .ms-md-5 {
    margin-left: 6rem !important;
  }
  .nv-evox-root .ms-md--1 {
    margin-left: -0.5rem !important;
  }
  .nv-evox-root .ms-md--2 {
    margin-left: -1rem !important;
  }
  .nv-evox-root .ms-md--3 {
    margin-left: -2rem !important;
  }
  .nv-evox-root .ms-md--4 {
    margin-left: -3rem !important;
  }
  .nv-evox-root .ms-md--5 {
    margin-left: -6rem !important;
  }
  .nv-evox-root .ms-md-auto {
    margin-left: auto !important;
  }
  .nv-evox-root .p-md-0 {
    padding: 0 !important;
  }
  .nv-evox-root .p-md-1 {
    padding: 0.5rem !important;
  }
  .nv-evox-root .p-md-2 {
    padding: 1rem !important;
  }
  .nv-evox-root .p-md-3 {
    padding: 2rem !important;
  }
  .nv-evox-root .p-md-4 {
    padding: 3rem !important;
  }
  .nv-evox-root .p-md-5 {
    padding: 6rem !important;
  }
  .nv-evox-root .p-md--1 {
    padding: -0.5rem !important;
  }
  .nv-evox-root .p-md--2 {
    padding: -1rem !important;
  }
  .nv-evox-root .p-md--3 {
    padding: -2rem !important;
  }
  .nv-evox-root .p-md--4 {
    padding: -3rem !important;
  }
  .nv-evox-root .p-md--5 {
    padding: -6rem !important;
  }
  .nv-evox-root .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .nv-evox-root .px-md-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .nv-evox-root .px-md-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .nv-evox-root .px-md-3 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .nv-evox-root .px-md-4 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .nv-evox-root .px-md-5 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .nv-evox-root .px-md--1 {
    padding-right: -0.5rem !important;
    padding-left: -0.5rem !important;
  }
  .nv-evox-root .px-md--2 {
    padding-right: -1rem !important;
    padding-left: -1rem !important;
  }
  .nv-evox-root .px-md--3 {
    padding-right: -2rem !important;
    padding-left: -2rem !important;
  }
  .nv-evox-root .px-md--4 {
    padding-right: -3rem !important;
    padding-left: -3rem !important;
  }
  .nv-evox-root .px-md--5 {
    padding-right: -6rem !important;
    padding-left: -6rem !important;
  }
  .nv-evox-root .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .nv-evox-root .py-md-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .nv-evox-root .py-md-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .nv-evox-root .py-md-3 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .nv-evox-root .py-md-4 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .nv-evox-root .py-md-5 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .nv-evox-root .py-md--1 {
    padding-top: -0.5rem !important;
    padding-bottom: -0.5rem !important;
  }
  .nv-evox-root .py-md--2 {
    padding-top: -1rem !important;
    padding-bottom: -1rem !important;
  }
  .nv-evox-root .py-md--3 {
    padding-top: -2rem !important;
    padding-bottom: -2rem !important;
  }
  .nv-evox-root .py-md--4 {
    padding-top: -3rem !important;
    padding-bottom: -3rem !important;
  }
  .nv-evox-root .py-md--5 {
    padding-top: -6rem !important;
    padding-bottom: -6rem !important;
  }
  .nv-evox-root .pt-md-0 {
    padding-top: 0 !important;
  }
  .nv-evox-root .pt-md-1 {
    padding-top: 0.5rem !important;
  }
  .nv-evox-root .pt-md-2 {
    padding-top: 1rem !important;
  }
  .nv-evox-root .pt-md-3 {
    padding-top: 2rem !important;
  }
  .nv-evox-root .pt-md-4 {
    padding-top: 3rem !important;
  }
  .nv-evox-root .pt-md-5 {
    padding-top: 6rem !important;
  }
  .nv-evox-root .pt-md--1 {
    padding-top: -0.5rem !important;
  }
  .nv-evox-root .pt-md--2 {
    padding-top: -1rem !important;
  }
  .nv-evox-root .pt-md--3 {
    padding-top: -2rem !important;
  }
  .nv-evox-root .pt-md--4 {
    padding-top: -3rem !important;
  }
  .nv-evox-root .pt-md--5 {
    padding-top: -6rem !important;
  }
  .nv-evox-root .pe-md-0 {
    padding-right: 0 !important;
  }
  .nv-evox-root .pe-md-1 {
    padding-right: 0.5rem !important;
  }
  .nv-evox-root .pe-md-2 {
    padding-right: 1rem !important;
  }
  .nv-evox-root .pe-md-3 {
    padding-right: 2rem !important;
  }
  .nv-evox-root .pe-md-4 {
    padding-right: 3rem !important;
  }
  .nv-evox-root .pe-md-5 {
    padding-right: 6rem !important;
  }
  .nv-evox-root .pe-md--1 {
    padding-right: -0.5rem !important;
  }
  .nv-evox-root .pe-md--2 {
    padding-right: -1rem !important;
  }
  .nv-evox-root .pe-md--3 {
    padding-right: -2rem !important;
  }
  .nv-evox-root .pe-md--4 {
    padding-right: -3rem !important;
  }
  .nv-evox-root .pe-md--5 {
    padding-right: -6rem !important;
  }
  .nv-evox-root .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .nv-evox-root .pb-md-1 {
    padding-bottom: 0.5rem !important;
  }
  .nv-evox-root .pb-md-2 {
    padding-bottom: 1rem !important;
  }
  .nv-evox-root .pb-md-3 {
    padding-bottom: 2rem !important;
  }
  .nv-evox-root .pb-md-4 {
    padding-bottom: 3rem !important;
  }
  .nv-evox-root .pb-md-5 {
    padding-bottom: 6rem !important;
  }
  .nv-evox-root .pb-md--1 {
    padding-bottom: -0.5rem !important;
  }
  .nv-evox-root .pb-md--2 {
    padding-bottom: -1rem !important;
  }
  .nv-evox-root .pb-md--3 {
    padding-bottom: -2rem !important;
  }
  .nv-evox-root .pb-md--4 {
    padding-bottom: -3rem !important;
  }
  .nv-evox-root .pb-md--5 {
    padding-bottom: -6rem !important;
  }
  .nv-evox-root .ps-md-0 {
    padding-left: 0 !important;
  }
  .nv-evox-root .ps-md-1 {
    padding-left: 0.5rem !important;
  }
  .nv-evox-root .ps-md-2 {
    padding-left: 1rem !important;
  }
  .nv-evox-root .ps-md-3 {
    padding-left: 2rem !important;
  }
  .nv-evox-root .ps-md-4 {
    padding-left: 3rem !important;
  }
  .nv-evox-root .ps-md-5 {
    padding-left: 6rem !important;
  }
  .nv-evox-root .ps-md--1 {
    padding-left: -0.5rem !important;
  }
  .nv-evox-root .ps-md--2 {
    padding-left: -1rem !important;
  }
  .nv-evox-root .ps-md--3 {
    padding-left: -2rem !important;
  }
  .nv-evox-root .ps-md--4 {
    padding-left: -3rem !important;
  }
  .nv-evox-root .ps-md--5 {
    padding-left: -6rem !important;
  }
  .nv-evox-root .gap-md-0 {
    gap: 0 !important;
  }
  .nv-evox-root .gap-md-1 {
    gap: 0.5rem !important;
  }
  .nv-evox-root .gap-md-2 {
    gap: 1rem !important;
  }
  .nv-evox-root .gap-md-3 {
    gap: 2rem !important;
  }
  .nv-evox-root .gap-md-4 {
    gap: 3rem !important;
  }
  .nv-evox-root .gap-md-5 {
    gap: 6rem !important;
  }
  .nv-evox-root .gap-md--1 {
    gap: -0.5rem !important;
  }
  .nv-evox-root .gap-md--2 {
    gap: -1rem !important;
  }
  .nv-evox-root .gap-md--3 {
    gap: -2rem !important;
  }
  .nv-evox-root .gap-md--4 {
    gap: -3rem !important;
  }
  .nv-evox-root .gap-md--5 {
    gap: -6rem !important;
  }
  .nv-evox-root .text-md-start {
    text-align: left !important;
  }
  .nv-evox-root .text-md-end {
    text-align: right !important;
  }
  .nv-evox-root .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1024px) {
  .nv-evox-root .float-lg-start {
    float: left !important;
  }
  .nv-evox-root .float-lg-end {
    float: right !important;
  }
  .nv-evox-root .float-lg-none {
    float: none !important;
  }
  .nv-evox-root .d-lg-inline {
    display: inline !important;
  }
  .nv-evox-root .d-lg-inline-block {
    display: inline-block !important;
  }
  .nv-evox-root .d-lg-block {
    display: block !important;
  }
  .nv-evox-root .d-lg-grid {
    display: grid !important;
  }
  .nv-evox-root .d-lg-table {
    display: table !important;
  }
  .nv-evox-root .d-lg-table-row {
    display: table-row !important;
  }
  .nv-evox-root .d-lg-table-cell {
    display: table-cell !important;
  }
  .nv-evox-root .d-lg-flex {
    display: flex !important;
  }
  .nv-evox-root .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .nv-evox-root .d-lg-none {
    display: none !important;
  }
  .nv-evox-root .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .nv-evox-root .flex-lg-row {
    flex-direction: row !important;
  }
  .nv-evox-root .flex-lg-column {
    flex-direction: column !important;
  }
  .nv-evox-root .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .nv-evox-root .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .nv-evox-root .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .nv-evox-root .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .nv-evox-root .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .nv-evox-root .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .nv-evox-root .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .nv-evox-root .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .nv-evox-root .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .nv-evox-root .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .nv-evox-root .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .nv-evox-root .justify-content-lg-center {
    justify-content: center !important;
  }
  .nv-evox-root .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .nv-evox-root .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .nv-evox-root .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .nv-evox-root .align-items-lg-start {
    align-items: flex-start !important;
  }
  .nv-evox-root .align-items-lg-end {
    align-items: flex-end !important;
  }
  .nv-evox-root .align-items-lg-center {
    align-items: center !important;
  }
  .nv-evox-root .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .nv-evox-root .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .nv-evox-root .align-content-lg-start {
    align-content: flex-start !important;
  }
  .nv-evox-root .align-content-lg-end {
    align-content: flex-end !important;
  }
  .nv-evox-root .align-content-lg-center {
    align-content: center !important;
  }
  .nv-evox-root .align-content-lg-between {
    align-content: space-between !important;
  }
  .nv-evox-root .align-content-lg-around {
    align-content: space-around !important;
  }
  .nv-evox-root .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .nv-evox-root .align-self-lg-auto {
    align-self: auto !important;
  }
  .nv-evox-root .align-self-lg-start {
    align-self: flex-start !important;
  }
  .nv-evox-root .align-self-lg-end {
    align-self: flex-end !important;
  }
  .nv-evox-root .align-self-lg-center {
    align-self: center !important;
  }
  .nv-evox-root .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .nv-evox-root .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .nv-evox-root .order-lg-first {
    order: -1 !important;
  }
  .nv-evox-root .order-lg-0 {
    order: 0 !important;
  }
  .nv-evox-root .order-lg-1 {
    order: 1 !important;
  }
  .nv-evox-root .order-lg-2 {
    order: 2 !important;
  }
  .nv-evox-root .order-lg-3 {
    order: 3 !important;
  }
  .nv-evox-root .order-lg-4 {
    order: 4 !important;
  }
  .nv-evox-root .order-lg-5 {
    order: 5 !important;
  }
  .nv-evox-root .order-lg-last {
    order: 6 !important;
  }
  .nv-evox-root .m-lg-0 {
    margin: 0 !important;
  }
  .nv-evox-root .m-lg-1 {
    margin: 0.5rem !important;
  }
  .nv-evox-root .m-lg-2 {
    margin: 1rem !important;
  }
  .nv-evox-root .m-lg-3 {
    margin: 2rem !important;
  }
  .nv-evox-root .m-lg-4 {
    margin: 3rem !important;
  }
  .nv-evox-root .m-lg-5 {
    margin: 6rem !important;
  }
  .nv-evox-root .m-lg--1 {
    margin: -0.5rem !important;
  }
  .nv-evox-root .m-lg--2 {
    margin: -1rem !important;
  }
  .nv-evox-root .m-lg--3 {
    margin: -2rem !important;
  }
  .nv-evox-root .m-lg--4 {
    margin: -3rem !important;
  }
  .nv-evox-root .m-lg--5 {
    margin: -6rem !important;
  }
  .nv-evox-root .m-lg-auto {
    margin: auto !important;
  }
  .nv-evox-root .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .nv-evox-root .mx-lg-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .nv-evox-root .mx-lg-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .nv-evox-root .mx-lg-3 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .nv-evox-root .mx-lg-4 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .nv-evox-root .mx-lg-5 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .nv-evox-root .mx-lg--1 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .nv-evox-root .mx-lg--2 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .nv-evox-root .mx-lg--3 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .nv-evox-root .mx-lg--4 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .nv-evox-root .mx-lg--5 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .nv-evox-root .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .nv-evox-root .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .nv-evox-root .my-lg-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .nv-evox-root .my-lg-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .nv-evox-root .my-lg-3 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .nv-evox-root .my-lg-4 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .nv-evox-root .my-lg-5 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .nv-evox-root .my-lg--1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .nv-evox-root .my-lg--2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .nv-evox-root .my-lg--3 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .nv-evox-root .my-lg--4 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .nv-evox-root .my-lg--5 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .nv-evox-root .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .nv-evox-root .mt-lg-0 {
    margin-top: 0 !important;
  }
  .nv-evox-root .mt-lg-1 {
    margin-top: 0.5rem !important;
  }
  .nv-evox-root .mt-lg-2 {
    margin-top: 1rem !important;
  }
  .nv-evox-root .mt-lg-3 {
    margin-top: 2rem !important;
  }
  .nv-evox-root .mt-lg-4 {
    margin-top: 3rem !important;
  }
  .nv-evox-root .mt-lg-5 {
    margin-top: 6rem !important;
  }
  .nv-evox-root .mt-lg--1 {
    margin-top: -0.5rem !important;
  }
  .nv-evox-root .mt-lg--2 {
    margin-top: -1rem !important;
  }
  .nv-evox-root .mt-lg--3 {
    margin-top: -2rem !important;
  }
  .nv-evox-root .mt-lg--4 {
    margin-top: -3rem !important;
  }
  .nv-evox-root .mt-lg--5 {
    margin-top: -6rem !important;
  }
  .nv-evox-root .mt-lg-auto {
    margin-top: auto !important;
  }
  .nv-evox-root .me-lg-0 {
    margin-right: 0 !important;
  }
  .nv-evox-root .me-lg-1 {
    margin-right: 0.5rem !important;
  }
  .nv-evox-root .me-lg-2 {
    margin-right: 1rem !important;
  }
  .nv-evox-root .me-lg-3 {
    margin-right: 2rem !important;
  }
  .nv-evox-root .me-lg-4 {
    margin-right: 3rem !important;
  }
  .nv-evox-root .me-lg-5 {
    margin-right: 6rem !important;
  }
  .nv-evox-root .me-lg--1 {
    margin-right: -0.5rem !important;
  }
  .nv-evox-root .me-lg--2 {
    margin-right: -1rem !important;
  }
  .nv-evox-root .me-lg--3 {
    margin-right: -2rem !important;
  }
  .nv-evox-root .me-lg--4 {
    margin-right: -3rem !important;
  }
  .nv-evox-root .me-lg--5 {
    margin-right: -6rem !important;
  }
  .nv-evox-root .me-lg-auto {
    margin-right: auto !important;
  }
  .nv-evox-root .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .nv-evox-root .mb-lg-1 {
    margin-bottom: 0.5rem !important;
  }
  .nv-evox-root .mb-lg-2 {
    margin-bottom: 1rem !important;
  }
  .nv-evox-root .mb-lg-3 {
    margin-bottom: 2rem !important;
  }
  .nv-evox-root .mb-lg-4 {
    margin-bottom: 3rem !important;
  }
  .nv-evox-root .mb-lg-5 {
    margin-bottom: 6rem !important;
  }
  .nv-evox-root .mb-lg--1 {
    margin-bottom: -0.5rem !important;
  }
  .nv-evox-root .mb-lg--2 {
    margin-bottom: -1rem !important;
  }
  .nv-evox-root .mb-lg--3 {
    margin-bottom: -2rem !important;
  }
  .nv-evox-root .mb-lg--4 {
    margin-bottom: -3rem !important;
  }
  .nv-evox-root .mb-lg--5 {
    margin-bottom: -6rem !important;
  }
  .nv-evox-root .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .nv-evox-root .ms-lg-0 {
    margin-left: 0 !important;
  }
  .nv-evox-root .ms-lg-1 {
    margin-left: 0.5rem !important;
  }
  .nv-evox-root .ms-lg-2 {
    margin-left: 1rem !important;
  }
  .nv-evox-root .ms-lg-3 {
    margin-left: 2rem !important;
  }
  .nv-evox-root .ms-lg-4 {
    margin-left: 3rem !important;
  }
  .nv-evox-root .ms-lg-5 {
    margin-left: 6rem !important;
  }
  .nv-evox-root .ms-lg--1 {
    margin-left: -0.5rem !important;
  }
  .nv-evox-root .ms-lg--2 {
    margin-left: -1rem !important;
  }
  .nv-evox-root .ms-lg--3 {
    margin-left: -2rem !important;
  }
  .nv-evox-root .ms-lg--4 {
    margin-left: -3rem !important;
  }
  .nv-evox-root .ms-lg--5 {
    margin-left: -6rem !important;
  }
  .nv-evox-root .ms-lg-auto {
    margin-left: auto !important;
  }
  .nv-evox-root .p-lg-0 {
    padding: 0 !important;
  }
  .nv-evox-root .p-lg-1 {
    padding: 0.5rem !important;
  }
  .nv-evox-root .p-lg-2 {
    padding: 1rem !important;
  }
  .nv-evox-root .p-lg-3 {
    padding: 2rem !important;
  }
  .nv-evox-root .p-lg-4 {
    padding: 3rem !important;
  }
  .nv-evox-root .p-lg-5 {
    padding: 6rem !important;
  }
  .nv-evox-root .p-lg--1 {
    padding: -0.5rem !important;
  }
  .nv-evox-root .p-lg--2 {
    padding: -1rem !important;
  }
  .nv-evox-root .p-lg--3 {
    padding: -2rem !important;
  }
  .nv-evox-root .p-lg--4 {
    padding: -3rem !important;
  }
  .nv-evox-root .p-lg--5 {
    padding: -6rem !important;
  }
  .nv-evox-root .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .nv-evox-root .px-lg-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .nv-evox-root .px-lg-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .nv-evox-root .px-lg-3 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .nv-evox-root .px-lg-4 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .nv-evox-root .px-lg-5 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .nv-evox-root .px-lg--1 {
    padding-right: -0.5rem !important;
    padding-left: -0.5rem !important;
  }
  .nv-evox-root .px-lg--2 {
    padding-right: -1rem !important;
    padding-left: -1rem !important;
  }
  .nv-evox-root .px-lg--3 {
    padding-right: -2rem !important;
    padding-left: -2rem !important;
  }
  .nv-evox-root .px-lg--4 {
    padding-right: -3rem !important;
    padding-left: -3rem !important;
  }
  .nv-evox-root .px-lg--5 {
    padding-right: -6rem !important;
    padding-left: -6rem !important;
  }
  .nv-evox-root .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .nv-evox-root .py-lg-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .nv-evox-root .py-lg-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .nv-evox-root .py-lg-3 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .nv-evox-root .py-lg-4 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .nv-evox-root .py-lg-5 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .nv-evox-root .py-lg--1 {
    padding-top: -0.5rem !important;
    padding-bottom: -0.5rem !important;
  }
  .nv-evox-root .py-lg--2 {
    padding-top: -1rem !important;
    padding-bottom: -1rem !important;
  }
  .nv-evox-root .py-lg--3 {
    padding-top: -2rem !important;
    padding-bottom: -2rem !important;
  }
  .nv-evox-root .py-lg--4 {
    padding-top: -3rem !important;
    padding-bottom: -3rem !important;
  }
  .nv-evox-root .py-lg--5 {
    padding-top: -6rem !important;
    padding-bottom: -6rem !important;
  }
  .nv-evox-root .pt-lg-0 {
    padding-top: 0 !important;
  }
  .nv-evox-root .pt-lg-1 {
    padding-top: 0.5rem !important;
  }
  .nv-evox-root .pt-lg-2 {
    padding-top: 1rem !important;
  }
  .nv-evox-root .pt-lg-3 {
    padding-top: 2rem !important;
  }
  .nv-evox-root .pt-lg-4 {
    padding-top: 3rem !important;
  }
  .nv-evox-root .pt-lg-5 {
    padding-top: 6rem !important;
  }
  .nv-evox-root .pt-lg--1 {
    padding-top: -0.5rem !important;
  }
  .nv-evox-root .pt-lg--2 {
    padding-top: -1rem !important;
  }
  .nv-evox-root .pt-lg--3 {
    padding-top: -2rem !important;
  }
  .nv-evox-root .pt-lg--4 {
    padding-top: -3rem !important;
  }
  .nv-evox-root .pt-lg--5 {
    padding-top: -6rem !important;
  }
  .nv-evox-root .pe-lg-0 {
    padding-right: 0 !important;
  }
  .nv-evox-root .pe-lg-1 {
    padding-right: 0.5rem !important;
  }
  .nv-evox-root .pe-lg-2 {
    padding-right: 1rem !important;
  }
  .nv-evox-root .pe-lg-3 {
    padding-right: 2rem !important;
  }
  .nv-evox-root .pe-lg-4 {
    padding-right: 3rem !important;
  }
  .nv-evox-root .pe-lg-5 {
    padding-right: 6rem !important;
  }
  .nv-evox-root .pe-lg--1 {
    padding-right: -0.5rem !important;
  }
  .nv-evox-root .pe-lg--2 {
    padding-right: -1rem !important;
  }
  .nv-evox-root .pe-lg--3 {
    padding-right: -2rem !important;
  }
  .nv-evox-root .pe-lg--4 {
    padding-right: -3rem !important;
  }
  .nv-evox-root .pe-lg--5 {
    padding-right: -6rem !important;
  }
  .nv-evox-root .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .nv-evox-root .pb-lg-1 {
    padding-bottom: 0.5rem !important;
  }
  .nv-evox-root .pb-lg-2 {
    padding-bottom: 1rem !important;
  }
  .nv-evox-root .pb-lg-3 {
    padding-bottom: 2rem !important;
  }
  .nv-evox-root .pb-lg-4 {
    padding-bottom: 3rem !important;
  }
  .nv-evox-root .pb-lg-5 {
    padding-bottom: 6rem !important;
  }
  .nv-evox-root .pb-lg--1 {
    padding-bottom: -0.5rem !important;
  }
  .nv-evox-root .pb-lg--2 {
    padding-bottom: -1rem !important;
  }
  .nv-evox-root .pb-lg--3 {
    padding-bottom: -2rem !important;
  }
  .nv-evox-root .pb-lg--4 {
    padding-bottom: -3rem !important;
  }
  .nv-evox-root .pb-lg--5 {
    padding-bottom: -6rem !important;
  }
  .nv-evox-root .ps-lg-0 {
    padding-left: 0 !important;
  }
  .nv-evox-root .ps-lg-1 {
    padding-left: 0.5rem !important;
  }
  .nv-evox-root .ps-lg-2 {
    padding-left: 1rem !important;
  }
  .nv-evox-root .ps-lg-3 {
    padding-left: 2rem !important;
  }
  .nv-evox-root .ps-lg-4 {
    padding-left: 3rem !important;
  }
  .nv-evox-root .ps-lg-5 {
    padding-left: 6rem !important;
  }
  .nv-evox-root .ps-lg--1 {
    padding-left: -0.5rem !important;
  }
  .nv-evox-root .ps-lg--2 {
    padding-left: -1rem !important;
  }
  .nv-evox-root .ps-lg--3 {
    padding-left: -2rem !important;
  }
  .nv-evox-root .ps-lg--4 {
    padding-left: -3rem !important;
  }
  .nv-evox-root .ps-lg--5 {
    padding-left: -6rem !important;
  }
  .nv-evox-root .gap-lg-0 {
    gap: 0 !important;
  }
  .nv-evox-root .gap-lg-1 {
    gap: 0.5rem !important;
  }
  .nv-evox-root .gap-lg-2 {
    gap: 1rem !important;
  }
  .nv-evox-root .gap-lg-3 {
    gap: 2rem !important;
  }
  .nv-evox-root .gap-lg-4 {
    gap: 3rem !important;
  }
  .nv-evox-root .gap-lg-5 {
    gap: 6rem !important;
  }
  .nv-evox-root .gap-lg--1 {
    gap: -0.5rem !important;
  }
  .nv-evox-root .gap-lg--2 {
    gap: -1rem !important;
  }
  .nv-evox-root .gap-lg--3 {
    gap: -2rem !important;
  }
  .nv-evox-root .gap-lg--4 {
    gap: -3rem !important;
  }
  .nv-evox-root .gap-lg--5 {
    gap: -6rem !important;
  }
  .nv-evox-root .text-lg-start {
    text-align: left !important;
  }
  .nv-evox-root .text-lg-end {
    text-align: right !important;
  }
  .nv-evox-root .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1280px) {
  .nv-evox-root .float-xl-start {
    float: left !important;
  }
  .nv-evox-root .float-xl-end {
    float: right !important;
  }
  .nv-evox-root .float-xl-none {
    float: none !important;
  }
  .nv-evox-root .d-xl-inline {
    display: inline !important;
  }
  .nv-evox-root .d-xl-inline-block {
    display: inline-block !important;
  }
  .nv-evox-root .d-xl-block {
    display: block !important;
  }
  .nv-evox-root .d-xl-grid {
    display: grid !important;
  }
  .nv-evox-root .d-xl-table {
    display: table !important;
  }
  .nv-evox-root .d-xl-table-row {
    display: table-row !important;
  }
  .nv-evox-root .d-xl-table-cell {
    display: table-cell !important;
  }
  .nv-evox-root .d-xl-flex {
    display: flex !important;
  }
  .nv-evox-root .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .nv-evox-root .d-xl-none {
    display: none !important;
  }
  .nv-evox-root .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .nv-evox-root .flex-xl-row {
    flex-direction: row !important;
  }
  .nv-evox-root .flex-xl-column {
    flex-direction: column !important;
  }
  .nv-evox-root .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .nv-evox-root .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .nv-evox-root .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .nv-evox-root .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .nv-evox-root .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .nv-evox-root .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .nv-evox-root .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .nv-evox-root .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .nv-evox-root .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .nv-evox-root .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .nv-evox-root .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .nv-evox-root .justify-content-xl-center {
    justify-content: center !important;
  }
  .nv-evox-root .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .nv-evox-root .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .nv-evox-root .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .nv-evox-root .align-items-xl-start {
    align-items: flex-start !important;
  }
  .nv-evox-root .align-items-xl-end {
    align-items: flex-end !important;
  }
  .nv-evox-root .align-items-xl-center {
    align-items: center !important;
  }
  .nv-evox-root .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .nv-evox-root .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .nv-evox-root .align-content-xl-start {
    align-content: flex-start !important;
  }
  .nv-evox-root .align-content-xl-end {
    align-content: flex-end !important;
  }
  .nv-evox-root .align-content-xl-center {
    align-content: center !important;
  }
  .nv-evox-root .align-content-xl-between {
    align-content: space-between !important;
  }
  .nv-evox-root .align-content-xl-around {
    align-content: space-around !important;
  }
  .nv-evox-root .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .nv-evox-root .align-self-xl-auto {
    align-self: auto !important;
  }
  .nv-evox-root .align-self-xl-start {
    align-self: flex-start !important;
  }
  .nv-evox-root .align-self-xl-end {
    align-self: flex-end !important;
  }
  .nv-evox-root .align-self-xl-center {
    align-self: center !important;
  }
  .nv-evox-root .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .nv-evox-root .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .nv-evox-root .order-xl-first {
    order: -1 !important;
  }
  .nv-evox-root .order-xl-0 {
    order: 0 !important;
  }
  .nv-evox-root .order-xl-1 {
    order: 1 !important;
  }
  .nv-evox-root .order-xl-2 {
    order: 2 !important;
  }
  .nv-evox-root .order-xl-3 {
    order: 3 !important;
  }
  .nv-evox-root .order-xl-4 {
    order: 4 !important;
  }
  .nv-evox-root .order-xl-5 {
    order: 5 !important;
  }
  .nv-evox-root .order-xl-last {
    order: 6 !important;
  }
  .nv-evox-root .m-xl-0 {
    margin: 0 !important;
  }
  .nv-evox-root .m-xl-1 {
    margin: 0.5rem !important;
  }
  .nv-evox-root .m-xl-2 {
    margin: 1rem !important;
  }
  .nv-evox-root .m-xl-3 {
    margin: 2rem !important;
  }
  .nv-evox-root .m-xl-4 {
    margin: 3rem !important;
  }
  .nv-evox-root .m-xl-5 {
    margin: 6rem !important;
  }
  .nv-evox-root .m-xl--1 {
    margin: -0.5rem !important;
  }
  .nv-evox-root .m-xl--2 {
    margin: -1rem !important;
  }
  .nv-evox-root .m-xl--3 {
    margin: -2rem !important;
  }
  .nv-evox-root .m-xl--4 {
    margin: -3rem !important;
  }
  .nv-evox-root .m-xl--5 {
    margin: -6rem !important;
  }
  .nv-evox-root .m-xl-auto {
    margin: auto !important;
  }
  .nv-evox-root .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .nv-evox-root .mx-xl-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .nv-evox-root .mx-xl-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .nv-evox-root .mx-xl-3 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .nv-evox-root .mx-xl-4 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .nv-evox-root .mx-xl-5 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .nv-evox-root .mx-xl--1 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .nv-evox-root .mx-xl--2 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .nv-evox-root .mx-xl--3 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .nv-evox-root .mx-xl--4 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .nv-evox-root .mx-xl--5 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .nv-evox-root .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .nv-evox-root .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .nv-evox-root .my-xl-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .nv-evox-root .my-xl-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .nv-evox-root .my-xl-3 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .nv-evox-root .my-xl-4 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .nv-evox-root .my-xl-5 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .nv-evox-root .my-xl--1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .nv-evox-root .my-xl--2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .nv-evox-root .my-xl--3 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .nv-evox-root .my-xl--4 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .nv-evox-root .my-xl--5 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .nv-evox-root .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .nv-evox-root .mt-xl-0 {
    margin-top: 0 !important;
  }
  .nv-evox-root .mt-xl-1 {
    margin-top: 0.5rem !important;
  }
  .nv-evox-root .mt-xl-2 {
    margin-top: 1rem !important;
  }
  .nv-evox-root .mt-xl-3 {
    margin-top: 2rem !important;
  }
  .nv-evox-root .mt-xl-4 {
    margin-top: 3rem !important;
  }
  .nv-evox-root .mt-xl-5 {
    margin-top: 6rem !important;
  }
  .nv-evox-root .mt-xl--1 {
    margin-top: -0.5rem !important;
  }
  .nv-evox-root .mt-xl--2 {
    margin-top: -1rem !important;
  }
  .nv-evox-root .mt-xl--3 {
    margin-top: -2rem !important;
  }
  .nv-evox-root .mt-xl--4 {
    margin-top: -3rem !important;
  }
  .nv-evox-root .mt-xl--5 {
    margin-top: -6rem !important;
  }
  .nv-evox-root .mt-xl-auto {
    margin-top: auto !important;
  }
  .nv-evox-root .me-xl-0 {
    margin-right: 0 !important;
  }
  .nv-evox-root .me-xl-1 {
    margin-right: 0.5rem !important;
  }
  .nv-evox-root .me-xl-2 {
    margin-right: 1rem !important;
  }
  .nv-evox-root .me-xl-3 {
    margin-right: 2rem !important;
  }
  .nv-evox-root .me-xl-4 {
    margin-right: 3rem !important;
  }
  .nv-evox-root .me-xl-5 {
    margin-right: 6rem !important;
  }
  .nv-evox-root .me-xl--1 {
    margin-right: -0.5rem !important;
  }
  .nv-evox-root .me-xl--2 {
    margin-right: -1rem !important;
  }
  .nv-evox-root .me-xl--3 {
    margin-right: -2rem !important;
  }
  .nv-evox-root .me-xl--4 {
    margin-right: -3rem !important;
  }
  .nv-evox-root .me-xl--5 {
    margin-right: -6rem !important;
  }
  .nv-evox-root .me-xl-auto {
    margin-right: auto !important;
  }
  .nv-evox-root .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .nv-evox-root .mb-xl-1 {
    margin-bottom: 0.5rem !important;
  }
  .nv-evox-root .mb-xl-2 {
    margin-bottom: 1rem !important;
  }
  .nv-evox-root .mb-xl-3 {
    margin-bottom: 2rem !important;
  }
  .nv-evox-root .mb-xl-4 {
    margin-bottom: 3rem !important;
  }
  .nv-evox-root .mb-xl-5 {
    margin-bottom: 6rem !important;
  }
  .nv-evox-root .mb-xl--1 {
    margin-bottom: -0.5rem !important;
  }
  .nv-evox-root .mb-xl--2 {
    margin-bottom: -1rem !important;
  }
  .nv-evox-root .mb-xl--3 {
    margin-bottom: -2rem !important;
  }
  .nv-evox-root .mb-xl--4 {
    margin-bottom: -3rem !important;
  }
  .nv-evox-root .mb-xl--5 {
    margin-bottom: -6rem !important;
  }
  .nv-evox-root .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .nv-evox-root .ms-xl-0 {
    margin-left: 0 !important;
  }
  .nv-evox-root .ms-xl-1 {
    margin-left: 0.5rem !important;
  }
  .nv-evox-root .ms-xl-2 {
    margin-left: 1rem !important;
  }
  .nv-evox-root .ms-xl-3 {
    margin-left: 2rem !important;
  }
  .nv-evox-root .ms-xl-4 {
    margin-left: 3rem !important;
  }
  .nv-evox-root .ms-xl-5 {
    margin-left: 6rem !important;
  }
  .nv-evox-root .ms-xl--1 {
    margin-left: -0.5rem !important;
  }
  .nv-evox-root .ms-xl--2 {
    margin-left: -1rem !important;
  }
  .nv-evox-root .ms-xl--3 {
    margin-left: -2rem !important;
  }
  .nv-evox-root .ms-xl--4 {
    margin-left: -3rem !important;
  }
  .nv-evox-root .ms-xl--5 {
    margin-left: -6rem !important;
  }
  .nv-evox-root .ms-xl-auto {
    margin-left: auto !important;
  }
  .nv-evox-root .p-xl-0 {
    padding: 0 !important;
  }
  .nv-evox-root .p-xl-1 {
    padding: 0.5rem !important;
  }
  .nv-evox-root .p-xl-2 {
    padding: 1rem !important;
  }
  .nv-evox-root .p-xl-3 {
    padding: 2rem !important;
  }
  .nv-evox-root .p-xl-4 {
    padding: 3rem !important;
  }
  .nv-evox-root .p-xl-5 {
    padding: 6rem !important;
  }
  .nv-evox-root .p-xl--1 {
    padding: -0.5rem !important;
  }
  .nv-evox-root .p-xl--2 {
    padding: -1rem !important;
  }
  .nv-evox-root .p-xl--3 {
    padding: -2rem !important;
  }
  .nv-evox-root .p-xl--4 {
    padding: -3rem !important;
  }
  .nv-evox-root .p-xl--5 {
    padding: -6rem !important;
  }
  .nv-evox-root .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .nv-evox-root .px-xl-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .nv-evox-root .px-xl-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .nv-evox-root .px-xl-3 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .nv-evox-root .px-xl-4 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .nv-evox-root .px-xl-5 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .nv-evox-root .px-xl--1 {
    padding-right: -0.5rem !important;
    padding-left: -0.5rem !important;
  }
  .nv-evox-root .px-xl--2 {
    padding-right: -1rem !important;
    padding-left: -1rem !important;
  }
  .nv-evox-root .px-xl--3 {
    padding-right: -2rem !important;
    padding-left: -2rem !important;
  }
  .nv-evox-root .px-xl--4 {
    padding-right: -3rem !important;
    padding-left: -3rem !important;
  }
  .nv-evox-root .px-xl--5 {
    padding-right: -6rem !important;
    padding-left: -6rem !important;
  }
  .nv-evox-root .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .nv-evox-root .py-xl-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .nv-evox-root .py-xl-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .nv-evox-root .py-xl-3 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .nv-evox-root .py-xl-4 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .nv-evox-root .py-xl-5 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .nv-evox-root .py-xl--1 {
    padding-top: -0.5rem !important;
    padding-bottom: -0.5rem !important;
  }
  .nv-evox-root .py-xl--2 {
    padding-top: -1rem !important;
    padding-bottom: -1rem !important;
  }
  .nv-evox-root .py-xl--3 {
    padding-top: -2rem !important;
    padding-bottom: -2rem !important;
  }
  .nv-evox-root .py-xl--4 {
    padding-top: -3rem !important;
    padding-bottom: -3rem !important;
  }
  .nv-evox-root .py-xl--5 {
    padding-top: -6rem !important;
    padding-bottom: -6rem !important;
  }
  .nv-evox-root .pt-xl-0 {
    padding-top: 0 !important;
  }
  .nv-evox-root .pt-xl-1 {
    padding-top: 0.5rem !important;
  }
  .nv-evox-root .pt-xl-2 {
    padding-top: 1rem !important;
  }
  .nv-evox-root .pt-xl-3 {
    padding-top: 2rem !important;
  }
  .nv-evox-root .pt-xl-4 {
    padding-top: 3rem !important;
  }
  .nv-evox-root .pt-xl-5 {
    padding-top: 6rem !important;
  }
  .nv-evox-root .pt-xl--1 {
    padding-top: -0.5rem !important;
  }
  .nv-evox-root .pt-xl--2 {
    padding-top: -1rem !important;
  }
  .nv-evox-root .pt-xl--3 {
    padding-top: -2rem !important;
  }
  .nv-evox-root .pt-xl--4 {
    padding-top: -3rem !important;
  }
  .nv-evox-root .pt-xl--5 {
    padding-top: -6rem !important;
  }
  .nv-evox-root .pe-xl-0 {
    padding-right: 0 !important;
  }
  .nv-evox-root .pe-xl-1 {
    padding-right: 0.5rem !important;
  }
  .nv-evox-root .pe-xl-2 {
    padding-right: 1rem !important;
  }
  .nv-evox-root .pe-xl-3 {
    padding-right: 2rem !important;
  }
  .nv-evox-root .pe-xl-4 {
    padding-right: 3rem !important;
  }
  .nv-evox-root .pe-xl-5 {
    padding-right: 6rem !important;
  }
  .nv-evox-root .pe-xl--1 {
    padding-right: -0.5rem !important;
  }
  .nv-evox-root .pe-xl--2 {
    padding-right: -1rem !important;
  }
  .nv-evox-root .pe-xl--3 {
    padding-right: -2rem !important;
  }
  .nv-evox-root .pe-xl--4 {
    padding-right: -3rem !important;
  }
  .nv-evox-root .pe-xl--5 {
    padding-right: -6rem !important;
  }
  .nv-evox-root .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .nv-evox-root .pb-xl-1 {
    padding-bottom: 0.5rem !important;
  }
  .nv-evox-root .pb-xl-2 {
    padding-bottom: 1rem !important;
  }
  .nv-evox-root .pb-xl-3 {
    padding-bottom: 2rem !important;
  }
  .nv-evox-root .pb-xl-4 {
    padding-bottom: 3rem !important;
  }
  .nv-evox-root .pb-xl-5 {
    padding-bottom: 6rem !important;
  }
  .nv-evox-root .pb-xl--1 {
    padding-bottom: -0.5rem !important;
  }
  .nv-evox-root .pb-xl--2 {
    padding-bottom: -1rem !important;
  }
  .nv-evox-root .pb-xl--3 {
    padding-bottom: -2rem !important;
  }
  .nv-evox-root .pb-xl--4 {
    padding-bottom: -3rem !important;
  }
  .nv-evox-root .pb-xl--5 {
    padding-bottom: -6rem !important;
  }
  .nv-evox-root .ps-xl-0 {
    padding-left: 0 !important;
  }
  .nv-evox-root .ps-xl-1 {
    padding-left: 0.5rem !important;
  }
  .nv-evox-root .ps-xl-2 {
    padding-left: 1rem !important;
  }
  .nv-evox-root .ps-xl-3 {
    padding-left: 2rem !important;
  }
  .nv-evox-root .ps-xl-4 {
    padding-left: 3rem !important;
  }
  .nv-evox-root .ps-xl-5 {
    padding-left: 6rem !important;
  }
  .nv-evox-root .ps-xl--1 {
    padding-left: -0.5rem !important;
  }
  .nv-evox-root .ps-xl--2 {
    padding-left: -1rem !important;
  }
  .nv-evox-root .ps-xl--3 {
    padding-left: -2rem !important;
  }
  .nv-evox-root .ps-xl--4 {
    padding-left: -3rem !important;
  }
  .nv-evox-root .ps-xl--5 {
    padding-left: -6rem !important;
  }
  .nv-evox-root .gap-xl-0 {
    gap: 0 !important;
  }
  .nv-evox-root .gap-xl-1 {
    gap: 0.5rem !important;
  }
  .nv-evox-root .gap-xl-2 {
    gap: 1rem !important;
  }
  .nv-evox-root .gap-xl-3 {
    gap: 2rem !important;
  }
  .nv-evox-root .gap-xl-4 {
    gap: 3rem !important;
  }
  .nv-evox-root .gap-xl-5 {
    gap: 6rem !important;
  }
  .nv-evox-root .gap-xl--1 {
    gap: -0.5rem !important;
  }
  .nv-evox-root .gap-xl--2 {
    gap: -1rem !important;
  }
  .nv-evox-root .gap-xl--3 {
    gap: -2rem !important;
  }
  .nv-evox-root .gap-xl--4 {
    gap: -3rem !important;
  }
  .nv-evox-root .gap-xl--5 {
    gap: -6rem !important;
  }
  .nv-evox-root .text-xl-start {
    text-align: left !important;
  }
  .nv-evox-root .text-xl-end {
    text-align: right !important;
  }
  .nv-evox-root .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .nv-evox-root .fs-1 {
    font-size: 2.86875rem !important;
  }
  .nv-evox-root .fs-2 {
    font-size: 2.4975rem !important;
  }
  .nv-evox-root .fs-3 {
    font-size: 1.575rem !important;
  }
}
@media print {
  .nv-evox-root .d-print-inline {
    display: inline !important;
  }
  .nv-evox-root .d-print-inline-block {
    display: inline-block !important;
  }
  .nv-evox-root .d-print-block {
    display: block !important;
  }
  .nv-evox-root .d-print-grid {
    display: grid !important;
  }
  .nv-evox-root .d-print-table {
    display: table !important;
  }
  .nv-evox-root .d-print-table-row {
    display: table-row !important;
  }
  .nv-evox-root .d-print-table-cell {
    display: table-cell !important;
  }
  .nv-evox-root .d-print-flex {
    display: flex !important;
  }
  .nv-evox-root .d-print-inline-flex {
    display: inline-flex !important;
  }
  .nv-evox-root .d-print-none {
    display: none !important;
  }
}
.nv-evox-root .fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .nv-evox-root .fade {
    transition: none;
  }
}
.nv-evox-root .fade:not(.show) {
  opacity: 0;
}
.nv-evox-root .collapse:not(.show) {
  display: none;
}
.nv-evox-root .collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .nv-evox-root .collapsing {
    transition: none;
  }
}
.nv-evox-root .collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .nv-evox-root .collapsing.collapse-horizontal {
    transition: none;
  }
}
.nv-evox-root .nav {
  --nv-nav-link-padding-x: 1rem;
  --nv-nav-link-padding-y: 0.5rem;
  --nv-nav-link-font-weight: ;
  --nv-nav-link-color: var(--nv-link-color);
  --nv-nav-link-hover-color: var(--nv-link-hover-color);
  --nv-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nv-evox-root .nav-link {
  display: block;
  padding: var(--nv-nav-link-padding-y) var(--nv-nav-link-padding-x);
  font-size: var(--nv-nav-link-font-size);
  font-weight: var(--nv-nav-link-font-weight);
  color: var(--nv-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nv-evox-root .nav-link {
    transition: none;
  }
}
.nv-evox-root .nav-link:hover, .nv-evox-root .nav-link:focus {
  color: var(--nv-nav-link-hover-color);
  text-decoration: none;
}
.nv-evox-root .nav-link.disabled {
  color: var(--nv-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}
.nv-evox-root .nav-tabs {
  --nv-nav-tabs-border-width: 1px;
  --nv-nav-tabs-border-color: #dee2e6;
  --nv-nav-tabs-border-radius: 0.375rem;
  --nv-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --nv-nav-tabs-link-active-color: #495057;
  --nv-nav-tabs-link-active-bg: #fff;
  --nv-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--nv-nav-tabs-border-width) solid var(--nv-nav-tabs-border-color);
}
.nv-evox-root .nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--nv-nav-tabs-border-width));
  background: none;
  border: var(--nv-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--nv-nav-tabs-border-radius);
  border-top-right-radius: var(--nv-nav-tabs-border-radius);
}
.nv-evox-root .nav-tabs .nav-link:hover, .nv-evox-root .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--nv-nav-tabs-link-hover-border-color);
}
.nv-evox-root .nav-tabs .nav-link.disabled, .nv-evox-root .nav-tabs .nav-link:disabled {
  color: var(--nv-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nv-evox-root .nav-tabs .nav-link.active,
.nv-evox-root .nav-tabs .nav-item.show .nav-link {
  color: var(--nv-nav-tabs-link-active-color);
  background-color: var(--nv-nav-tabs-link-active-bg);
  border-color: var(--nv-nav-tabs-link-active-border-color);
}
.nv-evox-root .nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--nv-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nv-evox-root .nav-pills {
  --nv-nav-pills-border-radius: 0.375rem;
  --nv-nav-pills-link-active-color: #fff;
  --nv-nav-pills-link-active-bg: #003460;
}
.nv-evox-root .nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--nv-nav-pills-border-radius);
}
.nv-evox-root .nav-pills .nav-link:disabled {
  color: var(--nv-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nv-evox-root .nav-pills .nav-link.active,
.nv-evox-root .nav-pills .show > .nav-link {
  color: var(--nv-nav-pills-link-active-color);
  background-color: var(--nv-nav-pills-link-active-bg);
}
.nv-evox-root .nav-fill > .nav-link,
.nv-evox-root .nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nv-evox-root .nav-justified > .nav-link,
.nv-evox-root .nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.nv-evox-root .nav-fill .nav-item .nav-link,
.nv-evox-root .nav-justified .nav-item .nav-link {
  width: 100%;
}
.nv-evox-root .tab-content > .tab-pane {
  display: none;
}
.nv-evox-root .tab-content > .active {
  display: block;
}
.nv-evox-root .navbar {
  --nv-navbar-padding-x: 0;
  --nv-navbar-padding-y: 0.5625rem;
  --nv-navbar-color: rgba(0, 0, 0, 0.55);
  --nv-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --nv-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --nv-navbar-active-color: rgba(0, 0, 0, 0.9);
  --nv-navbar-brand-padding-y: 0.2890625rem;
  --nv-navbar-brand-margin-end: 1rem;
  --nv-navbar-brand-font-size: 1.40625rem;
  --nv-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --nv-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --nv-navbar-nav-link-padding-x: 0.5rem;
  --nv-navbar-toggler-padding-y: 0.25rem;
  --nv-navbar-toggler-padding-x: 0.75rem;
  --nv-navbar-toggler-font-size: 1.40625rem;
  --nv-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --nv-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --nv-navbar-toggler-border-radius: 0.375rem;
  --nv-navbar-toggler-focus-width: 0;
  --nv-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--nv-navbar-padding-y) var(--nv-navbar-padding-x);
}
.nv-evox-root .navbar > .container,
.nv-evox-root .navbar > .container-fluid,
.nv-evox-root .navbar > .container-sm,
.nv-evox-root .navbar > .container-md,
.nv-evox-root .navbar > .container-lg,
.nv-evox-root .navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.nv-evox-root .navbar-brand {
  padding-top: var(--nv-navbar-brand-padding-y);
  padding-bottom: var(--nv-navbar-brand-padding-y);
  margin-right: var(--nv-navbar-brand-margin-end);
  font-size: var(--nv-navbar-brand-font-size);
  color: var(--nv-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.nv-evox-root .navbar-brand:hover, .nv-evox-root .navbar-brand:focus {
  color: var(--nv-navbar-brand-hover-color);
  text-decoration: none;
}
.nv-evox-root .navbar-nav {
  --nv-nav-link-padding-x: 0;
  --nv-nav-link-padding-y: 0.5rem;
  --nv-nav-link-font-weight: ;
  --nv-nav-link-color: var(--nv-navbar-color);
  --nv-nav-link-hover-color: var(--nv-navbar-hover-color);
  --nv-nav-link-disabled-color: var(--nv-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nv-evox-root .navbar-nav .show > .nav-link,
.nv-evox-root .navbar-nav .nav-link.active {
  color: var(--nv-navbar-active-color);
}
.nv-evox-root .navbar-nav .dropdown-menu {
  position: static;
}
.nv-evox-root .navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--nv-navbar-color);
}
.nv-evox-root .navbar-text a,
.nv-evox-root .navbar-text a:hover,
.nv-evox-root .navbar-text a:focus {
  color: var(--nv-navbar-active-color);
}
.nv-evox-root .navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.nv-evox-root .navbar-toggler {
  padding: var(--nv-navbar-toggler-padding-y) var(--nv-navbar-toggler-padding-x);
  font-size: var(--nv-navbar-toggler-font-size);
  line-height: 1;
  color: var(--nv-navbar-color);
  background-color: transparent;
  border: var(--nv-border-width) solid var(--nv-navbar-toggler-border-color);
  border-radius: var(--nv-navbar-toggler-border-radius);
  transition: var(--nv-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .nv-evox-root .navbar-toggler {
    transition: none;
  }
}
.nv-evox-root .navbar-toggler:hover {
  text-decoration: none;
}
.nv-evox-root .navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--nv-navbar-toggler-focus-width);
}
.nv-evox-root .navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--nv-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.nv-evox-root .navbar-nav-scroll {
  max-height: var(--nv-scroll-height, 75vh);
  overflow-y: auto;
}
@media (min-width: 480px) {
  .nv-evox-root .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .nv-evox-root .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .nv-evox-root .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .nv-evox-root .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--nv-navbar-nav-link-padding-x);
    padding-left: var(--nv-navbar-nav-link-padding-x);
  }
  .nv-evox-root .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .nv-evox-root .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .nv-evox-root .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .nv-evox-root .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .nv-evox-root .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .nv-evox-root .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .nv-evox-root .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .nv-evox-root .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .nv-evox-root .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .nv-evox-root .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--nv-navbar-nav-link-padding-x);
    padding-left: var(--nv-navbar-nav-link-padding-x);
  }
  .nv-evox-root .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .nv-evox-root .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .nv-evox-root .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .nv-evox-root .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .nv-evox-root .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .nv-evox-root .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1024px) {
  .nv-evox-root .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .nv-evox-root .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .nv-evox-root .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .nv-evox-root .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--nv-navbar-nav-link-padding-x);
    padding-left: var(--nv-navbar-nav-link-padding-x);
  }
  .nv-evox-root .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .nv-evox-root .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .nv-evox-root .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .nv-evox-root .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .nv-evox-root .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .nv-evox-root .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1280px) {
  .nv-evox-root .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .nv-evox-root .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .nv-evox-root .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .nv-evox-root .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--nv-navbar-nav-link-padding-x);
    padding-left: var(--nv-navbar-nav-link-padding-x);
  }
  .nv-evox-root .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .nv-evox-root .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .nv-evox-root .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .nv-evox-root .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .nv-evox-root .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .nv-evox-root .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.nv-evox-root .navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.nv-evox-root .navbar-expand .navbar-nav {
  flex-direction: row;
}
.nv-evox-root .navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.nv-evox-root .navbar-expand .navbar-nav .nav-link {
  padding-right: var(--nv-navbar-nav-link-padding-x);
  padding-left: var(--nv-navbar-nav-link-padding-x);
}
.nv-evox-root .navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.nv-evox-root .navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.nv-evox-root .navbar-expand .navbar-toggler {
  display: none;
}
.nv-evox-root .navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.nv-evox-root .navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.nv-evox-root .navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}
.nv-evox-root .navbar-dark {
  --nv-navbar-color: rgba(255, 255, 255, 0.55);
  --nv-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --nv-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --nv-navbar-active-color: #fff;
  --nv-navbar-brand-color: #fff;
  --nv-navbar-brand-hover-color: #fff;
  --nv-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --nv-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.nv-evox-root .modal {
  --nv-modal-zindex: 1055;
  --nv-modal-width: 100%;
  --nv-modal-padding: 0;
  --nv-modal-margin: 0px;
  --nv-modal-color: ;
  --nv-modal-bg: transparent;
  --nv-modal-border-color: transparent;
  --nv-modal-border-width: 0;
  --nv-modal-border-radius: 0;
  --nv-modal-box-shadow: none;
  --nv-modal-inner-border-radius: 0;
  --nv-modal-header-padding-x: 1rem;
  --nv-modal-header-padding-y: 1rem;
  --nv-modal-header-padding: 1rem 1rem;
  --nv-modal-header-border-color: var(--nv-border-color);
  --nv-modal-header-border-width: 0;
  --nv-modal-title-line-height: 1.5;
  --nv-modal-footer-gap: 0.5rem;
  --nv-modal-footer-bg: ;
  --nv-modal-footer-border-color: var(--nv-border-color);
  --nv-modal-footer-border-width: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--nv-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.nv-evox-root .modal-dialog {
  position: relative;
  width: auto;
  margin: var(--nv-modal-margin);
  pointer-events: none;
}
.modal.fade .nv-evox-root .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .nv-evox-root .modal-dialog {
    transition: none;
  }
}
.modal.show .nv-evox-root .modal-dialog {
  transform: none;
}
.modal.modal-static .nv-evox-root .modal-dialog {
  transform: scale(1.02);
}
.nv-evox-root .modal-dialog-scrollable {
  height: calc(100% - var(--nv-modal-margin) * 2);
}
.nv-evox-root .modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.nv-evox-root .modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.nv-evox-root .modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--nv-modal-margin) * 2);
}
.nv-evox-root .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--nv-modal-color);
  pointer-events: auto;
  background-color: var(--nv-modal-bg);
  background-clip: padding-box;
  border: var(--nv-modal-border-width) solid var(--nv-modal-border-color);
  border-radius: var(--nv-modal-border-radius);
  outline: 0;
}
.nv-evox-root .modal-backdrop {
  --nv-backdrop-zindex: 1050;
  --nv-backdrop-bg: #003460;
  --nv-backdrop-opacity: 0.85;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--nv-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--nv-backdrop-bg);
}
.nv-evox-root .modal-backdrop.fade {
  opacity: 0;
}
.nv-evox-root .modal-backdrop.show {
  opacity: var(--nv-backdrop-opacity);
}
.nv-evox-root .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--nv-modal-header-padding);
  border-bottom: var(--nv-modal-header-border-width) solid var(--nv-modal-header-border-color);
  border-top-left-radius: var(--nv-modal-inner-border-radius);
  border-top-right-radius: var(--nv-modal-inner-border-radius);
}
.nv-evox-root .modal-header .btn-close {
  padding: calc(var(--nv-modal-header-padding-y) * 0.5) calc(var(--nv-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--nv-modal-header-padding-y)) calc(-0.5 * var(--nv-modal-header-padding-x)) calc(-0.5 * var(--nv-modal-header-padding-y)) auto;
}
.nv-evox-root .modal-title {
  margin-bottom: 0;
  line-height: var(--nv-modal-title-line-height);
}
.nv-evox-root .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--nv-modal-padding);
}
.nv-evox-root .modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--nv-modal-padding) - var(--nv-modal-footer-gap) * 0.5);
  background-color: var(--nv-modal-footer-bg);
  border-top: var(--nv-modal-footer-border-width) solid var(--nv-modal-footer-border-color);
  border-bottom-right-radius: var(--nv-modal-inner-border-radius);
  border-bottom-left-radius: var(--nv-modal-inner-border-radius);
}
.nv-evox-root .modal-footer > * {
  margin: calc(var(--nv-modal-footer-gap) * 0.5);
}
@media (min-width: 480px) {
  .nv-evox-root .modal {
    --nv-modal-margin: 0px;
    --nv-modal-box-shadow: none;
  }
  .nv-evox-root .modal-dialog {
    max-width: var(--nv-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .nv-evox-root .modal-sm {
    --nv-modal-width: 100%;
  }
}
@media (min-width: 1024px) {
  .nv-evox-root .modal-lg,
  .nv-evox-root .modal-xl {
    --nv-modal-width: 100%;
  }
}
@media (min-width: 1280px) {
  .nv-evox-root .modal-xl {
    --nv-modal-width: 100%;
  }
}
.nv-evox-root .modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.nv-evox-root .modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.nv-evox-root .modal-fullscreen .modal-header,
.nv-evox-root .modal-fullscreen .modal-footer {
  border-radius: 0;
}
.nv-evox-root .modal-fullscreen .modal-body {
  overflow-y: auto;
}
@media (max-width: 479.98px) {
  .nv-evox-root .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .nv-evox-root .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .nv-evox-root .modal-fullscreen-sm-down .modal-header,
  .nv-evox-root .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .nv-evox-root .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .nv-evox-root .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .nv-evox-root .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .nv-evox-root .modal-fullscreen-md-down .modal-header,
  .nv-evox-root .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .nv-evox-root .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1023.98px) {
  .nv-evox-root .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .nv-evox-root .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .nv-evox-root .modal-fullscreen-lg-down .modal-header,
  .nv-evox-root .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .nv-evox-root .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1279.98px) {
  .nv-evox-root .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .nv-evox-root .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .nv-evox-root .modal-fullscreen-xl-down .modal-header,
  .nv-evox-root .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .nv-evox-root .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
.nv-evox-root .carousel {
  position: relative;
}
.nv-evox-root .carousel.pointer-event {
  touch-action: pan-y;
}
.nv-evox-root .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.nv-evox-root .carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.nv-evox-root .carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nv-evox-root .carousel-item {
    transition: none;
  }
}
.nv-evox-root .carousel-item.active,
.nv-evox-root .carousel-item-next,
.nv-evox-root .carousel-item-prev {
  display: block;
}
.nv-evox-root .carousel-item-next:not(.carousel-item-start),
.nv-evox-root .active.carousel-item-end {
  transform: translateX(100%);
}
.nv-evox-root .carousel-item-prev:not(.carousel-item-end),
.nv-evox-root .active.carousel-item-start {
  transform: translateX(-100%);
}
.nv-evox-root .carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.nv-evox-root .carousel-fade .carousel-item.active,
.nv-evox-root .carousel-fade .carousel-item-next.carousel-item-start,
.nv-evox-root .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.nv-evox-root .carousel-fade .active.carousel-item-start,
.nv-evox-root .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .nv-evox-root .carousel-fade .active.carousel-item-start,
  .nv-evox-root .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}
.nv-evox-root .carousel-control-prev,
.nv-evox-root .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .nv-evox-root .carousel-control-prev,
  .nv-evox-root .carousel-control-next {
    transition: none;
  }
}
.nv-evox-root .carousel-control-prev:hover, .nv-evox-root .carousel-control-prev:focus,
.nv-evox-root .carousel-control-next:hover,
.nv-evox-root .carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.nv-evox-root .carousel-control-prev {
  left: 0;
}
.nv-evox-root .carousel-control-next {
  right: 0;
}
.nv-evox-root .carousel-control-prev-icon,
.nv-evox-root .carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}
.nv-evox-root .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}
.nv-evox-root .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.nv-evox-root .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.nv-evox-root .carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .nv-evox-root .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.nv-evox-root .carousel-indicators .active {
  opacity: 1;
}
.nv-evox-root .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}
.nv-evox-root .carousel-dark .carousel-control-prev-icon,
.nv-evox-root .carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.nv-evox-root .carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.nv-evox-root .carousel-dark .carousel-caption {
  color: #000;
}
.nv-evox-root .dropup,
.nv-evox-root .dropend,
.nv-evox-root .dropdown,
.nv-evox-root .dropstart,
.nv-evox-root .dropup-center,
.nv-evox-root .dropdown-center {
  position: relative;
}
.nv-evox-root .dropdown-toggle {
  white-space: nowrap;
}
.nv-evox-root .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.nv-evox-root .dropdown-toggle:empty::after {
  margin-left: 0;
}
.nv-evox-root .dropdown-menu {
  --nv-dropdown-zindex: 1000;
  --nv-dropdown-min-width: 10rem;
  --nv-dropdown-padding-x: 0;
  --nv-dropdown-padding-y: 0;
  --nv-dropdown-spacer: 0;
  --nv-dropdown-font-size: 1.125rem;
  --nv-dropdown-color: #003460;
  --nv-dropdown-bg: #fff;
  --nv-dropdown-border-color: #aec2ce;
  --nv-dropdown-border-radius: 0 0 0.375rem 0.375rem;
  --nv-dropdown-border-width: 0;
  --nv-dropdown-inner-border-radius: 0.375rem;
  --nv-dropdown-divider-bg: #e9ecef;
  --nv-dropdown-divider-margin-y: 0.5625rem;
  --nv-dropdown-box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.15);
  --nv-dropdown-link-color: #003460;
  --nv-dropdown-link-hover-color: #003460;
  --nv-dropdown-link-hover-bg: #f1f6f9;
  --nv-dropdown-link-active-color: #003460;
  --nv-dropdown-link-active-bg: #f1f6f9;
  --nv-dropdown-link-disabled-color: #608ca1;
  --nv-dropdown-item-padding-x: 1.0625rem;
  --nv-dropdown-item-padding-y: 0.8125rem;
  --nv-dropdown-header-color: #6c757d;
  --nv-dropdown-header-padding-x: 1.0625rem;
  --nv-dropdown-header-padding-y: 0;
  position: absolute;
  z-index: var(--nv-dropdown-zindex);
  display: none;
  min-width: var(--nv-dropdown-min-width);
  padding: var(--nv-dropdown-padding-y) var(--nv-dropdown-padding-x);
  margin: 0;
  font-size: var(--nv-dropdown-font-size);
  color: var(--nv-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--nv-dropdown-bg);
  background-clip: padding-box;
  border: var(--nv-dropdown-border-width) solid var(--nv-dropdown-border-color);
  border-radius: var(--nv-dropdown-border-radius);
}
.nv-evox-root .dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--nv-dropdown-spacer);
}
.nv-evox-root .dropdown-menu > .dropdown-item:first-child,
.nv-evox-root .dropdown-menu > li:first-child .dropdown-item {
  border-top-left-radius: var(--nv-dropdown-inner-border-radius);
  border-top-right-radius: var(--nv-dropdown-inner-border-radius);
}
.nv-evox-root .dropdown-menu > .dropdown-item:last-child,
.nv-evox-root .dropdown-menu > li:last-child .dropdown-item {
  border-bottom-right-radius: var(--nv-dropdown-inner-border-radius);
  border-bottom-left-radius: var(--nv-dropdown-inner-border-radius);
}
.nv-evox-root .dropdown-menu-start {
  --bs-position: start;
}
.nv-evox-root .dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}
.nv-evox-root .dropdown-menu-end {
  --bs-position: end;
}
.nv-evox-root .dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}
@media (min-width: 480px) {
  .nv-evox-root .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .nv-evox-root .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .nv-evox-root .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .nv-evox-root .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .nv-evox-root .dropdown-menu-md-start {
    --bs-position: start;
  }
  .nv-evox-root .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .nv-evox-root .dropdown-menu-md-end {
    --bs-position: end;
  }
  .nv-evox-root .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1024px) {
  .nv-evox-root .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .nv-evox-root .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .nv-evox-root .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .nv-evox-root .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1280px) {
  .nv-evox-root .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .nv-evox-root .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .nv-evox-root .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .nv-evox-root .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.nv-evox-root .dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--nv-dropdown-spacer);
}
.nv-evox-root .dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.nv-evox-root .dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.nv-evox-root .dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--nv-dropdown-spacer);
}
.nv-evox-root .dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.nv-evox-root .dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.nv-evox-root .dropend .dropdown-toggle::after {
  vertical-align: 0;
}
.nv-evox-root .dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--nv-dropdown-spacer);
}
.nv-evox-root .dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.nv-evox-root .dropstart .dropdown-toggle::after {
  display: none;
}
.nv-evox-root .dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.nv-evox-root .dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.nv-evox-root .dropstart .dropdown-toggle::before {
  vertical-align: 0;
}
.nv-evox-root .dropdown-divider {
  height: 0;
  margin: var(--nv-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--nv-dropdown-divider-bg);
  opacity: 1;
}
.nv-evox-root .dropdown-item {
  display: block;
  width: 100%;
  padding: var(--nv-dropdown-item-padding-y) var(--nv-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--nv-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.nv-evox-root .dropdown-item:hover, .nv-evox-root .dropdown-item:focus {
  color: var(--nv-dropdown-link-hover-color);
  text-decoration: none;
  background-color: var(--nv-dropdown-link-hover-bg);
}
.nv-evox-root .dropdown-item.active, .nv-evox-root .dropdown-item:active {
  color: var(--nv-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--nv-dropdown-link-active-bg);
}
.nv-evox-root .dropdown-item.disabled, .nv-evox-root .dropdown-item:disabled {
  color: var(--nv-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}
.nv-evox-root .dropdown-menu.show {
  display: block;
}
.nv-evox-root .dropdown-header {
  display: block;
  padding: var(--nv-dropdown-header-padding-y) var(--nv-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.95625rem;
  color: var(--nv-dropdown-header-color);
  white-space: nowrap;
}
.nv-evox-root .dropdown-item-text {
  display: block;
  padding: var(--nv-dropdown-item-padding-y) var(--nv-dropdown-item-padding-x);
  color: var(--nv-dropdown-link-color);
}
.nv-evox-root .dropdown-menu-dark {
  --nv-dropdown-color: #dee2e6;
  --nv-dropdown-bg: #343a40;
  --nv-dropdown-border-color: #aec2ce;
  --nv-dropdown-box-shadow: ;
  --nv-dropdown-link-color: #dee2e6;
  --nv-dropdown-link-hover-color: #fff;
  --nv-dropdown-divider-bg: #e9ecef;
  --nv-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --nv-dropdown-link-active-color: #003460;
  --nv-dropdown-link-active-bg: #f1f6f9;
  --nv-dropdown-link-disabled-color: #adb5bd;
  --nv-dropdown-header-color: #adb5bd;
}
.nv-evox-root .bg-white {
  background-color: #fff !important;
}
.nv-evox-root .bg-transparent {
  background-color: transparent !important;
}
.nv-evox-root .border-primary {
  border-color: #003460 !important;
}
.nv-evox-root .border-primary-0 {
  border-color: #2a697f !important;
}
.nv-evox-root .border-primary-1 {
  border-color: #608ca1 !important;
}
.nv-evox-root .border-primary-2 {
  border-color: #aec2ce !important;
}
.nv-evox-root .border-primary-3 {
  border-color: #e0ebf1 !important;
}
.nv-evox-root .border-primary-35 {
  border-color: #f1f6f9 !important;
}
.nv-evox-root .border-secondary {
  border-color: #009bb7 !important;
}
.nv-evox-root .border-secondary-hover {
  border-color: #03859e !important;
}
.nv-evox-root .border-accent {
  border-color: #ff7c6b !important;
}
.nv-evox-root .border-error {
  border-color: #ff7c6b !important;
}
.nv-evox-root .border-warning {
  border-color: #ffd631 !important;
}
.nv-evox-root .border-success {
  border-color: #44ad4b !important;
}
.nv-evox-root .sbdocs.sbdocs-content {
  width: 100% !important;
  max-width: inherit !important;
  min-height: inherit !important;
}
.nv-evox-root div[id^=anchor--components] {
  min-height: 200px !important;
}
.nv-evox-root div[id^=story--components] {
  min-height: 200px !important;
}
.nv-evox-root div[id^=story--components] > div {
  min-height: 170px !important;
}
.nv-evox-root iframe[id^=iframe--components] {
  min-height: 170px !important;
}
@media (max-width: 767.98px) {
  .nv-evox-root input[type=date] {
    -webkit-appearance: none;
  }
}
@media (max-width: 767.98px) {
  .nv-evox-root h1,
  .nv-evox-root .h1 {
    font-size: calc(1.3995rem + 1.794vw);
  }
}
@media (max-width: 767.98px) and (min-width: 1200px) {
  .nv-evox-root h1,
  .nv-evox-root .h1 {
    font-size: 2.745rem;
  }
}
@media (max-width: 767.98px) {
  .nv-evox-root h2,
  .nv-evox-root .h2,
  .nv-evox-root .h2-sm {
    font-size: calc(1.26rem + 0.12vw);
  }
}
@media (max-width: 767.98px) and (min-width: 1200px) {
  .nv-evox-root h2,
  .nv-evox-root .h2,
  .nv-evox-root .h2-sm {
    font-size: 1.35rem;
  }
}
@media (max-width: 767.98px) {
  .nv-evox-root h3,
  .nv-evox-root .h3 {
    font-size: 1.00125rem;
  }
}
@media (max-width: 767.98px) {
  .nv-evox-root h4,
  .nv-evox-root .h4 {
    font-size: 1.00125rem;
  }
}
@media (max-width: 767.98px) {
  .nv-evox-root h5,
  .nv-evox-root .h5 {
    font-size: 1.00125rem;
  }
}
@media (max-width: 767.98px) {
  .nv-evox-root h6,
  .nv-evox-root .h6 {
    font-size: 1.00125rem;
  }
}
.nv-evox-root body {
  overflow-y: scroll;
}
@media (max-width: 767.98px) {
  .nv-evox-root body {
    font-size: 0.95625rem;
  }
}
.nv-evox-root ngb-datepicker {
  font-family: "NuernbergerTextWeb", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.125rem;
  border-radius: 0 !important;
}
.nv-evox-root ngb-datepicker .ngb-dp-header {
  border-radius: 0;
  margin-bottom: 1rem;
}
.nv-evox-root ngb-datepicker .ngb-dp-content .ngb-dp-month {
  width: 100%;
}
.nv-evox-root ngb-datepicker .ngb-dp-content .ngb-dp-month .ngb-dp-weekdays {
  margin-bottom: 0.5rem;
}
.nv-evox-root ngb-datepicker .ngb-dp-content .ngb-dp-month .ngb-dp-weekday,
.nv-evox-root ngb-datepicker .ngb-dp-content .ngb-dp-month .ngb-dp-day {
  width: 14.2857142857%;
  font-style: normal;
  font-size: 1.125rem;
  text-align: center;
}
.nv-evox-root ngb-datepicker .ngb-dp-content .ngb-dp-month .ngb-dp-weekday .btn-light,
.nv-evox-root ngb-datepicker .ngb-dp-content .ngb-dp-month .ngb-dp-day .btn-light {
  border-radius: 0;
  width: 100%;
}
.nv-evox-root ngb-datepicker .ngb-dp-content .ngb-dp-month .ngb-dp-weekday .btn-light:hover,
.nv-evox-root ngb-datepicker .ngb-dp-content .ngb-dp-month .ngb-dp-day .btn-light:hover {
  background-color: #009bb7;
}
.nv-evox-root ngb-datepicker .ngb-dp-content .ngb-dp-month .ngb-dp-weekday .btn-light.outside:hover,
.nv-evox-root ngb-datepicker .ngb-dp-content .ngb-dp-month .ngb-dp-day .btn-light.outside:hover {
  color: #fff !important;
}
.nv-evox-root ngb-datepicker .ngb-dp-content .ngb-dp-month .ngb-dp-weekday:focus,
.nv-evox-root ngb-datepicker .ngb-dp-content .ngb-dp-month .ngb-dp-day:focus {
  outline: none;
  box-shadow: none;
}
.nv-evox-root ngb-datepicker .ngb-dp-content .ngb-dp-month .ngb-dp-weekday.ngb-dp-today .btn-light, .nv-evox-root ngb-datepicker .ngb-dp-content .ngb-dp-month .ngb-dp-weekday[tabindex="0"] .btn-light,
.nv-evox-root ngb-datepicker .ngb-dp-content .ngb-dp-month .ngb-dp-day.ngb-dp-today .btn-light,
.nv-evox-root ngb-datepicker .ngb-dp-content .ngb-dp-month .ngb-dp-day[tabindex="0"] .btn-light {
  background-color: #009bb7;
  color: #fff;
}
.nv-evox-root ngb-datepicker .ngb-dp-content .ngb-dp-month .ngb-dp-weekday.disabled .btn-light:hover,
.nv-evox-root ngb-datepicker .ngb-dp-content .ngb-dp-month .ngb-dp-day.disabled .btn-light:hover {
  background-color: #fff;
  color: #adb5bd !important;
}
.nv-evox-root a {
  transition: 0.3s ease-in-out;
}
.nv-evox-root .custom-select {
  display: inline-block;
  width: 100%;
  padding: 0.8125rem 4.25rem 0.8125rem 1.0625rem;
  font-family: "NuernbergerTextWeb", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5556;
  color: #003460;
  vertical-align: middle;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.0625rem center;
  background-size: 16px 12px;
  border: 1px solid #aec2ce;
  border-radius: 0.375rem;
  transition: border-color 0.3s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .nv-evox-root .custom-select {
    transition: none;
  }
}
.nv-evox-root .custom-select:focus {
  border-color: #009bb7;
  outline: 0;
  box-shadow: 0 0 0 0 #009bb7;
}
.nv-evox-root .custom-select[multiple], .nv-evox-root .custom-select[size]:not([size="1"]) {
  padding-right: 1.0625rem;
  background-image: none;
}
.nv-evox-root .custom-select:disabled {
  background-color: #e9ecef;
}
.nv-evox-root .custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #003460;
}
.nv-evox-root .custom-select-sm {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1.0625rem;
  font-size: 1.00125rem;
}
.nv-evox-root .custom-select-lg {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.75rem;
  font-size: calc(1.34375rem + 1.125vw);
}
@media (min-width: 1200px) {
  .nv-evox-root .custom-select-lg {
    font-size: 2.1875rem;
  }
}
.nv-evox-root .shake {
  animation-name: shake-animation;
  animation-duration: 2s;
  border: 1px solid red;
}
.nv-evox-root .login-shake {
  animation-name: shake-animation;
  animation-duration: 2s;
}
.nv-evox-root .login-shake-dynamic {
  animation-iteration-count: infinite;
}
@keyframes shake-animation {
  10%, 90% {
    transform: translate3d(-0.5px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-3px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(3px, 0, 0);
  }
}
.nv-evox-root .cursor-pointer:not(.disabled), .nv-evox-root .cursor-pointer:not(:disabled) {
  cursor: pointer;
}
.nv-evox-root .lh-100 {
  line-height: 100%;
}
.nv-evox-root .pos-relative {
  position: relative;
  padding-right: 1.5rem;
}
.nv-evox-root section.content .nv-checked-list {
  position: relative;
  margin: 0;
  padding: 2rem 0;
  list-style-type: none;
}
.nv-evox-root section.content .nv-checked-list li {
  position: relative;
  padding-left: 2rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpath fill='%23009bb7' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z' /%3e%3c/svg%3e");
  background-position: left 3px;
  background-size: 1.125rem 1.125rem;
  background-repeat: no-repeat;
}
.nv-evox-root section.content .nv-checked-list li.dkr-li-bg-position {
  background-position-y: 21px;
}
.nv-evox-root section.content .nv-checked-list.text-muted .fas.fa-info-circle {
  color: #adb5bd;
  cursor: default;
}
.nv-evox-root section.content .nv-checked-list.text-muted li, .nv-evox-root section.content .nv-checked-list li.text-muted {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 480 512'%3e%3cpath fill='%23adb5bd' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z' /%3e%3c/svg%3e");
  background-position: left 5px;
}
.nv-evox-root section.content .nv-checked-list:first-of-type {
  padding-top: 0.5rem;
}
.nv-evox-root section.content .nv-checked-list:last-of-type {
  padding-bottom: 0.5rem;
}
.nv-evox-root section.content .nv-checked-list:not(:last-of-type) li:last-of-type:after {
  position: absolute;
  left: 0;
  bottom: -2rem;
  width: 100%;
  height: 1px;
  content: "";
  background-color: #ebedef;
}
.nv-evox-root section.nv-pricing-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 900;
  padding: 1rem 0;
}
@media (min-width: 1024px) {
  .nv-evox-root section.nv-pricing-footer {
    height: 91px;
  }
  .nv-evox-root section.nv-pricing-footer ~ section.content {
    margin-bottom: calc(2rem + 91px);
  }
}
.nv-evox-root .ie {
  display: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .nv-evox-root .ie {
    display: block !important;
  }
}
.nv-evox-root .carousel-indicators li {
  border: 5px solid #009bb7;
  width: 0;
  height: 0;
  border-radius: 100%;
}
.nv-evox-root .carousel-control-next-icon {
  opacity: 0;
}
.nv-evox-root .carousel-control-prev-icon {
  opacity: 0;
}
.nv-evox-root .header-fixed-top section.content {
  margin-top: calc(calc(34px + (1rem * 2)) + 2rem);
}
@media (min-width: 1024px) {
  .nv-evox-root .header-fixed-top section.content {
    min-height: calc(100vh - (calc(50px + (1rem * 2)) + 2rem) - 133px);
    margin-top: calc(calc(50px + (1rem * 2)) + 2rem);
  }
}
@media (max-width: 767.98px) {
  .nv-evox-root .header-fixed-top-kupo section:first-of-type {
    margin-top: calc(calc(4rem + 2px + (1rem * 2)) + 2rem);
  }
}
.nv-evox-root .dropdown-toggle::after {
  content: none;
}
.nv-evox-root .dropdown-menu {
  width: 100%;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.15);
}

:root {
  --nv-global-bg: var(--nv-primary-35);
}

.ie {
  display: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ie {
    display: block !important;
  }
}
body.bg-primary-35 {
  background-color: var(--nv-primary-35);
}

.nv-evox-root:not(.modal-dialog) {
  display: block;
  background-color: var(--nv-global-bg);
}

.header-fixed-top section.content {
  margin-top: calc(calc(34px + (1rem * 2)) + 2rem);
}
@media (min-width: 1024px) {
  .header-fixed-top section.content {
    min-height: calc(100vh - (50px + 2rem + 2rem) - 133px);
    margin-top: calc(calc(50px + (1rem * 2)) + 2rem);
  }
}
.header-fixed-top section.content.progress-fixed-top {
  margin-top: calc(calc(34px + (1rem * 2)) + 2rem + 62px);
}
@media (min-width: 1024px) {
  .header-fixed-top section.content.progress-fixed-top {
    min-height: calc(100vh - (50px + 2rem + 2rem) - 133px);
    margin-top: calc(calc(50px + (1rem * 2)) + 2rem + 93px);
  }
}

@media (max-width: 768px) {
  .header-fixed-top-kupo section:first-of-type {
    margin-top: calc(4rem + 2px + 2rem + 2rem);
  }
}
.visually-hidden {
  display: none;
}