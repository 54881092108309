// Bootstrap Functions & Mixins
@import 'bootstrap/scss/functions';

// Custom & Bootstrap Mixins
@import 'abstracts/mixins';

// Custom variable overrides
@import 'abstracts/variables';

// Bootstrap Variables
@import 'bootstrap/scss/variables';

// Custom maps overrides
// TODO: possibly move map overrides from abstracts/variables to seperate file

// Bootstrap Maps
@import 'bootstrap/scss/maps';

// Custom Clearing
@import 'base/reset';

// Bootstrap Optional
@import 'bootstrap/scss/root';

@import 'bootstrap/scss/utilities';

// Extracted body from boostrap reboot to use it on our root element
@import 'base/root-reboot';

// apply reboot in our scope to avoid leaking into host site
$reboot-root-selector: 'body' !default;
#{$reboot-root-selector} {
  @import 'bootstrap/scss/reboot';
}

.nv-evox-root {
  @import 'bootstrap/scss/type';
  @import 'bootstrap/scss/images';
  @import 'bootstrap/scss/containers';
  @import 'bootstrap/scss/grid';

  @import 'bootstrap/scss/helpers';
  @import 'bootstrap/scss/utilities/api';

  @import 'bootstrap/scss/transitions';

  @import 'bootstrap/scss/nav';
  @import 'bootstrap/scss/navbar';

  @import 'bootstrap/scss/modal';
  @import 'bootstrap/scss/carousel';
  @import 'bootstrap/scss/dropdown';

  //TODO: refactor additions
  @import 'additions';

  // NBGR header
  $header-padding-y: map-get($spacers, 2);
  $header-height-sm-down: calc(4rem + 2px + (#{$header-padding-y} * 2));
  $header-height-md-down: calc(32px + 2px + (#{$header-padding-y} * 2));
  $header-height-lg-up: calc(48px + 2px + (#{$header-padding-y} * 2));
  $header-fixed-section-spacer: map-get($spacers, 3);

  .ie {
    display: none;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ie {
      display: block !important;
    }
  }

  .carousel-indicators li {
    border: 5px solid map-get($theme-colors, 'secondary');
    width: 0;
    height: 0;
    border-radius: 100%;
  }

  .carousel-control-next-icon {
    opacity: 0;
  }

  .carousel-control-prev-icon {
    opacity: 0;
  }

  // Overwrites on body level for fixed header (apply spacer margins on the first content section)
  .header-fixed-top {
    section {
      &.content {
        margin-top: calc(#{$header-height-md-down} + #{$header-fixed-section-spacer});
        @include media-breakpoint-up('lg') {
          min-height: calc(100vh - calc(#{$header-height-lg-up} + #{$header-fixed-section-spacer}) - 133px);
          margin-top: calc(#{$header-height-lg-up} + #{$header-fixed-section-spacer});
        }
      }
    }
  }

  .header-fixed-top-kupo {
    section {
      &:first-of-type {
        @include media-breakpoint-down('md') {
          margin-top: calc(#{$header-height-sm-down} + #{$header-fixed-section-spacer});
        }
      }
    }
  }

  // loading style globally so it works with ngb datepicker in kupo

  @include dropdown;
}

@import 'global-additions';
