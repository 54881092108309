:root {
  --nv-global-bg: var(--nv-primary-35);
}

.ie {
  display: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ie {
    display: block !important;
  }
}

body.bg-primary-35 {
  background-color: var(--nv-primary-35);
}

.nv-evox-root:not(.modal-dialog) {
  display: block;
  background-color: var(--nv-global-bg);
}

.header-fixed-top section.content {
  margin-top: calc(#{$header-height-md-down} + 2rem);
  @include media-breakpoint-up('lg') {
    min-height: calc(100vh - (calc(50px + (1rem * 2)) + 2rem) - 133px);
    margin-top: calc(#{$header-height-lg-up} + 2rem);
  }

  $progress-bar-height: 62px;
  $progress-bar-height-lg: 93px;
  &.progress-fixed-top {
    margin-top: calc(#{$header-height-md-down} + 2rem + #{$progress-bar-height});
    @include media-breakpoint-up('lg') {
      min-height: calc(100vh - (calc(50px + (1rem * 2)) + 2rem) - 133px);
      margin-top: calc(#{$header-height-lg-up} + 2rem + #{$progress-bar-height-lg});
    }
  }
}

@media (max-width: 768px) {
  .header-fixed-top-kupo section:first-of-type {
    margin-top: calc(calc(4rem + 2px + (1rem * 2)) + 2rem);
  }
}

// this fixes the bootstrap typeahead counter at the bottom of the page

.visually-hidden {
  display: none;
}
