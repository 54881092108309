@font-face {
  font-family: 'NuernbergerTextWeb';
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  src: url('../../assets/fonts/nuernbergertextweb-bold.eot');
  src: url('../../assets/fonts/nuernbergertextweb-bold.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/nuernbergertextweb-bold.woff2') format('woff2'),
    url('../../assets/fonts/nuernbergertextweb-bold.woff') format('woff'),
    url('../../assets/fonts/nuernbergertextweb-bold.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'NuernbergerTextWeb';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: url('../../assets/fonts/nuernbergertextweb-regular.eot');
  src: url('../../assets/fonts/nuernbergertextweb-regular.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/nuernbergertextweb-regular.woff2') format('woff2'),
    url('../../assets/fonts/nuernbergertextweb-regular.woff') format('woff'),
    url('../../assets/fonts/nuernbergertextweb-regular.ttf') format('truetype');
  font-display: swap;
}

$font-family-sans-serif: 'NuernbergerTextWeb', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
  Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;

$headings-font-family: $font-family-base !default;
$input-btn-font-family: $font-family-base !default;
$input-font-family: $input-btn-font-family !default;

@import 'evox/magnum-go';
