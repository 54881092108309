@import 'bootstrap/scss/functions';
@import 'abstracts/variables';
@import 'bootstrap/scss/variables';

@mixin dropdown {
  .dropdown-toggle {
    &::after {
      content: none;
    }
  }

  .dropdown-menu {
    width: 100%;
    box-shadow: $dropdown-box-shadow;
  }
}
