// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-150: #f5f5f7 !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

// TODO: CSS variables
// Colors
$blue: #003460;
$blue-gray-0: #2a697f;
$blue-gray-1: #608ca1;
$blue-gray-2: #aec2ce;
$blue-gray-3: #e0ebf1;
$blue-gray-35: #f1f6f9;
$gray: #9ea4a7;
$turq: #009bb7;
$turq-hover: #03859e;
$rose: #ff7c6b;
$rose-typo: #f3987a;
$purple: #7f4d7c;

$light: $blue-gray-3;

$color-error: $rose;
$color-warning: #ffd631;
$color-success: #44ad4b;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    'blue': $blue,
    'blue-gray-0': $blue-gray-0,
    'blue-gray-1': $blue-gray-1,
    'blue-gray-2': $blue-gray-2,
    'blue-gray-3': $blue-gray-3,
    'blue-gray-35': $blue-gray-35,
    'gray': $gray,
    'turq': $turq,
    'rose': $rose,
    'error': $color-error,
    'warning': $color-warning,
    'success': $color-success,
    'light': $light,
  ),
  $colors
);

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    'primary': $blue,
    'primary-0': $blue-gray-0,
    'primary-1': $blue-gray-1,
    'primary-2': $blue-gray-2,
    'primary-3': $blue-gray-3,
    'primary-35': $blue-gray-35,
    'secondary': $turq,
    'secondary-hover': $turq-hover,
    'accent': $rose,
    'error': $color-error,
    'warning': $color-warning,
    'success': $color-success,
    'light': $light,
  ),
  $theme-colors
);

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 3 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: map-get($colors, 'blue') !default;
$color-contrast-light: $white !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-validation-icons: false !default;

// Prefix for :root CSS variables

$variable-prefix: nv- !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1.125rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: 0.5rem,
    2: 1rem,
    3: 2rem,
    4: 3rem,
    5: 6rem,
    -1: -0.5rem,
    -2: -1rem,
    -3: -2rem,
    -4: -3rem,
    -5: -6rem,
  ),
  $spacers
);

// Body
//
// Settings for the `<body>` element.

$body-bg: $white !default;
$body-color: map-get($colors, 'blue') !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: $spacer !default;

// Links
//
// Style anchor elements.

$link-color: map-get($colors, 'blue') !default;
$link-decoration: underline !default;
$link-hover-color: map-get($theme-colors, 'secondary') !default;
$link-hover-decoration: underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grid-breakpoints: map-merge(
  (
    xs: 0,
    sm: 480px,
    md: 768px,
    lg: 1024px,
    xl: 1280px,
  ),
  $grid-breakpoints
);

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$container-max-widths: map-merge(
  (
    sm: 480px,
    md: 768px,
    lg: 1024px,
    xl: 1280px,
  ),
  $container-max-widths
);

@include _assert-ascending($container-max-widths, '$container-max-widths');

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 32px !default;

// Components
//
// Define common padding and border radius sizes and more.

$border-width: 1px !default;
$border-color: $gray-300 !default;

$border-radius: 0.375rem !default;
$border-radius-lg: 0 !default;
$border-radius-sm: 0.25rem !default;

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.05) !default;
$box-shadow: 0 0.1875rem 0.375rem rgba($black, 0.15) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;

$component-active-color: $white !default;
$component-active-bg: map-get($theme-colors, 'primary') !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// overwrites bootstrap responsive font-scaling - we don't want to use that

$enable-responsive-font-sizes: false;

$font-size-base: 1.125rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: ($font-size-base * 0.85) !default;
$font-size-lg: ($font-size-base * 1.25) !default;

// $font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
// $font-weight-bolder: 600 !default;

$font-weight-base: $font-weight-normal !default;

$line-height-base: 1.5 !default;
$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;

$h1-font-size: $font-size-base * 2.55 !default;
$h2-font-size: $font-size-base * 2.22 !default;
$h3-font-size: $font-size-base * 1.4 !default;
$h4-font-size: $font-size-base !default;
$h5-font-size: $font-size-base !default;
$h6-font-size: $font-size-base !default;

$h1-font-size-sm: $font-size-base * 2.44 !default;
$h2-font-size-sm: $font-size-base * 1.2 !default;
$h3-font-size-sm: $font-size-base * 0.89 !default;
$h4-font-size-sm: $font-size-base * 0.89 !default;
$h5-font-size-sm: $font-size-base * 0.89 !default;
$h6-font-size-sm: $font-size-base * 0.89 !default;

$headings-font-weight: $font-weight-bold !default;
$headings-line-height: 1.3 !default;
$headings-color: inherit !default;

$small-font-size: 0.9rem !default;

$text-muted: $gray-500 !default;

$list-inline-padding: 0.5rem !default;

$mark-bg: #fcf8e3 !default;

$hr-margin-y: $spacer !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// ---

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.8125rem !default;
$input-btn-padding-x: 1.0625rem !default;
$input-btn-font-size: $font-size-base !default;
$input-btn-line-height: 1.5556 !default;

$input-btn-focus-width: 0 !default;
$input-btn-focus-color: map-get($theme-colors, 'secondary') !default;
$input-btn-focus-box-shadow: inset 0px 0px 0px 1px $input-btn-focus-color !default;

$input-btn-padding-y-sm: 0 !default;
$input-btn-padding-x-sm: 1.0625rem !default;
$input-btn-font-size-sm: $font-size-base * 0.89 !default;
$input-btn-line-height-sm: $line-height-sm * 0.89 !default;

$input-btn-padding-y-lg: 0.75rem !default;
$input-btn-padding-x-lg: 1.75rem !default;
$input-btn-font-size-lg: 2.1875rem !default;
$input-btn-line-height-lg: $line-height-lg !default;

$input-btn-border-width: $border-width !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-border-width: 0.125rem !default;

$btn-font-weight: $font-weight-bold !default;
$btn-box-shadow: $box-shadow !default;
$btn-focus-box-shadow: $btn-box-shadow !default;

$btn-link-disabled-color: map-get($theme-colors, 'primary-1') !default;
$btn-disabled-opacity: 0.3 !default;
$btn-border-radius: 0.375rem !default;
$btn-border-radius-lg: 0 !default;
$btn-border-radius-sm: 0 !default;

$btn-transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out,
  box-shadow 0.25s ease-in-out !default;

$btn-hover-bg-shade-amount: 15% !default;
$btn-hover-bg-tint-amount: 15% !default;
$btn-hover-border-shade-amount: 15% !default;
$btn-hover-border-tint-amount: 15% !default;
$btn-active-bg-shade-amount: 7.5% !default;
$btn-active-bg-tint-amount: 7.5% !default;
$btn-active-border-shade-amount: 7.5% !default;
$btn-active-border-tint-amount: 7.5% !default;

// Forms

$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;

$input-bg: $white !default;
$input-disabled-bg: mix(map-get($theme-colors, 'primary-35'), $white, 25%) !default;

$input-color: map-get($theme-colors, 'primary') !default;
$input-border-color: map-get($theme-colors, 'primary-2') !default;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow: inset 0 0 0 1px #{map-get($theme-colors, 'secondary')} !default;

$input-focus-bg: $input-bg !default;
$input-focus-border-color: map-get($theme-colors, 'secondary') !default;
$input-focus-box-shadow: $input-box-shadow !default;

$input-placeholder-color: map-get($theme-colors, 'primary-1') !default;

$input-height-border: $input-border-width * 2 !default;

$input-transition: border-color 0.3s ease-in-out, box-shadow 0.15s ease-in-out !default;

$transition-base: 0.3s ease-in-out;

$form-group-margin-bottom: 1rem !default;

$input-group-addon-color: $input-color !default;
$input-group-addon-bg: $gray-200 !default;
$input-group-addon-border-color: $input-border-color !default;

$custom-forms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;

$form-control-gutter: 0.5rem;
$form-control-indicator-size: 1.325rem;
$form-check-input-width: $form-control-indicator-size;
$form-check-input-border: 2px solid
  map-get(
    $map: $theme-colors,
    $key: 'primary-1',
  ) !default;
$form-check-input-focus-border: none !default;
$form-check-input-focus-box-shadow: none !default;
$form-check-label-cursor: pointer !default;
$form-check-input-checked-color: map-get(
  $map: $theme-colors,
  $key: 'primary',
) !default;
$form-check-input-checked-bg-color: $white !default;
$form-check-input-checked-border-color: $form-check-input-checked-color !default;

$form-check-padding-start: $form-check-input-width + 0.5rem;

$form-switch-color: map-get(
  $map: $theme-colors,
  $key: 'primary-1',
);
$form-switch-width: $form-control-indicator-size * 1.75 !default;
$form-switch-padding-start: $form-switch-width + 0.5rem !default;
$form-switch-focus-color: $form-switch-color !default;

$form-floating-label-transform: scale(0.75) translateY(-0.5rem) translateX(0.33rem) !default;
$form-floating-transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out !default;

// Form validation

$form-feedback-font-size: $small-font-size !default;
$form-feedback-valid-color: map-get($theme-colors, 'success') !default;
$form-feedback-invalid-color: map-get($theme-colors, 'error') !default;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-fixed: 900 !default;

// Navs

$nav-divider-color: $gray-200 !default;

// Navbar

$navbar-padding-x: null !default;

$navbar-nav-link-padding-x: 0.5rem !default;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-padding-y: 0 !default;
$dropdown-spacer: 0 !default;

//$dropdown-border-color: rgba($black, 0.15) !default;
$dropdown-border-color: $input-border-color !default;
$dropdown-border-radius: 0 0 $border-radius $border-radius !default;
$dropdown-border-width: 0 !default;
$dropdown-inner-border-radius: $border-radius !default;
$dropdown-divider-bg: $gray-200 !default;
$dropdown-box-shadow: $box-shadow !default;

$dropdown-link-color: map-get($theme-colors, 'primary') !default;
$dropdown-link-hover-color: $dropdown-link-color !default;
$dropdown-link-hover-bg: map-get($theme-colors, 'primary-35') !default;

$dropdown-link-active-color: $dropdown-link-color !default;
$dropdown-link-active-bg: $dropdown-link-hover-bg !default;

$dropdown-link-disabled-color: $btn-link-disabled-color !default;

$dropdown-item-padding-y: $input-btn-padding-y !default;
$dropdown-item-padding-x: $input-btn-padding-x !default;

// Pagination

// ---

// Jumbotron

// ---

// Cards

$card-spacer-y: 0.75rem !default;
$card-spacer-x: 1.25rem !default;
$card-border-width: 0 !default;
$card-border-radius: $border-radius !default;

$card-img-overlay-padding: 1.25rem !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;

// Tooltips

// ---

//
// Form tooltips must come after regular tooltips

// ---

// Popovers

$popover-max-width: 100% !default;
$popover-border-width: $border-width !default;
$popover-border-color: transparent !default;
$popover-box-shadow: 0 0.25rem 0.5rem rgba($black, 0.2) !default;

$popover-body-padding-y: 1rem !default;
$popover-body-padding-x: 1rem !default;

$popover-arrow-width: 2rem !default;
$popover-arrow-height: 1rem !default;

$popover-arrow-zindex: 1 !default;

// Toasts

// ---

// Badges

// ---

// Modals

// Padding applied to the modal body
$modal-inner-padding: 0 !default;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between: 0.5rem !default;

$modal-dialog-margin: 0px !default;
$modal-dialog-margin-y-sm-up: 0px !default;

$modal-content-color: null !default;
$modal-content-bg: transparent !default;
$modal-content-border-color: transparent !default;
$modal-content-border-width: 0 !default;
$modal-content-border-radius: 0 !default;
$modal-content-box-shadow-xs: none !default;
$modal-content-box-shadow-sm-up: none !default;

$modal-backdrop-bg: map-get($theme-colors, 'primary') !default;
$modal-backdrop-opacity: 0.85 !default;
$modal-header-padding-y: 1rem !default;
$modal-header-padding-x: 1rem !default;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-xl: 100% !default;
$modal-lg: 100% !default;
$modal-md: 100% !default;
$modal-sm: 100% !default;

// Alerts

// ---

// Progress bars

$progress-height: 0.625rem !default;
$progress-box-shadow: inset 0 0.1rem 0.1rem rgba($black, 0.1) !default;
$progress-bar-bg: map-get($theme-colors, 'primary') !default;

// List group

// ---

// Image thumbnails

// ---

// Figures

// ---

// Breadcrumbs

// ---

// Carousel

// ---

// Spinners

// ---

// Code

// ---

// Additions

// NBGR header
$header-padding-y: map-get($spacers, 2);
$header-height-md-down: calc(32px + 2px + (#{$header-padding-y} * 2));
$header-height-lg-up: calc(48px + 2px + (#{$header-padding-y} * 2));
