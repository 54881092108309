.bg-white {
  background-color: $white !important;
}

.bg-transparent {
  background-color: transparent !important;
}

@if $enable-gradients {
  @each $color, $value in $theme-colors {
    @include bg-gradient-variant('.bg-gradient-#{$color}', $value);
  }
}

// Mutation of node_modules/bootstrap/scss/utilities/_borders.scss
@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}

// TODO remove, once Docs settings are adjusted in DEA, quickfix for Docs plugin
.sbdocs.sbdocs-content {
  width: 100% !important;
  max-width: inherit !important;
  min-height: inherit !important;
}

div[id^='anchor--components'] {
  min-height: 200px !important;
}

div[id^='story--components'] {
  min-height: 200px !important;

  > div {
    min-height: 170px !important;
  }
}

iframe[id^='iframe--components'] {
  min-height: 170px !important;
}

// TODO: refactor this

@include media-breakpoint-down('md') {
  input[type='date'] {
    -webkit-appearance: none;
  }
}

h1,
.h1 {
  @include media-breakpoint-down('md') {
    @include font-size($h1-font-size-sm);
  }
}

h2,
.h2,
.h2-sm {
  @include media-breakpoint-down('md') {
    @include font-size($h2-font-size-sm);
  }
}

h3,
.h3 {
  @include media-breakpoint-down('md') {
    @include font-size($h3-font-size-sm);
  }
}

h4,
.h4 {
  @include media-breakpoint-down('md') {
    @include font-size($h4-font-size-sm);
  }
}

h5,
.h5 {
  @include media-breakpoint-down('md') {
    @include font-size($h5-font-size-sm);
  }
}

h6,
.h6 {
  @include media-breakpoint-down('md') {
    @include font-size($h6-font-size-sm);
  }
}

body {
  @include media-breakpoint-down('md') {
    @include font-size($font-size-sm);
  }
  overflow-y: scroll;
}

ngb-datepicker {
  font-family: $font-family-base;
  font-size: $font-size-base;
  border-radius: 0 !important;

  .ngb-dp-header {
    border-radius: 0;
    margin-bottom: map-get($spacers, 2);
  }

  .ngb-dp-content {
    .ngb-dp-month {
      width: 100%;

      .ngb-dp-weekdays {
        margin-bottom: map-get($spacers, 1);
      }

      .ngb-dp-weekday,
      .ngb-dp-day {
        width: calc(100% / 7);
        font-style: normal;
        font-size: $font-size-base;
        text-align: center;

        .btn-light {
          border-radius: 0;
          width: 100%;

          &:hover {
            background-color: map-get($theme-colors, 'secondary');
          }

          &.outside {
            &:hover {
              color: $white !important;
            }
          }
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }

        &.ngb-dp-today,
        &[tabindex='0'] {
          .btn-light {
            background-color: map-get($theme-colors, 'secondary');
            color: $white;
          }
        }

        &.disabled {
          .btn-light {
            &:hover {
              background-color: $white;
              color: $text-muted !important;
            }
          }
        }
      }
    }
  }
}

a {
  transition: $transition-base;
}

.custom-select {
  display: inline-block;
  width: 100%;
  // height: $form-select-height;
  padding: $form-select-padding-y ($form-select-padding-x + $form-select-indicator-padding) $form-select-padding-y
    $form-select-padding-x;
  font-family: $form-select-font-family;
  @include font-size($form-select-font-size);
  font-weight: $form-select-font-weight;
  line-height: $form-select-line-height;
  color: $form-select-color;
  vertical-align: middle;
  background-color: $form-select-bg;
  background-image: escape-svg($form-select-indicator);
  background-repeat: no-repeat;
  background-position: $form-select-bg-position;
  background-size: $form-select-bg-size;
  border: $form-select-border-width solid $form-select-border-color;
  @include border-radius($form-select-border-radius, 0);
  @include box-shadow($form-select-box-shadow);
  @include transition($form-select-transition);
  appearance: none;

  &:focus {
    border-color: $form-select-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $form-select-focus-box-shadow;
    }
  }

  &[multiple],
  &[size]:not([size='1']) {
    padding-right: $form-select-padding-x;
    background-image: none;
  }

  &:disabled {
    color: $form-select-disabled-color;
    background-color: $form-select-disabled-bg;
    border-color: $form-select-disabled-border-color;
  }

  // Remove outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $form-select-color;
  }
}

// TODO: check ob man das in datepicker reinziehen kann

.custom-select-sm {
  // height: $form-select-height-sm;
  padding-top: $form-select-padding-y-sm;
  padding-bottom: $form-select-padding-y-sm;
  padding-left: $form-select-padding-x-sm;
  @include font-size($form-select-font-size-sm);
}

.custom-select-lg {
  // height: $form-select-height-lg;
  padding-top: $form-select-padding-y-lg;
  padding-bottom: $form-select-padding-y-lg;
  padding-left: $form-select-padding-x-lg;
  @include font-size($form-select-font-size-lg);
}

.shake {
  animation-name: shake-animation;
  animation-duration: 2s;
  border: 1px solid red;
}

.login-shake {
  animation-name: shake-animation;
  animation-duration: 2s;

  &-dynamic {
    animation-iteration-count: infinite;
  }
}

@keyframes shake-animation {
  10%,
  90% {
    transform: translate3d(-0.5px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-3px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(3px, 0, 0);
  }
}

// NBGR shared helpers
.cursor-pointer {
  &:not(.disabled),
  &:not(:disabled) {
    cursor: pointer;
  }
}

.lh-100 {
  line-height: 100%;
}

.pos-relative {
  position: relative;
  padding-right: map-get($spacers, 2) * 1.5;
}

$nv-pricing-footer-height-lg-up: 91px;
$nv-pricing-footer-spacer-lg-up: map-get($spacers, 3);

$nv-checked-list-icon-color: map-get($theme-colors, 'secondary');

// NBGR content
section {
  &.content {
    .nv-checked-list {
      position: relative;
      margin: 0;
      padding: map-get($spacers, 3) 0;
      list-style-type: none;

      li {
        position: relative;
        padding-left: map-get($spacers, 3);
        background-image: escape-svg(
          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='#{$nv-checked-list-icon-color}' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z' /></svg>")
        );
        background-position: left 3px;
        background-size: $font-size-base $font-size-base;
        background-repeat: no-repeat;

        &.dkr-li-bg-position {
          background-position-y: 21px;
        }
      }

      &.text-muted {
        .fas.fa-info-circle {
          color: $text-muted;
          cursor: default;
        }
      }

      &.text-muted li,
      & li.text-muted {
        background-image: escape-svg(
          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 480 512'><path fill='#{$text-muted}' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z' /></svg>")
        );
        background-position: left 5px;
      }

      &:first-of-type {
        padding-top: map-get($spacers, 1);
      }

      &:last-of-type {
        padding-bottom: map-get($spacers, 1);
      }

      &:not(:last-of-type) {
        li {
          &:last-of-type {
            &:after {
              position: absolute;
              left: 0;
              bottom: map-get($spacers, 3) * -1;
              width: 100%;
              height: 1px;
              content: '';
              //background-color: map-get($theme-colors, 'secondary');
              background-color: mix($text-muted, $white, 25%);
            }
          }
        }
      }
    }
  }

  &.nv-pricing-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: $zindex-fixed;
    padding: map-get($spacers, 2) 0;
    @include media-breakpoint-up('lg') {
      height: $nv-pricing-footer-height-lg-up;
      ~ section.content {
        margin-bottom: calc(#{$nv-pricing-footer-spacer-lg-up} + #{$nv-pricing-footer-height-lg-up});
      }
    }
  }
}
